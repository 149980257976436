import { BasicModal, Button } from '@components';
import { TextInput } from '@components/forms';
import { Alert, Box } from '@suid/material';
import { Component, createSignal } from 'solid-js';

type PropsT = {
  id: string;
  hasError?: boolean;
  onSave: (name: string, cb: () => void) => Promise<void>;
  onCloseModal: (label: string) => void;
};

export const SaveModal: Component<PropsT> = (props: PropsT) => {
  const [name, setName] = createSignal('');
  const [error, setError] = createSignal(false);
  const [isSaving, setIsSaving] = createSignal(false);

  return (
    <BasicModal
      title="Name Your Filter"
      id={props.id}
      onClose={() => {
        props.onCloseModal(props.id);
        setName('');
      }}
      footer={false}
      showClose={false}
      width={'800'}
    >
      <Box class="flex mb-3">
        {props.hasError !== undefined && props.hasError ? (
          <Alert severity="error">There was an error saving the filter</Alert>
        ) : undefined}
        <TextInput
          variant="outlined"
          label="Filter Name"
          placeholder="Filter Name"
          name="filterName"
          onChange={(val: string) => {
            setName(val);
            setError(false);
          }}
          maxLength={80}
          error={error() ? 'Filter Name is required' : undefined}
        />
      </Box>
      <Box class="flex justify-end">
        <Button
          disabled={isSaving()}
          isLoading={isSaving()}
          label="Submit"
          onClick={async () => {
            if (!Boolean(name())) {
              setError(true);
              return;
            }
            setIsSaving(true);
            await props.onSave(name(), () => {
              setIsSaving(false);
              setName('');
            });
          }}
          class="!mr-2"
        />
        <Button
          variant="outlined"
          label="Cancel"
          onClick={() => {
            props.onCloseModal(props.id);
            setName('');
          }}
        />
      </Box>
    </BasicModal>
  );
};
