import {
  BasicModal,
  Button,
  Card,
  DatePicker,
  Map,
  Menu,
  TimeInput,
  ToastType,
  Typography,
  openToast,
} from '@components';
import { Coordinate } from '@components/Map/Map';
import Tooltip from '@components/Tooltip';
import { SelectField } from '@components/forms';
import { useParams } from '@solidjs/router';
import { nextCheckCallByStatus } from '@store/loadboard';
import { closeModal, openModal } from '@store/modals';
import {
  disableTracking,
  enableTracking,
  getStopCordinates,
  getTrackingData,
  orderStore,
  postNextCheckCall,
  updateLoadPropertyAtIndex,
} from '@store/orders';
import { ILoadComments, TrackingData } from '@store/orders/types';
import { Info } from '@suid/icons-material';
import ArrowDropDownIcon from '@suid/icons-material/ArrowDropDown';
import { Box, Button as SButton, Stack } from '@suid/material';
import { timeZoneAbbreviation } from '@utils/dateFormat';
import { handleToast } from '@utils/utils';
import { loadStatusItems, trackingOptions } from '@views/order/constants';
import { DateTime } from 'luxon';
import { Show, createEffect, createSignal } from 'solid-js';

import { CustomerTracking } from '../../customerTracking';
import { getLoadColor } from '../../header/constants';
import { subTextStyle } from '../loads/styles';
import { CustomerUpdateModal } from './CustomerUpdateModal';
import { TrackingCard } from './TrackingCard';
import classes from './classes';
import styles from './styles';
import { trackingToComment } from './utils';

export interface IZip {
  Address: {
    Zip: string;
  };
}

export interface ICityState {
  Address: {
    City: string;
    State: string;
  };
}

export interface trackingProps {
  loading?: boolean;
}

export const Tracking = (props: trackingProps) => {
  const params = useParams();
  const [comment, setComment] = createSignal<ILoadComments>();
  const [checkCallEnabled, setCheckCallEnabled] = createSignal(
    orderStore.order.loads[orderStore.activeTab.index].mode !== 'LTL',
  );
  const [nextCheckCallDateUtc, setNextCheckCallDateUtc] =
    createSignal<string>();

  createEffect(() => {
    if (
      orderStore.order.loads[orderStore.activeTab.index]?.mode !== undefined
    ) {
      setCheckCallEnabled(
        orderStore.order.loads[orderStore.activeTab.index]?.mode !== 'LTL',
      );
    }

    if (
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      orderStore.order.loads[orderStore.activeTab.index] !== undefined &&
      orderStore.order.loads[orderStore.activeTab.index]
        .nextCheckCallDateUtc !== undefined
    ) {
      setNextCheckCallDateUtc(
        DateTime.fromISO(
          `${
            orderStore.order.loads[orderStore.activeTab.index]
              .nextCheckCallDateUtc
          }`,
          { zone: 'Etc/UTC' },
        )
          .toLocal()
          .toISO() ?? '',
      );
    }
  });

  const customerUpdateModalId = 'trackingCustomerUpdateModalId';

  const isCreate = !params.id;
  const serviceId =
    orderStore.order.loads[orderStore.activeTab.index]?.trackingService;

  createEffect(() => {
    const stops = orderStore.order.loads[
      orderStore.activeTab.index
    ]?.stops?.filter((stop) => {
      return stop.operationType !== 'Delete';
    });
    if (stops) {
      const zipArray: IZip[] = [];
      stops.forEach((stop) => {
        zipArray.push({ Address: { Zip: stop.zip } });
      });
      void getStopCordinates(zipArray);
    }
  });

  const [activeTabIndex, setActiveTabIndex] = createSignal(
    orderStore.activeTab.index,
  );

  createEffect(() => {
    setActiveTabIndex(orderStore.activeTab.index);
  });

  const initialValues = {
    driverPhone:
      orderStore.order.loads[orderStore.activeTab.index]?.driverPhoneNumber,
    loadId: orderStore.order.loads[orderStore.activeTab.index]?.id,
    trackingStartDate: new Date().toISOString(),
    settings: {
      truckNumber: '',
      trailerNumber: '',
      timezone: timeZoneAbbreviation(),
      resend: true,
    },
    trackingStopWindows:
      orderStore.order.loads[orderStore.activeTab.index]?.stops?.map((val) => {
        return { Id: val.id, EDT: '', ETA: '' };
      }) || [],
    serviceId: serviceId === null ? 0 : serviceId,
  };

  const hasTrackingData = () => {
    const trackingData =
      orderStore.order.loads[orderStore.activeTab.index].loadTracking;
    if (
      trackingData !== undefined &&
      trackingData !== null &&
      trackingData.length > 0
    ) {
      return true;
    }
    return false;
  };

  const handleTracking = async (trackingOption: string) => {
    try {
      const { id } = orderStore.order.loads[orderStore.activeTab.index];
      if (trackingOption === 'Disable') {
        await disableTracking(id);
      } else if (trackingOption === 'Resend') {
        await enableTracking(initialValues as unknown as TrackingData);
      }
    } catch (error) {
      handleToast(ToastType.Error, (error as Error).message);
    }
  };

  return (
    <Card
      startTitle="Tracking"
      startTitleAction={
        <Menu
          // @ts-expect-error sauce
          menuItems={trackingOptions}
          disabled={orderStore.isReadOnly}
          menuButtonLabel={
            <Button
              label="Tracking Options"
              class={classes.trackingButton}
              endIcon={<ArrowDropDownIcon />}
              disabled={orderStore.isReadOnly}
              size="small"
              sx={{ lineHeight: '22px' }}
            />
          }
          onMenuItemClick={(item) => {
            void handleTracking(item);
          }}
          downArrowShowHide={false}
        />
      }
      endTitle={
        <Tooltip
          placement="left"
          text="You can double click on any Check Call to send your customer."
        >
          <Info class={classes.infoIcon} />
        </Tooltip>
      }
      loading={props.loading ?? orderStore.loading}
    >
      <div>
        <Stack direction="row" class="mb-2">
          <div class="flex-auto ">
            {/*This is a show trick to force the map to re-render when the tab is changed*/}
            <Show when={activeTabIndex() === orderStore.activeTab.index}>
              <Map
                center={[0, 0]}
                height={500}
                width={'100%'}
                zoom={3}
                coordinates={orderStore.mapCords}
                markerPositions={
                  getTrackingData()
                    .filter((tracking) => {
                      return (
                        tracking.latitude !== undefined && tracking.longitude
                      );
                    })
                    .map((tracking) => {
                      return {
                        lng: tracking.longitude as number,
                        lat: tracking.latitude as number,
                        city: tracking.city,
                        state: tracking.state,
                        locationTime: tracking.locationTime,
                      };
                    }) as unknown as Coordinate[]
                }
              />
            </Show>
          </div>
          <Show when={hasTrackingData()}>
            <Stack
              width="200px"
              direction="column"
              sx={{
                overflowY: 'auto',
                height: '500px',
                paddingLeft: '5px',
                paddingBottom: '2px',
              }}
              gap={0.25}
            >
              {orderStore.order.loads[orderStore.activeTab.index]
                .loadTracking &&
                getTrackingData().map((t) => {
                  return (
                    <Box
                      class="cursor-pointer"
                      onClick={() => {
                        setComment(trackingToComment(t));
                        openModal(customerUpdateModalId);
                      }}
                    >
                      <TrackingCard tracking={t} />
                    </Box>
                  );
                })}
            </Stack>
          </Show>
        </Stack>
        {checkCallEnabled() ? (
          <div>
            <div class="text-[#123b50] font-normal text-base font-roboto">
              Schedule Check Calls
            </div>
            <Stack direction="row" gap={1} alignItems="flex-end">
              <Box>
                <SelectField
                  backgroundColor={getLoadColor(
                    orderStore.order.loads[orderStore.activeTab.index].status,
                  )}
                  onChange={(e) => {
                    updateLoadPropertyAtIndex({
                      status: e.target.value,
                    });
                  }}
                  disableUnderline
                  label="Load Status"
                  variant="filled"
                  renderValue={(value) => {
                    return value;
                  }}
                  menuItems={loadStatusItems}
                  sxProps={styles.statusSelectStyle}
                  value={
                    orderStore.order.loads[orderStore.activeTab.index].status
                  }
                  disabled={orderStore.isReadOnly}
                  size="small"
                  width="140px"
                />
              </Box>
              <Box>
                <Box
                  borderRadius="4px"
                  padding="4px 7px"
                  backgroundColor="#f0f0f0"
                  height="38px"
                  width="140px"
                >
                  <Typography
                    variant="body1"
                    component="div"
                    sxProps={{
                      ...subTextStyle,
                      textAlign: 'left',
                      marginBottom: '-6px',
                    }}
                  >
                    Next Check Call
                  </Typography>
                  <Box class="text-sm">
                    {
                      nextCheckCallByStatus[
                        orderStore.order.loads[orderStore.activeTab.index]
                          .status ?? 'None'
                      ]
                    }
                  </Box>
                </Box>
              </Box>
              <Box maxWidth="150px">
                {/*
                 * There's a weird reactivity issue here where the value of `isReadOnly` will never get
                 * passed to the `DatePicker`. So I added this `Show` trick to get around that for now.
                 */}
                <Show when={orderStore.isReadOnly}>
                  <DatePicker
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-empty-function
                    handleChange={(value) => {}}
                    label="Next Call Date"
                    placeholder="MM/DD/YYYY"
                    size="small"
                    disabled
                  />
                </Show>
                <Show when={!orderStore.isReadOnly}>
                  <DatePicker
                    handleChange={(value) => {
                      updateLoadPropertyAtIndex({
                        nextCheckCallDateUtc:
                          DateTime.fromISO(value).toUTC().toISO() ?? '',
                      });
                    }}
                    value={nextCheckCallDateUtc()}
                    label="Next Call Date"
                    placeholder="MM/DD/YYYY"
                    size="small"
                    disabled={orderStore.isReadOnly}
                  />
                </Show>
              </Box>
              <Box width="120px">
                <TimeInput
                  handleChange={(value) => {
                    if (
                      orderStore.order.loads[orderStore.activeTab.index]
                        .nextCheckCallDateUtc === undefined
                    ) {
                      return;
                    }

                    let date = DateTime.fromISO(
                      orderStore.order.loads[orderStore.activeTab.index]
                        .nextCheckCallDateUtc as string,
                    );

                    const time = DateTime.fromFormat(value, 'HH:mm');

                    if (
                      date.hour === time.hour &&
                      date.minute === time.minute
                    ) {
                      return;
                    }

                    date = date.set({ hour: time.hour, minute: time.minute });

                    updateLoadPropertyAtIndex({
                      nextCheckCallDateUtc: date.toUTC().toISO() as string,
                    });
                  }}
                  value={nextCheckCallDateUtc()}
                  label="Next Call Time"
                  disabled={orderStore.isReadOnly}
                  size="small"
                />
              </Box>
              <Box flex={1}>
                <SButton
                  sx={{ height: '37px' }}
                  variant="outlined"
                  color="primary"
                  onClick={async () => {
                    const load =
                      orderStore.order.loads[orderStore.activeTab.index];
                    const localDate = nextCheckCallDateUtc();
                    if (localDate === undefined) {
                      openToast({
                        type: ToastType.Error,
                        position: 'top-right',
                        message: 'A valid date time is required',
                        duration: 2500,
                      });
                      return;
                    }
                    const date = DateTime.fromISO(localDate).toUTC().toISO();
                    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                    if (load !== undefined) {
                      const res = await postNextCheckCall(load.id, date ?? '');
                      if (!res) {
                        openToast({
                          type: ToastType.Error,
                          position: 'top-right',
                          message:
                            'There was an issue updating the next check call date',
                          duration: 2500,
                        });
                      } else {
                        openToast({
                          type: ToastType.Success,
                          position: 'top-right',
                          message: 'Check call scheduled',
                          duration: 2500,
                        });
                      }
                    }
                  }}
                  disabled={orderStore.isReadOnly}
                >
                  <span class="text-sm">SCHEDULE CHECK CALL</span>
                </SButton>
              </Box>
            </Stack>
          </div>
        ) : (
          <div>
            <div class="text-[#123b50] font-normal text-base font-roboto ">
              Tracking Calls
            </div>
            <SelectField
              backgroundColor={getLoadColor(
                orderStore.order.loads[orderStore.activeTab.index].status,
              )}
              onChange={(e) => {
                updateLoadPropertyAtIndex({
                  status: e.target.value,
                });
              }}
              disableUnderline
              label="Load Status"
              variant="filled"
              renderValue={(value) => {
                return value;
              }}
              menuItems={loadStatusItems}
              sxProps={styles.statusSelectStyle}
              width="140px"
              value={orderStore.order.loads[orderStore.activeTab.index].status}
              disabled={orderStore.isReadOnly}
            />
          </div>
        )}
        <Show when={!isCreate}>
          <CustomerTracking activeCarrierTab={orderStore.activeTab.index} />
        </Show>
      </div>
      <BasicModal
        id={customerUpdateModalId}
        title="Send Customer Update"
        footer={false}
        onClose={() => {
          closeModal(customerUpdateModalId);
        }}
        onBackdropClick={() => {
          closeModal(customerUpdateModalId);
        }}
        modalStyles={styles.basicModal}
      >
        <CustomerUpdateModal comment={comment()} excludeDate />
      </BasicModal>
    </Card>
  );
};
