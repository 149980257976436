import { ToastType, Typography } from '@components';
import { TextInput } from '@components/forms';
import { updateCarrierComment } from '@store/carriers';
import { Comments } from '@store/orders';
import { Grid, Stack } from '@suid/material';
import { handleToast, isAdmin } from '@utils/utils';
import { DateTime } from 'luxon';
import { Component, createSignal, onMount, Show } from 'solid-js';
import {
  canDeleteCarrierComment,
  isAdminOrOriginalAuthor,
} from '@views/carriers/carrierDetails/carrierDetailsTab/utils';
import { Delete } from '@suid/icons-material';
import { openDialogBox } from '@store/dialogBox';
import { DELETE_COMMENT_DIALOG_ID } from '@views/order/constants';
import { updateCustomerComment } from '@store/customers/customerDetails';

type SingleCommentCardProps = {
  comment: Comments;
  type: 'Carrier' | 'Order' | 'Customer';
  setCommentIdToDelete: (id: number) => void;
};

const textStyle = {
  color: 'black',
  fontSize: '16px',
  fontWeight: '400',
  flex: '1',
};
const titleStyle = { ...textStyle, fontWeight: '500' };

export const SingleCommentCard: Component<SingleCommentCardProps> = (props) => {
  const [editable, setEditable] = createSignal(false);
  const [comment, setComment] = createSignal('');

  onMount(() => {
    setComment(props.comment.comment ?? '');
  });

  const canEditComment = () => {
    switch (props.type) {
      case 'Customer':
      case 'Carrier':
        return isAdminOrOriginalAuthor(props.comment);
      default:
        return false;
    }
  };

  const canDelete = (comment: Comments) => {
    switch (props.type) {
      case 'Customer':
        return isAdminOrOriginalAuthor(comment);
      case 'Carrier':
        return canDeleteCarrierComment(comment);
      default:
        return isAdmin();
    }
  };

  const handleEditComment = () => {
    if (canEditComment()) setEditable(true);
  };

  const handleCommentSubmission = async (e: KeyboardEvent) => {
    if (e.key !== 'Enter' || e.shiftKey) return;
    setEditable(false);
    const updateComment =
      props.type === 'Customer' ? updateCustomerComment : updateCarrierComment;
    const res = await updateComment({
      id: props.comment.id,
      type: props.type,
      objectId: props.comment.objectId,
      comment: comment(),
    });

    if (Boolean(res)) {
      handleToast(ToastType.Success, 'Comment updated successfully');
    }
  };

  return (
    <Stack spacing={1}>
      <Grid item container class="bg-[#EEE] p-1 h-[48px]">
        <Typography
          class="flex items-center h-full p-[5px]"
          variant="subtitle2"
          sxProps={titleStyle}
        >
          {props.comment.name ?? '-'}
        </Typography>
        <Grid
          xs={6}
          container
          gap={3}
          alignItems="center"
          justifyContent="flex-end"
        >
          <Typography
            variant="body2"
            component="span"
            sxProps={{ ...textStyle, textAlign: 'end' }}
          >
            {DateTime.fromISO(props.comment.timestamp as string, {
              zone: 'utc',
            })
              .setZone('local')
              .toFormat('MMM dd, yyyy hh:mm a')
              .toUpperCase()}
          </Typography>
          {canDelete(props.comment) && (
            <Delete
              sx={{ color: '#B00020', cursor: 'pointer' }}
              onClick={() => {
                openDialogBox(DELETE_COMMENT_DIALOG_ID);
                props.setCommentIdToDelete(props.comment.id);
              }}
            />
          )}
        </Grid>
      </Grid>
      <Grid spacing={1} pb={3} class="!flex">
        <Show
          when={editable()}
          fallback={
            <span
              class={`${
                props.type === 'Customer' || props.type === 'Carrier'
                  ? 'cursor-pointer'
                  : ''
              }`}
              onClick={handleEditComment}
            >
              {comment()
                .split('\n')
                .map((line) => (
                  <span class="break-all">
                    {line}
                    <br />
                  </span>
                ))}
            </span>
          }
        >
          <TextInput
            multiline
            rows={3}
            inputProps={{
              style: {
                resize: 'vertical',
              },
            }}
            name="comment"
            label=""
            value={comment()}
            onChange={(value: string) => {
              setComment(value);
            }}
            onKeyDown={handleCommentSubmission}
            classes="!ml-2"
          />
        </Show>
      </Grid>
    </Stack>
  );
};
