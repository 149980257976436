import { Button, Notification, ToastType } from '@components';
import { CheckboxInput, TextInput } from '@components/forms';
import { Layout } from '@layouts/index';
import { useParams, useSearchParams } from '@solidjs/router';
import { getCarrierPlaylists, setPlaylistsStore } from '@store/carriers';
import { DocumentEntityType } from '@store/documents';
import { getEffectiveIsEnabledForUserByFeatureName } from '@store/Global/service';
import { setLtlQuoteState } from '@store/ltl/store';
import {
  createPlaylist,
  fetchCopyOrderDetails,
  fetchOrderDetails,
  getBookedStatus,
  getCarrierDetail,
  getOrderTemplates,
  mergeOrderWithQuote,
  orderStore,
  Playlist,
  populateOrderWithQuote,
  saveOrderTemplate,
  setOrderStore,
  updateLoadPropertyAtIndex,
} from '@store/orders';
import { DEFAULT_ORDER_STATE } from '@store/orders/defaultVals';
import { userStore } from '@store/user';
import { Box, Grid } from '@suid/material';
import { convertUtcToLocalAndFormat } from '@utils/dateFormat';
import { handleToast, isPrivate, printLog } from '@utils/utils';
import { createEffect, createSignal, onMount, Show } from 'solid-js';

import { closeModal, openModal } from '../../store';
import {
  Carrier,
  Customer,
  NeedsApprovalModal,
  OrderPageHeader,
} from './components';
import { ErrorsTable } from './components/ErrorsTable';
import {
  mapToComponent,
  mapToMessage,
  mapToTitle,
} from './components/ErrorsTable/constants';
import { ErrorT } from './components/ErrorsTable/types';
import { UsersViewingComponent } from './components/usersViewingComponent/UsersViewingComponent';

const Order = () => {
  const [templateInfo, setTemplateInfo] = createSignal({
    name: '',
    public: true,
    nameError: '',
  });
  const params = useParams();
  const [query] = useSearchParams();

  onMount(() => {
    const getAndCheckPlaylists = async () => {
      const playlists = await getCarrierPlaylists();

      if (playlists !== undefined) {
        if (!playlists.some((p) => p.name === 'Favorites')) {
          const favorites: Playlist = {
            id: 0,
            userId: userStore.user.id,
            name: 'Favorites',
            carriers: [],
            groups: [],
            editable: false,
            canEdit: true,
            // @ts-expect-error This is how it's done in V3.
            user: null,
          };

          try {
            // If there's no favorites playlist for this user, create it.
            const favoritesPlaylist = await createPlaylist(favorites);

            if (favoritesPlaylist !== undefined) {
              playlists.push(favoritesPlaylist);
              setPlaylistsStore('playlists', [...playlists]);
            }
          } catch (error) {
            handleToast(ToastType.Error, (error as Error).message);
          }
        }
      }
    };

    const setupOrder = async () => {
      const isCreate = !params.id;
      printLog('params', params);
      printLog('iscreate', isCreate);
      //reset the store when coming on order page.
      setOrderStore(() => ({
        order: { ...DEFAULT_ORDER_STATE },
        isCreate: isCreate && !query.copyOrderId,
        orderFormError: {},
        orderFormErrorPerSection: {
          customerInformation: 0,
          loads: 0,
          instructionsAndCosts: 0,
        },
        vendorFalveyInsuranceError: '',
      }));
      await getOrderTemplates();
      if (params.id !== orderStore.order.id.toString() && !isCreate) {
        // eslint-disable-next-line no-alert
        await fetchOrderDetails(params.id, query.quoteId);
      }
      const resp = await getEffectiveIsEnabledForUserByFeatureName('Falvey');
      setLtlQuoteState('isFalveyInsuranceFlow', Boolean(resp));
      if (isCreate && query.copyOrderId && !query.quoteId) {
        await fetchCopyOrderDetails(query.copyOrderId);
      }
    };

    void getAndCheckPlaylists();
    void setupOrder();
  });

  createEffect(async () => {
    if (query.quoteId && orderStore.isCreate && !query.copyOrderId) {
      await populateOrderWithQuote(query.quoteId);
    } else if (query.quoteId && !orderStore.isCreate && !query.copyOrderId) {
      await mergeOrderWithQuote(query.quoteId, orderStore.order);
    }
  });

  createEffect(() => {
    if (orderStore.order.id) {
      //run the get booked status service api for each load id in the order store
      orderStore.order.loads.forEach(async (loadId, ind) => {
        //if no carrier on load see if there is a matching carrier
        if (orderStore.order.loads[ind].carrierId === null) {
          await getBookedStatus(loadId.id);
        }
      });
    }
  });

  createEffect(async () => {
    //if no carrier and there is a carrier match, get the carrier details and update the carrierid and status
    if (
      orderStore.order.loads[orderStore.activeTab.index]?.carrierId === null &&
      Boolean(
        orderStore.order.loads[orderStore.activeTab.index]?.carrierMatchId,
      )
    ) {
      setOrderStore('matched', true);
      await getCarrierDetail(
        orderStore.order.loads[orderStore.activeTab.index]
          .carrierMatchId as number,
        0,
      );
      updateLoadPropertyAtIndex({
        carrierId:
          orderStore.order.loads[orderStore.activeTab.index]?.carrierMatchId,
        status: 'Assigned',
      });
    }
  });

  const checkErrorSection = () => {
    const newErrors = Object.entries(
      orderStore.orderFormErrorPerSection,
    ).reduce((acc, v) => {
      const [key, hasError] = v;
      if (hasError === 0) {
        return acc;
      }

      acc.push({
        title: mapToTitle[key],
        body: mapToMessage[key],
        onView: () => {
          // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
          const id = mapToComponent[key];

          // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
          if (id !== undefined) {
            document.querySelector(id)?.scrollIntoView({
              behavior: 'smooth',
            });
          }
        },
      });
      return acc;
    }, [] as ErrorT[]);
    return newErrors;
  };

  const renderCommentCount = (count: number | undefined) => {
    return (
      <div
        class={
          'w-[30px] h-[30px] rounded-3xl bg-[#1B4960] text-white text-center border-[#fff] border-2'
        }
      >
        {count}
      </div>
    );
  };

  const RenderNeedApprovalModal = () => {
    const {
      order: { needsApprovalFlag },
    } = orderStore;
    if (needsApprovalFlag) {
      return (
        <div class="mb-3 relative">
          <Notification
            type={needsApprovalFlag.flagType}
            commentLength={renderCommentCount(needsApprovalFlag.commentCount)}
            getCustomStyle={() => {
              if (!Boolean(needsApprovalFlag.cleared)) {
                return 'border-[#8BD0FF] bg-[#2989D8]/10 cursor-pointer';
              }
            }}
            text={`Needs Approval - ${needsApprovalFlag.description}`}
            subText={`${needsApprovalFlag.setBy} - ${convertUtcToLocalAndFormat(
              needsApprovalFlag.setDate as string,
              'MMM dd, yyyy h:mm a',
            )}`}
            onClick={() => openModal('needs-approval-modal')}
            cleared={needsApprovalFlag.cleared}
            disputed={
              (needsApprovalFlag.disputedOrderFlags ?? []).find((f) => {
                return f.clearedDate === undefined || f.clearedDate === null;
              }) !== undefined
            }
            needsApprovalFlag
          />
          <NeedsApprovalModal
            modalId="needs-approval-modal"
            onClose={() => closeModal('needs-approval-modal')}
            flagData={needsApprovalFlag}
          />
        </div>
      );
    }
  };

  const handleCloseTemplateMode = () => {
    setOrderStore('isTemplateMode', false);
    window.location.reload(); //reloading because this will save time and effort to reset the state and less code as well can be changed later to a more programming reset
  };

  const handleSaveTemplate = async () => {
    if (templateInfo().name.trim() === '') {
      setTemplateInfo((prev) => ({
        ...prev,
        nameError: 'Template name is required',
      }));
      return;
    }
    setTemplateInfo((prev) => ({ ...prev, nameError: '' }));
    await saveOrderTemplate(templateInfo().name, templateInfo().public, () =>
      setOrderStore('isTemplateMode', false),
    );
  };

  return (
    <Layout>
      <div class="border-2 bg-background h-screen flex flex-col">
        <OrderPageHeader />
        <div class="flex-1 overflow-y-auto xl:p-4 p-2">
          <Show when={checkErrorSection().length > 0}>
            <ErrorsTable
              errors={checkErrorSection()}
              message={
                <Box>
                  There were multiple errors that prevented the Order from being
                  saved. Please address the highlighted fields in the follow
                  sections. You can click on each error to jump to the effected
                  field.
                </Box>
              }
            />
          </Show>
          <div class="bg-white p-2 rounded-lg">
            {orderStore.isTemplateMode && (
              <div class="flex p-4 !border-2 mb-2 rounded-lg bg-[#f2dede] align-center">
                <TextInput
                  label="Template Name"
                  value={templateInfo().name}
                  classes="max-w-[30%] !mr-4 bg-white"
                  onChange={(val) =>
                    setTemplateInfo((prev) => ({
                      ...prev,
                      name: val as string,
                    }))
                  }
                  error={templateInfo().nameError}
                />
                <CheckboxInput
                  label={
                    <b>
                      {' '}
                      Public (everyone in my office can use this template for
                      their loads)
                    </b>
                  }
                  checked={templateInfo().public}
                  onChange={(val) =>
                    setTemplateInfo((prev) => ({ ...prev, public: val }))
                  }
                />
                <div class="flex max-h-[40px] ml-auto">
                  <Button
                    label="Save Template"
                    variant="contained"
                    size="small"
                    onClick={handleSaveTemplate}
                    class="!mr-3 !text-[12px]"
                  />
                  <Button
                    label="Cancel"
                    variant="outlined"
                    size="small"
                    onClick={handleCloseTemplateMode}
                    class="!text-[12px]"
                  />
                </div>
              </div>
            )}
            <div class="flex">
              <UsersViewingComponent orderId={orderStore.order.id.toString()} />
            </div>
            {orderStore.order.needsApprovalFlag && <RenderNeedApprovalModal />}
            <Grid container spacing={2}>
              {!isPrivate() && (
                <Customer
                  lgBreakpoint={4}
                  customerFileUploadProps={{
                    documentEntityType: DocumentEntityType.Load,
                    entityId: orderStore.order.id,
                  }}
                />
              )}
              <Carrier lgBreakpoint={isPrivate() ? 12 : 8} />
            </Grid>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Order;
