import { createStore, produce } from 'solid-js/store';

import { fetchUser } from './services';
import { UserViewModel } from './types';

type UserState = {
  user: UserViewModel;
  isLoading: boolean;
  isError: boolean;
  userPromise: Promise<void>;
};

const [userStore, setUserStore] = createStore<UserState>({
  user: {} as UserViewModel,
  isLoading: true,
  isError: false,
  userPromise: new Promise((resolve, reject) => {
    setTimeout(async () => {
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      if (userStore.user.name !== undefined) {
        return;
      }

      try {
        await fetchUserDetails();
        resolve();
      } catch (error) {
        reject(error);
      }
    }, 1);
  }),
});

async function fetchUserDetails() {
  try {
    const user: UserViewModel = await fetchUser();
    setUserStore(
      produce((draft) => {
        draft.user = user;
        draft.isLoading = false;
      }),
    );
  } catch (error) {
    setUserStore(
      produce((draft) => {
        draft.isError = true;
        draft.isLoading = false;
      }),
    );
  }
}

// @ts-expect-error debug console
window.userStore = { userStore };

export { fetchUserDetails, userStore };
