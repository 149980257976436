/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { apiClient, v3Client } from '@api/apiClient';
import { Token } from '@common/types';
import {
  HttpTransportType,
  HubConnection,
  HubConnectionBuilder,
} from '@microsoft/signalr';
import { routeLoginPage } from '@utils/routeLoginPage';
import { handleToast, printLog } from '@utils/utils';
import { ToastType } from '@components';
import { TrackingType } from '@components/Header/search/types';

import {
  AnnouncementMessagesType,
  CheckRequestType,
  NeedPaperworkTypes,
  NotificationTypes,
  CreditIncreaseRequestCustomer,
  DoNotSolicitRequest,
  PendingCustomers,
  CarrierType,
  pendingChecksTypes,
  AllApprovalsTypes,
} from './types';

export interface SignalrHubProps {
  connection: string;
  loginUrl: string;
}

// Add the import statement for SignalrHubProps

export async function createSocket(
  props: SignalrHubProps,
): Promise<HubConnection> {
  const tokenResponse = await fetch(props.loginUrl, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
  });

  const token: Token = (await tokenResponse.json()) as Token;
  if (!token.token) {
    routeLoginPage();
  }
  const con = new HubConnectionBuilder()
    .withUrl(props.connection, {
      skipNegotiation: true,
      transport: HttpTransportType.WebSockets,
      accessTokenFactory: () => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        return token.token;
      },
    })
    .withAutomaticReconnect()
    .build();
  await con.start().catch((err) => printLog(err));
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return con;
}

export const IsFeatureFlagEnabledByName = async (
  name: string,
): Promise<boolean | undefined> => {
  try {
    const result = (await apiClient.get(
      `FeatureFlag/GetIsFeatureEnabled/${name}`,
    )) as unknown as boolean;
    return result;
  } catch (e) {
    return undefined;
  }
};

export const getEffectiveIsEnabledForUserByFeatureName = async (
  name: string,
): Promise<boolean | undefined> => {
  try {
    const result = (await apiClient.get(
      `FeatureFlag/GetEffectiveIsEnabledForUserByFeatureName/${name}`,
    )) as unknown as boolean;
    return result;
  } catch (e) {
    return false;
  }
};

export const fetchAllAnnouncement = async () => {
  try {
    const response: AnnouncementMessagesType[] =
      await v3Client.get('/Announcement');
    return response;
  } catch (error) {
    if (error instanceof Error) {
      handleToast(ToastType.Error, 'Failed to get Announcement');
      throw new Error(`Failed to Announcement: ${error.message}`);
    }
  }
};

export const addAnnouncement = async (payload: {
  type: string;
  message: string;
}) => {
  try {
    const response: { success: boolean } = await v3Client.post(
      '/Announcement',
      payload,
    );
    if (response.success) {
      handleToast(ToastType.Success, 'Announcement was broadcast');
    }
  } catch (error) {
    if (error instanceof Error) {
      handleToast(ToastType.Error, 'Failed to broadcast Announcement');
      throw new Error(`Failed to broadcast Announcement: ${error.message}`);
    }
  }
};

export const announcementMarkAsRead = async (payload: number[]) => {
  try {
    const response: { success: boolean } = await v3Client.post(
      '/Announcement/MarkAsRead',
      payload,
    );
    if (response.success) {
      handleToast(ToastType.Success, 'Announcement marked as read');
    }
  } catch (error) {
    if (error instanceof Error) {
      handleToast(ToastType.Error, 'Failed to mark as read');
      throw new Error(`Failed to mark as read: ${error.message}`);
    }
  }
};

export const fetchCreditIncreaseRequestCustomers = async () => {
  try {
    const response: CreditIncreaseRequestCustomer[] = await v3Client.get(
      '/Queue/CreditIncrease',
    );
    return response;
  } catch (error) {
    if (error instanceof Error) {
      handleToast(ToastType.Error, 'Failed to get Credit Increase Request');
    }
  }
};

export const fetchDoNotSolicitRequests = async () => {
  try {
    const response: DoNotSolicitRequest[] = await v3Client.get(
      '/Queue/DoNotSolicitRequests',
    );
    return response;
  } catch (error) {
    if (error instanceof Error) {
      handleToast(ToastType.Error, 'Failed to get Do Not Solicit Requests');
    }
  }
};

export const fetchPendingCustomers = async () => {
  try {
    const response: PendingCustomers[] = await v3Client.get(
      '/Queue/PendingCustomers',
    );
    return response;
  } catch (error) {
    if (error instanceof Error) {
      handleToast(ToastType.Error, 'Failed to get Pending Customers');
    }
  }
};

export const changeQueueVisibility = async (id: number, visible: boolean) => {
  try {
    await v3Client.post(`/Customer/ChangeQueueVisibility/${id}/${visible}`);
    handleToast(ToastType.Success, 'Prepaid Cleared');
  } catch (error) {
    if (error instanceof Error) {
      handleToast(ToastType.Error, 'Failed to clear prepaid');
    }
  }
};

export const getPendingCarrierDataApi = async () => {
  try {
    const data = await v3Client.get('/Queue/PendingCarriers');
    return data as unknown as CarrierType[];
  } catch (error) {
    handleToast(ToastType.Error, 'Failed to fetch Pending Carrier Data');
  }
};

export const fetchAllNotification = async () => {
  try {
    const response: NotificationTypes[] = await v3Client.get('/Notification');
    return response;
  } catch (error) {
    if (error instanceof Error) {
      handleToast(ToastType.Error, 'Failed to get Notification');
    }
  }
};

export const notificationMarkAsRead = async (payload: number[]) => {
  try {
    const response: { success: boolean } = await v3Client.post(
      '/Notification/MarkAsRead',
      payload,
    );
    if (response.success) {
      handleToast(ToastType.Success, 'Notification marked as read');
    }
  } catch (error) {
    if (error instanceof Error) {
      handleToast(ToastType.Error, 'Failed to mark as read');
    }
  }
};

export const fetchAllNeedPaperwork = async () => {
  try {
    const response: NeedPaperworkTypes[] = await v3Client.get(
      '/Queue/NeedPaperwork',
    );
    return response;
  } catch (error) {
    if (error instanceof Error) {
      handleToast(ToastType.Error, 'Failed to get Paperwork');
    }
  }
};

export const fetchAllPendingChecks = async () => {
  try {
    const response: pendingChecksTypes[] = await v3Client.get(
      '/Queue/PendingChecks',
    );
    return response;
  } catch (error) {
    if (error instanceof Error) {
      handleToast(ToastType.Error, 'Failed to get Pending Checks');
    }
  }
};

export const getAllCheckRequest = async (id: number) => {
  try {
    const response: CheckRequestType = await v3Client.get(
      `/Request/CheckRequest/${id}`,
    );
    return response;
  } catch (error) {
    if (error instanceof Error) {
      handleToast(ToastType.Error, 'Failed to get Checks Request');
    }
  }
};

export const approveCheckRequest = async (id: number) => {
  try {
    const response: { success: boolean; message: string } = await v3Client.post(
      `/Request/ApproveCheck/${id}`,
      { id },
    );
    return response;
  } catch (error) {
    if (error instanceof Error) {
      handleToast(ToastType.Error, 'Failed to approve Checks Request');
    }
  }
};

export const denyCheckRequest = async (payload: {
  id: number;
  comment: string;
}) => {
  try {
    const response: { success: boolean } = await v3Client.post(
      '/Request/DenyCheck',
      payload,
    );
    return response;
  } catch (error) {
    if (error instanceof Error) {
      handleToast(ToastType.Error, 'Failed to deny Checks Request');
    }
  }
};

export function styleQuoteLocator(payload: { quoteLocator: string }) {
  const { quoteLocator } = payload;
  if (quoteLocator.length === 10) {
    return `${quoteLocator.slice(0, 3)}-${quoteLocator.slice(
      3,
      6,
    )}-${quoteLocator.slice(6)}`;
  }

  return quoteLocator;
}

export const fetchAllApprovals = async () => {
  try {
    const response = await v3Client.get('/Queue/PendingApprovalsAndDisputes/');
    return response as unknown as AllApprovalsTypes[];
  } catch (error) {
    if (error instanceof Error) {
      handleToast(ToastType.Error, 'Failed to get All Approvals');
    }
  }
};

export const fetchDisputedLoads = async () => {
  try {
    const response = await v3Client.get('/Queue/DisputedOrders/');
    return response as unknown as AllApprovalsTypes[];
  } catch (error) {
    if (error instanceof Error) {
      handleToast(ToastType.Error, 'Failed to get All Approvals');
    }
  }
};
export const findOrder = async (id: number) => {
  try {
    const data = await v3Client.get(`/Order/Find/${id}?id=${id}`);
    return data;
  } catch (error) {
    handleToast(ToastType.Error, 'The page you requested is not found');
  }
};

export const findTracking = async (id: number) => {
  try {
    const data = await v3Client.get(`/Load/Search/TrackingNumber/${id}`);
    return data as unknown as TrackingType;
  } catch (error) {
    handleToast(ToastType.Error, 'The page you requested is not found');
  }
};
