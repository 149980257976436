import { ApiResponse, apiClient } from '@api/apiClient';
import { BidOfLoadResponseT } from '@store/capacityTools/types';
import { userStore } from '@store/user';
import { deepConvertPascalToCamelCase } from '@utils/caseTransformers';

import { DUMMY_DATA } from '../../constants';
import {
  ILoadBoardViewModel,
  LoadBoardApiResponseT,
  PostLoadT,
  PostMultipleResponseT,
  SaveSearchSettingsApiResponseT,
  SavedSearchApiResponseT,
  SavedSearchCategory,
  SavedSearchT,
} from './types';

export async function fetchLoadBoardItems(
  dummyData = false,
): Promise<LoadBoardApiResponseT> {
  try {
    if (dummyData) {
      const items = deepConvertPascalToCamelCase(
        DUMMY_DATA.data,
      ) as ILoadBoardViewModel[];

      return {
        items,
        isEnabled: true,
        message: null,
        pendingApprovals: [],
        totalCount: items.length,
        page: 0,
        pageSize: items.length,
        postFilterCount: items.length,
        preFilterCount: items.length,
        membersByGroupName: {},
        officeGroupIdsByMachingNames: {},
      };
    }

    const resp = await apiClient.post('/LoadBoard', {
      page: 0,
      pageSize: 5000,
      filters: [],
      sort: [],
    });
    const value = resp as unknown as LoadBoardApiResponseT;
    const items = value.items.map((order) => {
      order.pickDate = new Date(new Date(order.pickDate).toDateString());
      order.dropDate = new Date(new Date(order.dropDate).toDateString());
      if (
        order.lastCheckCallDate !== null &&
        order.lastCheckCallDate !== undefined
      ) {
        order.lastCheckCallDate = new Date(
          order.lastCheckCallDate.toString().includes('Z')
            ? order.lastCheckCallDate.toString()
            : order.lastCheckCallDate.toString() + 'Z',
        );
      }
      if (
        order.nextCheckCallDateUtc !== null &&
        order.nextCheckCallDateUtc !== undefined
      ) {
        order.nextCheckCallDateUtc = new Date(
          order.nextCheckCallDateUtc.toString().includes('Z')
            ? order.nextCheckCallDateUtc.toString()
            : order.nextCheckCallDateUtc.toString() + 'Z',
        );
      }
      const name = userStore.user.name.trim();
      const myOwnedOrder =
        order.assignedTo === name ||
        order.coveredBy === name ||
        order.owner === name;
      const myCustomers =
        order.accountManager === name || order.salesManager === name;
      order.my = myOwnedOrder ? 'Yes' : 'No';
      order.myCustomers = myCustomers ? 'Yes' : 'No';
      return order;
    });
    return { ...value, items };
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(`Failed to fetch load board data: ${error.message}`);
    }
    throw new Error(`Failed to fetch load board data: ${error}`);
  }
}

export const fetchSavedSearches = async () => {
  try {
    const resp = (await apiClient.get(
      '/user/savedsearch',
    )) as unknown as SavedSearchApiResponseT;

    if (resp.isFailure) {
      throw new Error(`Fetching saved filters failed: ${resp.error.message}`);
    }

    return resp.value.filter((s) => s.category !== SavedSearchCategory.Legacy);
  } catch (error: unknown) {
    throw new Error(`Failed to fetch saved filters with error: ${error}`);
  }
};

export const saveFilter = async (
  model: Record<string, unknown>,
  category: SavedSearchCategory,
  name: string,
) => {
  try {
    const resp = (await apiClient.post('/user/SaveSearchSettings', {
      userId: userStore.user.id,
      Category: category,
      Name: name,
      Params: JSON.stringify(model),
    })) as unknown as SaveSearchSettingsApiResponseT;

    if (resp.isFailure) {
      throw new Error(`Saving filter failed with error: ${resp.error.message}`);
    }

    return resp.value;
  } catch (error: unknown) {
    throw new Error(`Failed to post filter with error: ${error}`);
  }
};

export const deleteFilter = async (search: SavedSearchT) => {
  try {
    await apiClient.delete('/user/DeleteSavedSearch', { data: search });
    return true;
  } catch (error: unknown) {
    throw new Error(`Failed to delete filter with error: ${error}`);
  }
};

export async function changeStatus(id: number, status: string) {
  try {
    await apiClient.post('/load/changestatus', {
      id: id,
      status: status,
    });
  } catch (error: unknown) {
    if (error instanceof Error) {
      throw new Error(`Failed to update status: ${error.message}`);
    }
    throw new Error(`Failed to update status: ${error}`);
  }
}

export async function postMultiple(loads: PostLoadT[]) {
  try {
    const response = await apiClient.post('posting/PostMultiple', {
      LoadPostings: loads,
    });
    return response as unknown as PostMultipleResponseT;
  } catch (error: unknown) {
    throw new Error(`Failed to Share Load Capacity: ${error}`);
  }
}

export async function unPostMultiple(loadIds: number[]) {
  try {
    const response = await apiClient.post('posting/UnPostMultiple', loadIds);
    return response as unknown as PostMultipleResponseT;
  } catch (error: unknown) {
    throw new Error(`Failed to Share Load Capacity: ${error}`);
  }
}

export const getBidsForLoad = async (loadId: number) => {
  try {
    const res = (await apiClient.get(
      `/capacity/truck/bid/load/${loadId}`,
    )) as unknown as ApiResponse<BidOfLoadResponseT>;

    if (res.isSuccess) {
      return res.value;
    }

    return undefined;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(`getBidsForLoad(${loadId})`, error);

    return undefined;
  }
};
