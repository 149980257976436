import {
  DialogBox,
  LTLProductCatalog,
  MenuItemType,
  ToastType,
} from '@components';
import { createForm } from '@felte/solid';
import { validator } from '@felte/validator-yup';
import { closeModal } from '@store/modals';
import {
  CustomerAddress,
  LTLCatalogs,
  LastUsedTopStopModel,
  LoadModeTypes,
  PickUpDropOffItem,
  orderStore,
  setOrderStore,
  updateLoadPropertyAtIndex,
} from '@store/orders';
import { convertDateTimeV2, handleToast } from '@utils/utils';
import _ from 'lodash';
import { DateTime } from 'luxon';
import { Accessor, createEffect, createSignal, Setter } from 'solid-js';
import AddressBookGrid from '@views/customer/customerDetail/addressBookTab/AddressBookGrid';
import { ColDef } from 'ag-grid-community';
import {
  AddressBookModel,
  addressBookStore,
  deleteAddress,
  getAddressBookData,
  setAddressBookStore,
} from '@store/customers/customerDetails/addressBookTab';
import { render } from 'solid-js/web';
import { dateFormatter } from '@store/loadboard';
import { dateComparator } from '@store/loadboard/utils';
import { Box, IconButton, Stack } from '@suid/material';
import { openDialogBox } from '@store/dialogBox';
import { Delete, PlaylistAddCheck } from '@suid/icons-material';
import { ltlQuoteState, setLtlQuoteState } from '@store/ltl/store';

import { validations } from './validations';
import { IntialStopFormValues } from './types';
import { initialFormValues } from './defaultValues';
import { StopsMain } from './StopsMain';

export interface StopEditorProps {
  stop: LastUsedTopStopModel | null;
  hasDropOffStop: boolean;
  dropOffOptions: MenuItemType[];
  pickUpOptions: MenuItemType[];
  stopIds: { [key: string]: number };
  isSidebarRender?: boolean;
  mode: LoadModeTypes | undefined;
  currentNewStopId: number;
  currentNewLoadItemId: number;
  setActiveView: Setter<string>;
  activeView: Accessor<string>;
}

const StopEditor = (props: StopEditorProps) => {
  const [setAddressBook] = createSignal<CustomerAddress[]>([]);
  const [smallestLoadItemId, setSmallestLoadItemId] = createSignal<number>(
    props.currentNewLoadItemId,
  );

  const [isDeleteLoading, setIsDeleteLoading] = createSignal(false);
  const [deleteRow, setDeleteRow] = createSignal<AddressBookModel | null>(null);

  const modalId = 'stops-modal';

  const handleDeleteRow = async (address: AddressBookModel) => {
    try {
      setIsDeleteLoading(true);
      const res = await deleteAddress(address.id as string);
      if (Boolean(res)) {
        setAddressBookStore('items', (items) =>
          items.filter((item) => item.id !== address.id),
        );
        handleToast(ToastType.Success, 'Address Successfully Deleted!');
      }
    } catch (error) {
      setAddressBookStore('isLoading', false);
      throw new Error(`Failed to Delete Address Data: ${error}`);
    } finally {
      setIsDeleteLoading(false);
    }
  };

  const { form, errors, data, setFields, isDirty, setIsDirty } =
    createForm<IntialStopFormValues>({
      initialValues: {
        ...initialFormValues,
        mode: orderStore.order.loads[orderStore.activeTab.index]?.mode,
        loadItems: [],
      },
      extend: validator({
        schema: validations(),
      }),
      onSubmit: () => {
        setOrderStore('isStopsEdited', isDirty());
        handleStopSubmit();
      },
    });

  createEffect(() => {
    if (props.stop) {
      setFieldsForEditStop(props.stop);
    }
    if (!props.stop && data().id !== props.currentNewStopId) {
      setFields('id', props.currentNewStopId);
    }
  });
  createEffect(() => {
    if (
      orderStore.order.loads[orderStore.activeTab.index].mode !== data().mode
    ) {
      setFields(
        'mode',
        orderStore.order.loads[orderStore.activeTab.index]
          .mode as LoadModeTypes,
      );
    }
  });

  // eslint-disable-next-line complexity
  const handleStopSubmit = () => {
    const stopsInStore = [
      ...(orderStore.order.loads[orderStore.activeTab.index].stops ?? []),
    ];
    const customerId = Boolean(orderStore.order.customerId)
      ? orderStore.order.customerId
      : 0;
    if (data().id === 0) {
      setFields('id', props.currentNewStopId);
    }
    const {
      loadItems,
      driverDateIn,
      driverDateOut,
      driverTimeIn,
      driverTimeOut,
      dateAppointment,
      timeAppointment,
      time,
      stopDateTime,
      ...rest
    } = data();
    let stop: Partial<LastUsedTopStopModel> = {
      ...rest,
      id: data().id,
      customerId: customerId!,
      loadId: orderStore.order.loads[orderStore.activeTab.index].id,
      phone: data().phone.replace(/[^0-9]/g, ''),
      time: time,
      driverInTime: convertDateTimeV2(driverDateIn, driverTimeIn),
      driverOutTime: convertDateTimeV2(driverDateOut, driverTimeOut),
      stopDateTime: stopDateTime,
      appointmentDate:
        (timeAppointment === '' && dateAppointment !== '') ||
        (dateAppointment && timeAppointment)
          ? convertDateTimeV2(dateAppointment, timeAppointment)
          : '',
    };
    if (!props.stop) {
      stop.createdDate = new Date().toISOString();
      stop.timeZoneOffset = new Date().getTimezoneOffset();
      stop.driverOut = {
        date: DateTime.fromISO(driverDateOut).toFormat('MM/dd/yyyy'),
        time: DateTime.fromISO(driverTimeOut).toFormat('hh:mm a'),
      };
      stop.driverIn = {
        date: DateTime.fromISO(driverDateIn).toFormat('MM/dd/yyyy'),
        time: DateTime.fromISO(driverTimeIn).toFormat('hh:mm a'),
      };
      if (stop.needsAppointment ?? false) {
        stop.appointmentDate =
          (timeAppointment === '' && dateAppointment !== '') ||
          (dateAppointment && timeAppointment)
            ? convertDateTimeV2(dateAppointment, timeAppointment)
            : '';
        stop.appointment = {
          date: DateTime.fromISO(dateAppointment).toFormat('MM/dd/yyyy'),
          time: DateTime.fromISO(timeAppointment).toFormat('hh:mm a'),
        };
      }
      // These values are required for the UI to work
      stop.arrived = false;
      let highestStopOrder = -1;
      stopsInStore.forEach((stop) => {
        if ((stop.stopOrder ?? -1) > highestStopOrder) {
          highestStopOrder = stop.stopOrder ?? 0;
        }
      });
      stop.stopOrder = highestStopOrder + 1;
      stopsInStore.push(stop as LastUsedTopStopModel);
    } else {
      stop.modifiedDate = new Date().toISOString();
      if (stop.needsAppointment ?? false) {
        stop.appointmentDate =
          (timeAppointment === '' && dateAppointment !== '') ||
          (dateAppointment && timeAppointment)
            ? convertDateTimeV2(dateAppointment, timeAppointment)
            : '';
      }
      const index = stopsInStore.findIndex((itm) => itm.id === props.stop?.id);
      stop = { ...props.stop, ...stop };
      stopsInStore[index] = stop as LastUsedTopStopModel;
    }
    const { stops } = orderStore.order.loads[orderStore.activeTab.index];

    const firstRelevantStop = _.find(stops, (s) => {
      return s.dropOff === stop.pickUp && s.operationType !== 'Delete';
    });

    const items = loadItems.map((item) => {
      const itm = {
        ...item,
        loadItemHazmatDetail: {
          ...item.loadItemHazmatDetail,
          contactNumber:
            item.loadItemHazmatDetail !== null &&
            item.loadItemHazmatDetail !== undefined
              ? // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                Boolean(item.loadItemHazmatDetail.contactNumber)
                ? item.loadItemHazmatDetail.contactNumber.replace(/[^0-9]/g, '')
                : ''
              : '',
        },
      };
      if (Boolean(stop.dropOff) && stop.id === itm.dropOffId) {
        itm.pickUpId =
          itm.pickUpId !== null
            ? itm.pickUpId
            : firstRelevantStop
              ? firstRelevantStop.id
              : null;
      } else if (Boolean(stop.pickUp) && stop.id === itm.pickUpId) {
        itm.dropOffId =
          itm.dropOffId !== null
            ? itm.dropOffId
            : firstRelevantStop
              ? firstRelevantStop.id
              : null;
      }
      return itm;
    });
    updateLoadPropertyAtIndex({ stops: stopsInStore, items: items });
    if (ltlQuoteState.quote) {
      const newQuoteInfo = { ...ltlQuoteState.quote };
      stopsInStore.forEach((stop) => {
        if (Boolean(stop.origin) || stop.pickUp) {
          newQuoteInfo.originCity = stop.city;
          newQuoteInfo.originState = stop.state;
          newQuoteInfo.originZip = stop.zip;
          newQuoteInfo.originCountry = stop.countryCode;
        } else if (Boolean(stop.destination) || stop.dropOff) {
          newQuoteInfo.destinationCity = stop.city;
          newQuoteInfo.destinationState = stop.state;
          newQuoteInfo.destinationZip = stop.zip;
          newQuoteInfo.destinationCountry = stop.countryCode;
        }
      });
      newQuoteInfo.loadItems = items;
      setLtlQuoteState('quote', newQuoteInfo);
    }
    closeModal(modalId);
  };

  // eslint-disable-next-line complexity
  const setFieldsForEditStop = (stop: LastUsedTopStopModel) => {
    setFields('address1', stop.address1 ?? '');
    setFields('address2', stop.address2 ?? '');
    setFields('city', stop.city ?? '');
    setFields('state', stop.state ?? '');
    setFields('zip', stop.zip);
    setFields('contact', stop.contact ?? '');
    setFields('locationName', stop.locationName ?? '');
    setFields('phone', stop.phone ?? '');
    setFields('email', stop.email ?? '');
    setFields('description', stop.description ?? '');
    setFields('instructions', stop.instructions ?? '');
    setFields('directions', stop.directions ?? '');
    setFields('stopDateTime', stop.stopDateTime ?? '');
    setFields('dateAppointment', stop.appointmentDate ?? '');
    setFields('timeAppointment', stop.appointmentDate ?? '');
    setFields('referenceNum', stop.referenceNum ?? '');
    setFields('time', stop.time ?? '');
    setFields('pickUp', stop.pickUp);
    setFields('dropOff', stop.dropOff);
    setFields('countryCode', stop.countryCode ?? 'USA');
    setFields('driverDateIn', stop.driverInTime ?? '');
    setFields('driverTimeIn', stop.driverInTime ?? '');
    setFields('driverDateOut', stop.driverOutTime ?? '');
    setFields('driverTimeOut', stop.driverOutTime ?? '');
    setFields('needsAppointment', stop.needsAppointment);
    setFields(
      'operationType',
      stop.operationType === 'None' ? 'Update' : stop.operationType,
    );
    setFields('id', stop.id);
    setFields(
      'loadItems',
      orderStore.order.loads[orderStore.activeTab.index].items ?? [],
    );
    if (orderStore.order.edi) {
      setFields('stopCode', stop.stopCode ?? '');
      setFields('proprietaryCode', stop.proprietaryCode ?? '');
      setFields('appointmentNum', stop.appointmentNum ?? '');
    }
  };

  const addListItemFromLTLCatalog = (item: LTLCatalogs) => {
    setFields('loadItems', [
      ...data().loadItems,
      {
        item: item.unitType || 'Pallets',
        class: item.class?.toString(),
        nmfc: item.nMFC,
        description: item.description,
        unitType: item.unitType,
        lengthInch: item.lengthInches,
        widthInch: item.widthInches,
        heightInch: item.heightInches,
        id: smallestLoadItemId(),
        operationType: 'Insert',
        loadId: orderStore.order.loads[orderStore.activeTab.index].id,
        dropOffId: data().dropOff ? data().id : null,
        pickUpId: data().pickUp ? data().id : null,
        quantity: 1,
        weight: 0,
        pieces: null,
        loadItemHazmatDetail: item.hazmat
          ? {
              hazardClass: item.hazardClass || '',
              packingGroup: item.packingGroup || '',
              unNumber: item.uNNumber || '',
              contactName: item.contactName || '',
              contactNumber: item.contactNumber || '',
              piece: item.piece || '',
            }
          : null,
      },
    ] as PickUpDropOffItem[]);
    setSmallestLoadItemId(smallestLoadItemId() - 1);
    props.setActiveView('stops-main');
  };

  const handleAddressClick = (rowData: CustomerAddress) => {
    setFields('locationName', rowData.name ?? '');
    setFields('address1', rowData.addressLine1 ?? '');
    setFields('address2', rowData.addressLine2 ?? '');
    setFields('city', rowData.city ?? '');
    setFields('state', rowData.state ?? '');
    setFields('zip', rowData.zip ?? '');
    setFields('contact', rowData.contactName ?? '');
    setFields('phone', rowData.contactPhone ?? '');
    setFields('instructions', rowData.specialInstructions ?? '');
    setFields('description', rowData.internalNote ?? '');
    setFields('directions', rowData.drivingDirections ?? '');
    props.setActiveView('stops-main');
  };

  const commonFilterParams = {
    suppressAndOrCondition: true,
    buttons: ['apply', 'reset'],
    filterOptions: ['startsWith', 'equals', 'notEqual'],
  };
  const DefaultOrigin = (data: AddressBookModel) => {
    const statusIcon = data.isDefaultAddress && (
      <PlaylistAddCheck class="text-[#15668f]" />
    );
    return (
      <Box class="flex gap-3">
        <span class={`${data.isDefaultAddress && 'font-semibold'}`}>
          {data.name} {statusIcon}
        </span>
      </Box>
    );
  };

  const AddressColumn = (val1: string | null, val2: string | null) => {
    return (
      <Stack direction="column">
        <span>{val1}</span>
        <span>{val2}</span>
      </Stack>
    );
  };

  const agGridCellStyle = {
    padding: '10px',
    'word-break': 'break-word',
    'white-space': 'normal',
    'line-height': '1.6 ',
    'align-items': 'center !important',
  };

  const columnDefs: ColDef[] = [
    {
      field: 'name',
      headerName: 'Name',
      width: 180,
      filter: 'agTextColumnFilter',
      filterParams: commonFilterParams,
      cellRenderer: (params: { data: AddressBookModel }) => {
        const container = document.createElement('div');
        const [Component] = createSignal(DefaultOrigin(params.data));
        render(() => <Component />, container);
        return container;
      },
    },
    {
      field: 'addressLine1',
      headerName: 'Address',
      filter: 'agTextColumnFilter',
      filterParams: commonFilterParams,
      cellRenderer: (params: { data: AddressBookModel }) => {
        const container = document.createElement('div');
        const [Component] = createSignal(
          AddressColumn(params.data.addressLine1, params.data.addressLine2),
        );
        render(() => <Component />, container);
        return container;
      },
      cellStyle: agGridCellStyle,
    },
    {
      field: 'city',
      headerName: 'City',
      filter: 'agTextColumnFilter',
      filterParams: commonFilterParams,
    },
    {
      field: 'state',
      headerName: 'State',
      filter: 'agTextColumnFilter',
      filterParams: commonFilterParams,
      maxWidth: 100,
    },
    {
      field: 'contactName',
      headerName: 'Contact',
      filter: 'agTextColumnFilter',
      filterParams: commonFilterParams,
      cellRenderer: (params: { data: AddressBookModel }) => {
        const container = document.createElement('div');
        const [Component] = createSignal(
          AddressColumn(params.data.contactName, params.data.contactPhone),
        );
        render(() => <Component />, container);
        return container;
      },
      cellStyle: agGridCellStyle,
    },
    {
      field: 'createdOn',
      headerName: 'Created',
      filter: 'agDateColumnFilter',
      valueFormatter: dateFormatter,
      filterParams: {
        comparator: dateComparator,
        buttons: ['apply', 'reset'],
        suppressAndOrCondition: true,
        filterOptions: ['startsWith', 'equals', 'notEqual'],
      },
    },
    {
      field: 'specialInstructions',
      headerName: 'Location Instructions',
      filter: 'agTextColumnFilter',
      filterParams: commonFilterParams,
    },
    {
      field: 'delete',
      filter: false,
      cellRenderer: (params: { data: AddressBookModel }) => {
        return (
          <IconButton
            onClick={() => {
              setDeleteRow(params.data);
              openDialogBox('deleteAddressItem');
            }}
            disabled={isDeleteLoading()}
          >
            <Delete class="text-red-700" />
          </IconButton>
        );
      },
      pinned: 'right',
      maxWidth: 100,
    },
  ];

  return (
    <>
      <div class={props.activeView() === 'stops-main' ? 'block p-2' : 'hidden'}>
        <StopsMain
          hasDropOffStop={props.hasDropOffStop}
          form={form}
          setActiveView={props.setActiveView}
          setAddressBook={setAddressBook}
          errors={
            errors as unknown as Accessor<
              Record<keyof IntialStopFormValues, string[] | null>
            >
          }
          setIsDirty={setIsDirty}
          data={data}
          setFields={setFields}
          setSmallestLoadItemId={setSmallestLoadItemId}
          smallestLoadItemId={smallestLoadItemId}
          modalId={modalId}
          dropOffOptions={props.dropOffOptions}
          pickUpOptions={props.pickUpOptions}
          mode={props.mode!}
          stop={props.stop}
          stopIds={props.stopIds}
        />
      </div>
      <div
        class={
          props.activeView() === 'ltl-product-catalog' ? 'block' : 'hidden'
        }
      >
        <LTLProductCatalog
          customerId={orderStore.order.customerId!}
          goBackButtonText="< Back to Stops"
          goBack={() => props.setActiveView('stops-main')}
          onRowClick={(rowData: LTLCatalogs) => {
            addListItemFromLTLCatalog(rowData);
          }}
        />
      </div>
      <div
        class={
          props.activeView() === 'select-from-addresses' ? 'block' : 'hidden'
        }
      >
        <Box class="ag-theme-alpine" height={550}>
          <AddressBookGrid
            addressBookColumns={columnDefs}
            items={addressBookStore.items}
            onGridReady={async () => {
              Boolean(orderStore.order.customerId) &&
                (await getAddressBookData(
                  orderStore.order.customerId!.toString(),
                ));
            }}
            onRowClick={(rowData: CustomerAddress) => {
              handleAddressClick(rowData);
            }}
            customerId={orderStore.order.customerId ?? undefined}
          />
          <DialogBox
            id="deleteAddressItem"
            title={'Are you sure you want to delete this Address item?'}
            onSubmit={async () => {
              await handleDeleteRow(deleteRow() as AddressBookModel);
              setDeleteRow(null);
            }}
            onSubmitText="Delete"
          />
        </Box>
      </div>
    </>
  );
};

export default StopEditor;
