import { Layout } from '@layouts/index';
import { createSignal, Show } from 'solid-js';
import AgGridSolid, { AgGridSolidRef } from 'ag-grid-solid';
import { atgLogo } from '@views/ltl';
import { Box, Grid } from '@suid/material';
import {
  CellClickedEvent,
  GridReadyEvent,
  IServerSideDatasource,
} from 'ag-grid-community';
import {
  FactoringCompaniesQueryParams,
  FactoringCompany as FactoringCompanyT,
  fetchFactoringCompanies,
} from '@store/factoringCompany';
import { DateTime } from 'luxon';
import {
  columnsToolPanel,
  filtersToolPanel,
} from '@components/Grid/components/constants';
import { useNavigate } from '@solidjs/router';
import { isLTLAdmin } from '@utils/utils';

import { factoringCompanyColumnDefs } from './components/FactoringCompanyDef';
import { FactoringCompanyHeader } from './components/FactoringCompanyHeader';

export const FactoringCompany = () => {
  const [gridRef, setGridRef] = createSignal<AgGridSolidRef | null>(null);

  const navigate = useNavigate();

  const onGridReady = (params: GridReadyEvent) => {
    setGridRef(params);
    const dataSource = {
      getRows: async (params: {
        api: {
          paginationGetCurrentPage: () => number;
        };
        request: {
          filterModel: {
            [key: string]: {
              filter?: string;
              dateFrom?: string | null;
              dateTo?: null;
              filterType: 'date' | 'text' | 'set';
              type: string;
              values: string[];
            };
          };
          sortModel: {
            colId: string;
            sort: string;
          }[];
        };
        success: (data: {
          rowData: FactoringCompanyT[];
          rowCount: number;
        }) => void;
        fail: () => void;
      }) => {
        let filter = '';
        let orderby = 'ModifiedDate desc';

        if (params.request.sortModel.length > 0) {
          const sortModel = params.request.sortModel[0];
          orderby = `${
            sortModel.colId.charAt(0).toUpperCase() + sortModel.colId.slice(1)
          } ${sortModel.sort}`;
        }

        if (Object.keys(params.request.filterModel).length > 0) {
          const filterString: string[] = [];

          const formatDate = (date: string | null) => {
            return date!
              ? DateTime.fromFormat(date, 'yyyy-MM-dd HH:mm:ss', {
                  zone: 'utc',
                })
                  .setZone('local')
                  .toISO()
              : '';
          };

          const handleDateFilter = (
            keyFilter: string,
            filterValue: {
              dateFrom?: string | null;
              dateTo?: string | null;
              filterType: 'text' | 'set' | 'date';
              type: string;
            },
          ) => {
            const dateFrom = formatDate(filterValue.dateFrom!);
            const dateTo = formatDate(filterValue.dateTo!);

            switch (filterValue.type) {
              case 'inRange':
                return `${keyFilter} ge ${dateFrom} and ${keyFilter} le ${dateTo}`;
              case 'equals':
                return `${keyFilter} eq ${dateFrom}`;
              case 'notEqual':
                return `${keyFilter} ne ${dateFrom}`;
              case 'lessThan':
                return `${keyFilter} lt ${dateFrom}`;
              case 'greaterThan':
                return `${keyFilter} gt ${dateFrom}`;
              case 'blank':
                return `${keyFilter} eq null`;
              case 'notBlank':
                return `${keyFilter} ne null`;
              default:
                return '';
            }
          };

          Object.keys(params.request.filterModel).forEach((key: string) => {
            const filterValue = params.request.filterModel[key];
            const keyFilter = key.charAt(0).toUpperCase() + key.slice(1);

            if (filterValue.filterType === 'date') {
              const dateFilter = handleDateFilter(keyFilter, filterValue);
              if (dateFilter) filterString.push(dateFilter);
            } else {
              const textFilterMap: { [key: string]: string } = {
                notEqual: `${keyFilter} ne '${filterValue.filter}'`,
                equals: `${keyFilter} eq '${filterValue.filter}'`,
                notContains: `indexof(${keyFilter},'${filterValue.filter}') eq -1`,
                blank: `${keyFilter} eq ''`,
                notBlank: `${keyFilter} ne ''`,
              };

              const textFilter =
                textFilterMap[filterValue.type] ||
                `${filterValue.type.toLowerCase()}(${keyFilter},'${
                  filterValue.filter
                }')`;

              if (textFilter) filterString.push(textFilter);
            }
          });
          filter = filterString.join(' and ');
        }
        const queryParams: FactoringCompaniesQueryParams = {
          $top: 50,
          $skip: params.api.paginationGetCurrentPage() * 50,
          $orderby: orderby,
          $format: 'json',
          $count: true,
        };

        if (filter) {
          queryParams.$filter = filter;
        }

        try {
          gridRef()?.api.showLoadingOverlay();
          const data = await fetchFactoringCompanies(queryParams);

          if (data) {
            if (data.count === 0) {
              gridRef()?.api.showNoRowsOverlay();
              params.success({
                rowData: [],
                rowCount: 0,
              });
            } else {
              params.success({
                rowData: data.items,
                rowCount: data.count,
              });
              gridRef()?.api.hideOverlay();
            }
          }
        } catch (error) {
          params.fail();
        }
      },
    };
    params.api.setGridOption(
      'serverSideDatasource',
      dataSource as unknown as IServerSideDatasource,
    );
  };

  return (
    <Layout>
      <FactoringCompanyHeader />
      <Box
        class="!rounded-md p-4
        !h-[calc(100%-100px)]
        !overflow-y-auto "
        bgcolor="#E1ECF2"
      >
        <Show when={isLTLAdmin()}>
          <Grid class="ag-theme-alpine !flex-1 h-[calc(100%-10px)]">
            <AgGridSolid
              ref={gridRef}
              overlayLoadingTemplate={atgLogo}
              overlayNoRowsTemplate={'No data found'}
              columnDefs={factoringCompanyColumnDefs}
              suppressNoRowsOverlay={false}
              gridOptions={{
                defaultColDef: {
                  flex: 1,
                  filter: true,
                },
                onGridReady: onGridReady,
                paginationPageSizeSelector: false,
                rowModelType: 'serverSide',
                pagination: true,
                paginationPageSize: 50,
                suppressPaginationPanel: false,
                cacheBlockSize: 50,
                sideBar: {
                  toolPanels: [columnsToolPanel, filtersToolPanel],
                  defaultToolPanel: '',
                },
              }}
              onCellClicked={(e: CellClickedEvent) => {
                const factoringCompanyId = (e.data as { id: number }).id;
                navigate('details/' + factoringCompanyId);
              }}
            />
          </Grid>
        </Show>
      </Box>
    </Layout>
  );
};
