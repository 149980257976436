import {
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  ListSubheader,
  MenuItem,
  Select,
} from '@suid/material';
import { SelectChangeEvent } from '@suid/material/Select';
import { SxProps } from '@suid/system';
import {
  Component,
  JSXElement,
  Show,
  createSignal,
  mergeProps,
  onCleanup,
  onMount,
} from 'solid-js';

import FormErrorComponent from './FormErrorComponent';
import { selectinputClasses as classes } from './classes';

const ITEM_HEIGHT = 60;
const ITEM_PADDING_TOP = 4;
export const MenuProps = {
  PaperProps: {
    style: {},
  },
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'left',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'left',
  },
};

const iconStyles = {
  position: 'absolute',
  height: '100%',
  width: '40px',
  padding: '6px',
  top: '0',
  right: '0',
  color: 'rgb(27, 102, 143)',
  border: '1px solid #ccc',
  background: '#fff',
  zIndex: 1,
  borderTopRightRadius: 'inherit',
  borderBottomRightRadius: 'inherit',
};

type Styles<T = string> = {
  [key: string]: T | Styles<T>;
};

export type MenuItemType = {
  label: string | JSXElement;
  value: string | number;
  disabled?: boolean;
  extra?: Record<string, unknown>;
};

type SelectInputProps = {
  label?: string;
  menuItems: string[] | MenuItemType[] | Record<string, MenuItemType[]>;
  onChange?: (e: SelectChangeEvent, isProgrammatic: boolean) => void;
  onBlur?: () => void;
  value?: string | string[];
  multiple?: boolean;
  width?: string;
  sxProps?: SxProps;
  menuProps?: Styles;
  customIconStyle?: Styles;
  size?: 'small' | 'medium';
  boxedIcon?: boolean;
  name?: string;
  error?: string | string[] | null;
  disabled?: boolean;
  required?: boolean;
  backgroundColor?: string;
  placeholder?: string;
  variant?: 'standard' | 'outlined' | 'filled';
  labelStyle?: Styles;
  renderValue?: (selected: string | string[]) => JSXElement;
  disableUnderline?: boolean;
  groupBy?: boolean;
  noErrorMessage?: boolean;
  customWidth?: string;
  setFitContent?: boolean;
  defaultSx?: boolean;
  menuItemGrow?: boolean;
  setValueOnKeyPressFeatureOn?: boolean;
  dataTestId?: string;
};

// Define default props
const defaultProps: Partial<SelectInputProps> = {
  value: '',
  error: null,
  disabled: false,
  required: false,
  setValueOnKeyPressFeatureOn: true,
};

export const renderSelectOption = (
  selected: string | string[],
  menuItems: string[] | MenuItemType[] | Record<string, MenuItemType[]>,
  placeholder?: string,
  groupBy = false,
  focused = false,
) => {
  const selectedItems = Array.isArray(selected) ? selected : [selected];

  if (selectedItems.length === 0 && focused) {
    return <>{placeholder}</>;
  }

  const items: MenuItemType[] =
    Array.isArray(menuItems) && typeof menuItems[0] === 'string'
      ? menuItems.map((item) => ({ label: item, value: item }))
      : groupBy
        ? Object.values(menuItems).flat()
        : (menuItems as MenuItemType[]);

  const selectedLabels = items
    .filter((item) => selectedItems.includes(item.value))
    .map((item) => item.label);

  return selectedLabels.join(', ');
};

export const SelectField: Component<SelectInputProps> = (props) => {
  props = mergeProps(defaultProps, props);
  const [focused, setFocused] = createSignal(false);
  const [isProgrammatic, setIsProgrammatic] = createSignal(false);
  const [checkKeyPressCount, setCheckKeyPressCount] = createSignal(0);
  const [lastPressedKey, setLastPressedKey] = createSignal('');

  let selectControlRef:
    | HTMLDivElement
    | ((el: HTMLDivElement) => void)
    | undefined;

  function calculateWidth(): number | undefined {
    if (props.customWidth !== undefined) {
      return parseInt(props.customWidth);
    }
    if (Boolean(props.menuItemGrow)) {
      return undefined;
    }
    return selectControlRef instanceof HTMLDivElement
      ? selectControlRef.clientWidth
      : 0;
  }

  const getStyles = () => {
    if (Boolean(props.customIconStyle)) {
      return { ...props.sxProps, ...props.customIconStyle };
    } else if (Boolean(props.boxedIcon)) {
      return {
        background: props.backgroundColor ?? '#fff',
        '.MuiSelect-icon': iconStyles,
        ...props.sxProps,
      };
    }
    return {
      ...(props.size === 'small'
        ? {
            height: '36px',
            fontSize: '14px',
            '& label': {
              fontSize: '14px',
            },
          }
        : {}),
      borderRadius: '4px',
      ...props.sxProps,
      background: props.backgroundColor ?? '#fff',
    };
  };

  const handleBlur = () => {
    setFocused(false);
    props.onBlur && props.onBlur();
  };

  const isItemSelected = (value: string, selected: string[]) => {
    return selected.indexOf(value) !== -1;
  };

  const renderMenuItem = (item: MenuItemType | string) => {
    if (typeof item === 'string') {
      return (
        <MenuItem value={item} class="data-testid">
          {Boolean(props.multiple) && (
            <Checkbox checked={isItemSelected(item, props.value as string[])} />
          )}
          <ListItemText primary={item} />
        </MenuItem>
      );
    }
    return (
      <MenuItem
        name={item.value}
        value={item.value}
        class="datatestid"
        disabled={item.disabled}
      >
        {Boolean(props.multiple) && (
          <Checkbox checked={props.value!.indexOf(item.value as string) > -1} />
        )}
        <ListItemText class={classes.menuItemText} primary={item.label} />
      </MenuItem>
    );
  };

  const renderMenuItems = (items: MenuItemType[]) => items.map(renderMenuItem);

  const renderGroupedMenuItems = (key: string) => (
    <>
      <ListSubheader class="capitalize">{key}</ListSubheader>
      {Array.isArray(props.menuItems[key]) &&
        renderMenuItems(props.menuItems[key] as MenuItemType[])}
    </>
  );

  const onKeyDown = (e: KeyboardEvent) => {
    if (selectControlRef === undefined) {
      return;
    }

    const node = selectControlRef as Node;

    if (
      (node.contains(document.activeElement) ||
        node.isEqualNode(document.activeElement)) &&
      e.key === 'Enter'
    ) {
      setIsProgrammatic(true);
    }
  };

  const handleSetValueOnKeyPressFeature = (e: KeyboardEvent) => {
    if (
      Boolean(props.setValueOnKeyPressFeatureOn) &&
      Array.isArray(props.menuItems) &&
      props.menuItems.length > 0
    ) {
      const items = (
        typeof (props.menuItems as string[])[0] === 'string'
          ? (props.menuItems as string[]).filter(
              (item: string) => item.charAt(0).toLowerCase() === e.key,
            )
          : (props.menuItems as MenuItemType[]).filter(
              (item) =>
                (item.label as string).charAt(0).toLowerCase() === e.key,
            )
      ) as (string | MenuItemType)[];
      if (items.length > 0) {
        const selectedItem =
          items[lastPressedKey() === e.key ? checkKeyPressCount() : 0];
        const value =
          typeof selectedItem === 'string' ? selectedItem : selectedItem.value;

        props.onChange?.({ target: { value } } as SelectChangeEvent, true);

        setCheckKeyPressCount((prevCount) =>
          items.length - 1 > prevCount ? prevCount + 1 : 0,
        );
        setLastPressedKey(e.key);
      }
    }
  };

  onMount(() => {
    addEventListener('keydown', onKeyDown);
  });

  onCleanup(() => {
    removeEventListener('keydown', onKeyDown);
  });

  return (
    <FormControl
      fullWidth
      sx={{
        ...(props.defaultSx !== false
          ? {
              width: props.width,
              '& .MuiSelect-select:focus': {
                backgroundColor: props.backgroundColor,
                borderRadius: '4px',
              },
              '& .MuiInputLabel-root.Mui-focused': {
                fontWeight: 600, // Font weight when focused
              },
              '& .MuiInputLabel-root.MuiFormLabel-filled:not(.Mui-disabled):not(.Mui-error)':
                {
                  fontWeight: 600, // Font weight when there is a value
                  color: '#123b50',
                },
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderWidth: '1px',
                  borderColor: '#80b6cf',
                  fontSize: '14px',
                  color: '#123b50',
                  fontWeight: 600,
                },
              },
            }
          : {}),
        width: props.width,
      }}
      ref={selectControlRef}
      error={Boolean(props.error)}
      disabled={props.disabled}
      size={props.size || 'small'}
      variant={props.variant}
    >
      <Show when={Boolean(props.label)} fallback={<></>}>
        <InputLabel sx={props.labelStyle}>{props.label}</InputLabel>
      </Show>
      <Select
        {...props}
        dataTestId={props.dataTestId}
        disableUnderline={props.disableUnderline ?? false}
        label={`${props.label}`}
        displayEmpty
        name={props.name}
        value={props.value}
        onChange={(e) => {
          setTimeout(() => {
            props.onChange?.(e, isProgrammatic());

            if (isProgrammatic()) {
              setIsProgrammatic(false);
            }
          }, 1);
        }}
        data-testid={props.dataTestId}
        onFocus={() => setFocused(true)}
        onKeyPress={handleSetValueOnKeyPressFeature}
        onBlur={handleBlur}
        required={props.required}
        error={Boolean(props.error)}
        renderValue={
          props.renderValue ??
          ((selected: string | string[]) => {
            return renderSelectOption(
              selected,
              props.menuItems,
              props.placeholder,
              props.groupBy,
              focused(),
            );
          })
        }
        MenuProps={{
          ...MenuProps,
          PaperProps: {
            style: {
              'max-height': `${ITEM_HEIGHT * 4 + ITEM_PADDING_TOP}px`,
              width: Boolean(props.setFitContent)
                ? 'fit-content'
                : calculateWidth() + 'px',
            },
          },
          ...props.menuProps,
        }}
        size={props.size || 'small'}
        sx={getStyles()}
      >
        <>
          {props.placeholder !== undefined && (
            <MenuItem disabled value="">
              <ListItemText primary={props.placeholder} />
            </MenuItem>
          )}
          {props.multiple ?? false
            ? renderMenuItems(props.menuItems as MenuItemType[])
            : props.groupBy ?? false
              ? Object.keys(props.menuItems).map(renderGroupedMenuItems)
              : renderMenuItems(props.menuItems as MenuItemType[])}
        </>
      </Select>
      <FormErrorComponent
        error={props.error}
        showHelpertext={!Boolean(props.noErrorMessage)}
      />
    </FormControl>
  );
};
