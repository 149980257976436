import { BasicModal, Typography } from '@components';
import {
  CheckboxInput,
  PhoneInput,
  RadioInput,
  TextInput,
} from '@components/forms';
import { NumericInput } from '@components/forms/NumericInput';
import Tooltip from '@components/Tooltip';
import { closeModal, openModal } from '@store/modals';
import {
  ILoadEditViewModel,
  LTLShipmentDataViewModel,
  orderStore,
  updateLoadPropertyAtIndex,
  validateFieldForOrder,
} from '@store/orders';
import { userStore } from '@store/user';
import WarningIcon from '@suid/icons-material/Warning';
import { Box, Stack } from '@suid/material';
import * as ST from '@suid/types';
import { isAdmin, isSuperAdmin } from '@utils/utils';
import { AcceptTrackingModal } from '@views/order/components/models';
import { ACCEPT_TRACKING_CHARGES_ID } from '@views/order/constants';
import { Show } from 'solid-js';

import { canOverrideCarrierHistory, getTotalWeightAndUnit } from './utils';

type PropsT = {
  load: ILoadEditViewModel;
  tabIndex: number;
};

// eslint-disable-next-line complexity
export const DriverDetails = (props: PropsT) => {
  const showSecondaryDriver = () => {
    return props.load.mode !== 'LTL' && props.load.teamRequired == true;
  };

  const handleRadioChange = (e: ST.ChangeEvent<HTMLInputElement>) => {
    updateLoadPropertyAtIndex({
      cargoValueIsHigh: e.target.value,
    });
  };

  const acceptTrackingModalId = `${ACCEPT_TRACKING_CHARGES_ID}-${props.tabIndex}-modal`;

  return (
    <Box flex={1}>
      <Box mb={1}>
        <Typography
          variant="body1"
          class="text-base font-bold text-armstrong-primary"
        >
          Driver Details
        </Typography>
      </Box>
      <Stack gap={1}>
        <Box>
          <TextInput
            label="Primary Driver Name"
            placeholder="Enter Driver Name"
            error={
              orderStore.orderFormError &&
              orderStore.orderFormError[`loads[${props.tabIndex}].driver`]
            }
            variant="outlined"
            value={props.load.driver ?? ''}
            size="small"
            onChange={(value) => {
              updateLoadPropertyAtIndex({
                driver: value as string,
              });
            }}
            disabled={orderStore.isReadOnly}
          />
        </Box>
        <Box>
          <PhoneInput
            label="Primary Driver Phone"
            placeholder="Enter Driver Phone"
            variant="outlined"
            value={props.load.driverPhoneNumber ?? ''}
            size="small"
            onChange={(value) => {
              updateLoadPropertyAtIndex({
                driverPhoneNumber: value,
              });
            }}
            disabled={orderStore.isReadOnly}
          />
          <div class="mt-1">
            <CheckboxInput
              label="Enable Tracking"
              checked={orderStore.order.loads[props.tabIndex].trackingEnabled}
              size="small"
              onChange={(prevValue) => {
                if (prevValue) {
                  openModal(acceptTrackingModalId);
                }
                if (
                  !Boolean(
                    orderStore.order.loads[props.tabIndex].trackingServiceId,
                  )
                ) {
                  updateLoadPropertyAtIndex({
                    trackingEnabled:
                      !orderStore.order.loads[props.tabIndex].trackingEnabled,
                  });
                }
              }}
              disabled={
                !(
                  props.load.driverPhoneNumber !== '' ||
                  (props.load.truckNumber?.trim().length ?? 0) > 0 ||
                  (props.load.trailerNumber?.trim().length ?? 0) > 0
                ) ||
                orderStore.isReadOnly ||
                Boolean(props.load.trackingServiceId)
              }
            />
            <BasicModal
              id={acceptTrackingModalId}
              title="Accept Tracking Changes"
              width={'60vw'}
              onClose={() => {
                closeModal(acceptTrackingModalId);
              }}
              footer={false}
              showClose={false}
            >
              <AcceptTrackingModal
                load={props.load}
                modalId={acceptTrackingModalId}
                tabIndex={props.tabIndex}
                setTracking={(e: boolean) =>
                  updateLoadPropertyAtIndex({
                    trackingEnabled: e,
                  })
                }
              />
            </BasicModal>
          </div>
        </Box>
        <Show when={showSecondaryDriver()}>
          <Box>
            <TextInput
              label="Secondary Driver Name"
              placeholder="Second Driver"
              variant="outlined"
              value={props.load.secondDriver ?? ''}
              size="small"
              maxLength={30}
              onChange={(value) => {
                updateLoadPropertyAtIndex({
                  secondDriver: value as string,
                });
              }}
              disabled={orderStore.isReadOnly}
            />
          </Box>
          <Box>
            <PhoneInput
              label="Secondary Driver Phone"
              placeholder="Second Driver Phone #"
              variant="outlined"
              value={props.load.secondDriverPhoneNumber ?? ''}
              size="small"
              onChange={(value) => {
                updateLoadPropertyAtIndex({
                  secondDriverPhoneNumber: value,
                });
              }}
              disabled={orderStore.isReadOnly}
            />
          </Box>
          <Box>
            <Tooltip
              text="Tracking will only be available for the Primary Driver's phone number"
              placement="bottom"
              tootlTipClasses="z-10"
            >
              <Typography component={'p'} variant="body2">
                <WarningIcon class="text-[16px] text-[#ccb10e]" />
                Tracking Notice
              </Typography>
            </Tooltip>
          </Box>
        </Show>
        <Box>
          <TextInput
            label="Truck Number"
            placeholder="Enter Truck Number"
            variant="outlined"
            error={
              orderStore.orderFormError &&
              orderStore.orderFormError[`loads[${props.tabIndex}].truckNumber`]
            }
            value={props.load.truckNumber ?? ''}
            size="small"
            onChange={(value) => {
              updateLoadPropertyAtIndex({
                truckNumber: value as string,
              });
            }}
            disabled={orderStore.isReadOnly}
          />
        </Box>
        <Box>
          <TextInput
            label="Trailer Number"
            placeholder="Enter Trailer Number"
            variant="outlined"
            error={
              orderStore.orderFormError &&
              orderStore.orderFormError[
                `loads[${props.tabIndex}].trailerNumber`
              ]
            }
            value={props.load.trailerNumber ?? ''}
            size="small"
            onChange={(value) => {
              updateLoadPropertyAtIndex({
                trailerNumber: value as string,
              });
            }}
            disabled={orderStore.isReadOnly}
          />
        </Box>
        <Show when={props.load.mode === 'LTL'}>
          <TextInput
            type="text"
            size="small"
            label="Pro Number"
            variant="outlined"
            value={props.load.ltlShipmentData?.proNumber ?? ''}
            onChange={(value) => {
              if (props.load.ltlShipmentData !== undefined) {
                const newLtlShipmentData = JSON.parse(
                  JSON.stringify(props.load.ltlShipmentData),
                ) as LTLShipmentDataViewModel;
                newLtlShipmentData.proNumber = value as string;
                newLtlShipmentData.operationType = 'Update';
                updateLoadPropertyAtIndex({
                  ltlShipmentData: newLtlShipmentData,
                });
              }
            }}
            disabled={!Boolean(props.load.ltlShipmentData?.shipmentId)}
          />
          <TextInput
            type="text"
            variant="outlined"
            size="small"
            label="Carrier PU #"
            value={props.load.ltlShipmentData?.pickupNumber ?? ''}
            onChange={(value) => {
              if (props.load.ltlShipmentData !== undefined) {
                const newLtlShipmentData = JSON.parse(
                  JSON.stringify(props.load.ltlShipmentData),
                ) as LTLShipmentDataViewModel;
                newLtlShipmentData.pickupNumber = value as string;
                newLtlShipmentData.operationType = 'Update';
                updateLoadPropertyAtIndex({
                  ltlShipmentData: newLtlShipmentData,
                });
              }
            }}
            disabled={props.load.ltlShipmentData?.shipmentId === undefined}
          />
          <TextInput
            disabled
            type="text"
            variant="outlined"
            size="small"
            label="ShipmentId"
            value={props.load.ltlShipmentData?.shipmentId ?? ''}
          />
          <div class="mb-1 text-[#123B50] text-sm font-medium">
            Reference Numbers
          </div>
          {props.load.ltlShipmentData?.referenceNumbers?.map((ref) => {
            return <div>{ref}</div>;
          })}
        </Show>
        <Show when={props.load.mode !== 'TL'}>
          <TextInput
            type="number"
            label="Freight Value ($)"
            size="small"
            placeholder="Freight Value ($)"
            error={
              orderStore.orderFormError &&
              orderStore.orderFormError[`loads[${props.tabIndex}].freightValue`]
            }
            variant="outlined"
            value={props.load.freightValue}
            onChange={(value) => {
              updateLoadPropertyAtIndex({
                freightValue: value as number,
              });
            }}
          />
        </Show>
        <Show when={props.load.mode === 'TL'}>
          <Box>
            <Box>
              <Typography component={'p'} variant="body2">
                Is the cargo value more than $100,000?
              </Typography>
              <RadioInput
                direction="horizontal"
                label=""
                value={props.load.cargoValueIsHigh}
                options={[
                  { label: 'Yes', value: 'Yes' },
                  { label: 'No', value: 'No' },
                ]}
                onChange={handleRadioChange}
                disabled={orderStore.isReadOnly}
              />
            </Box>
            <Box>
              <NumericInput
                disabled={orderStore.isReadOnly}
                label={
                  props.load.cargoValueIsHigh === 'Yes'
                    ? 'Cargo Value (required)'
                    : 'Cargo Value (optional)'
                }
                error={
                  orderStore.orderFormError &&
                  orderStore.orderFormError[
                    `loads[${props.tabIndex}].freightValue`
                  ]
                }
                value={props.load.freightValue ?? ''}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  if (Number(e.target.value) !== 0) {
                    updateLoadPropertyAtIndex({
                      freightValue: Number(e.target.value),
                    });
                  } else {
                    updateLoadPropertyAtIndex({
                      freightValue: undefined,
                    });
                  }
                }}
                onBlur={() =>
                  validateFieldForOrder(
                    `loads.${props.tabIndex}.freightValue`,
                    `loads[${props.tabIndex}].freightValue`,
                  )
                }
              />
            </Box>
            <Box class="!text-armstrong-sm">
              <span class="text-[#000000] opacity-40">
                Please confirm the contacted carrier has adequate cargo
                insurance. Contact{' '}
              </span>
              <a
                href="mailto:claims@armstrongtransport.com"
                class="text-armstrong-blue cursor-pointer"
              >
                claims@armstrongtransport.com
              </a>{' '}
              <span class="text-[#000000] opacity-40">
                for excess cargo coverage.
              </span>
            </Box>
          </Box>
        </Show>
        <Show
          when={
            (isAdmin() || isSuperAdmin() || canOverrideCarrierHistory()) &&
            props.load.mode == 'TL' &&
            props.load.cargoValueIsHigh === 'Yes'
          }
        >
          <Box>
            <CheckboxInput
              label="Carrier History Override"
              checked={props.load.enableLoadCarrierRestrictionOverride}
              onChange={(val) => {
                updateLoadPropertyAtIndex({
                  enableLoadCarrierRestrictionOverride: val,
                });
              }}
              disabled={orderStore.isReadOnly}
            />
            <Box sx={{ maxWidth: '400px' }}>
              <Typography
                variant="body1"
                component={'div'}
                sxProps={{
                  fontFamily: 'Roboto',
                  fontSize: '12px',
                  fontStyle: 'normal',
                  fontWeight: '400',
                  lineHeight: '166%',
                  letterSpacing: '0.4px',
                }}
              >
                By checking this box, you (`
                {props.load.enableLoadCarrierRestrictionOverrideOffice ??
                  userStore.user.office}
                `) agree to assume 100% liability instead of the typical 70%
                financial responsibility related to the use of this carrier.
                This includes, but is not limited to, costs incurred due to
                damage or loss of goods during transit.
              </Typography>
            </Box>
          </Box>
        </Show>
        <div class="text-base font-bold">
          Net Weight {getTotalWeightAndUnit(props.load)}
        </div>
      </Stack>
    </Box>
  );
};
