import { Button, MultiEmailInput, Typography } from '@components';
import { RadioInput, TextAreaField, TextInput } from '@components/forms';
import { createForm } from '@felte/solid';
import { validator } from '@felte/validator-yup';
import { closeModal } from '@store/modals';
import {
  ICreditCardPayment,
  IOrderViewModel,
  customerStore,
  makeCreditCardPayments,
  orderStore,
} from '@store/orders';
import { userStore } from '@store/user';
import { Box, Grid, Stack } from '@suid/material';
import { formatAmount } from '@utils/utils';
import { PAY_WITH_CREDIT_CARD_ID } from '@views/order/constants';
import { createSignal, onMount } from 'solid-js';
import * as yup from 'yup';

import classes from './classes';
import { payWithCreditCardFormSchema } from './validations';

export const PayWithCreditCardModal = () => {
  const [loading, setLoading] = createSignal<boolean>(false);
  const modalClass = classes.payWithCreditCardModal;

  const computeTotalPayments = () => {
    return orderStore.order.payments.length > 0
      ? orderStore.order.payments
          .filter((v) => v.operationType !== 'Delete')
          .reduce((acc, item) => acc + item.amount, 0)
      : 0;
  };

  const computeTotalLineItems = () => {
    return orderStore.order.lineItems.length > 0
      ? orderStore.order.lineItems
          .filter((v) => v.operationType !== 'Delete')
          .reduce(
            (acc, item) => acc + Number(item.rate) * Number(item.quantity),
            0,
          )
      : 0;
  };

  const totalAmount = () => computeTotalLineItems() - computeTotalPayments();

  const creditCardFee = () => (3 / 100) * totalAmount();

  const [totalPaymentWithFee, setTotalPaymentWithFee] = createSignal<number>(
    totalAmount() + creditCardFee(),
  );

  const getCurrentSelectedCustomerContactId = () => {
    return [
      ...customerStore.customer.billingContacts,
      ...customerStore.customer.freightContacts,
    ].find((i) => i.id === orderStore.order.customerContactId);
  };

  const [allEmails, setAllEmails] = createSignal<string[]>([
    userStore.user.email.replace('"', ''),
  ]);

  const handleAllEmails = (newEmails: string[]) => {
    setAllEmails(newEmails);
    setFields('to', newEmails);
  };

  const getBodyContent = (orderDetails: IOrderViewModel) => {
    return `Armstrong Invoice #${orderDetails.id} \nReference Number #${
      orderDetails.referenceNumber
    }\nAmount Due: ${formatAmount(
      totalPaymentWithFee(),
    )} \n\nThank you for your business!`;
  };

  const { form, data, setFields, errors } = createForm<
    yup.InferType<typeof payWithCreditCardFormSchema>
  >({
    initialValues: {
      to: (getCurrentSelectedCustomerContactId() !== undefined
        ? [getCurrentSelectedCustomerContactId()?.email, ...allEmails()]
        : allEmails()
      )
        .filter((email) => email !== null && email !== undefined)
        .map((email) => email.replace(/"/g, '')),
      subject: `Armstrong Invoice #${orderStore.order.id}`,
      body: getBodyContent(orderStore.order),
      fee: true,
    },
    extend: validator({ schema: payWithCreditCardFormSchema }),
    onSubmit: async (values) => {
      setLoading(true);
      await handleSubmit(values)
        .then(() => {
          closeModal(PAY_WITH_CREDIT_CARD_ID);
        })
        .finally(() => setLoading(false));
    },
  });

  const handleFeeChange = (value: boolean) => {
    setTotalPaymentWithFee(
      value === true ? totalAmount() + creditCardFee() : totalAmount(),
    );
    setFields('body', getBodyContent(orderStore.order));
  };

  const handleSubmit = async (
    values: yup.InferType<typeof payWithCreditCardFormSchema>,
  ) => {
    const payload: ICreditCardPayment = {
      emailSettings: {
        to: values.to as string[],
        subject: values.subject,
        body: values.body,
      },
      fee: values.fee,
    };
    await makeCreditCardPayments(orderStore.order.id, payload);
    closeModal(PAY_WITH_CREDIT_CARD_ID);
  };

  onMount(() => {
    totalAmount();
    creditCardFee();
  });

  return (
    <form ref={form}>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <MultiEmailInput
              id="to"
              label="Email To"
              variant="outlined"
              value={data().to.map((i) => i ?? '')}
              onChange={handleAllEmails}
            />
          </Grid>
          <Grid item xs={12}>
            <TextInput
              variant="outlined"
              label="Subject"
              value={data().subject}
              name="subject"
              onChange={(val) => setFields('subject', val as string)}
              error={errors().subject}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="body1"
              component="p"
              class={modalClass.subheading}
            >
              Credit Card Fee
            </Typography>
          </Grid>
          <Grid item xs={12} md={8}>
            <RadioInput
              label=""
              optionStyles={modalClass.radioOptionStyles}
              direction="vertical"
              options={[
                {
                  label: (
                    <Box displayRaw="flex" flexDirection="column">
                      <span class={modalClass.radioLabelHeading}>
                        Add Credit Card Fee
                      </span>
                      <span class={modalClass.radioLabelHelperText}>
                        This will pass on a {formatAmount(creditCardFee())} fee
                        to the customer
                      </span>
                    </Box>
                  ),
                  value: true,
                },
                {
                  label: (
                    <Box displayRaw="flex" flexDirection="column">
                      <span class={modalClass.radioLabelHeading}>
                        No Credit Card Fee
                      </span>
                      <span class={modalClass.radioLabelHelperText}>
                        This will reduce your profit by{' '}
                        {formatAmount(creditCardFee())}
                      </span>
                    </Box>
                  ),
                  value: false,
                },
              ]}
              value={data().fee}
              onChange={(e) => {
                const value = e.target.value === 'true';
                setFields('fee', value);
                handleFeeChange(value);
              }}
              error={errors().fee}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Stack class={modalClass.priceSectionContainer} spacing={1}>
              <Box textAlign="center" displayRaw="flex" flexDirection="column">
                <span>Invoice Amount:</span>
                <span class={modalClass.amount}>
                  {formatAmount(totalAmount())}
                </span>
              </Box>
              <Box textAlign="center" displayRaw="flex" flexDirection="column">
                <span>Fee:</span>
                <span class={modalClass.amount}>
                  {formatAmount(data().fee === true ? creditCardFee() : 0)}
                </span>
              </Box>
              <Box textAlign="center" displayRaw="flex" flexDirection="column">
                <span>Amount Due:</span>
                <span class={modalClass.amount}>
                  {formatAmount(totalPaymentWithFee())}
                </span>
              </Box>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <TextAreaField
              rows={7}
              label="Body"
              value={data().body}
              error={errors().body}
              onChange={(val) => setFields('body', val)}
            />
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          displayRaw="flex"
          justifyContent="flex-end"
          alignItems="center"
          gap={2}
          pt={3}
        >
          <Button
            label="Done"
            type="submit"
            variant="contained"
            isLoading={loading()}
            disabled={customerStore.loading}
          />
          <Button
            label="Cancel"
            variant="text"
            onClick={() => closeModal(PAY_WITH_CREDIT_CARD_ID)}
          />
        </Grid>
      </Box>
    </form>
  );
};
