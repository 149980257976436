import {
  Button,
  Notification,
  Popover,
  TabsComponent,
  Typography,
} from '@components';
import { useParams, useSearchParams } from '@solidjs/router';
import { closeModal, openModal } from '@store/modals';
import {
  GetCarrierVerificationLockdownValue,
  TabType,
  addNewVendorToOrder,
  carrierStore,
  getCarrierDetail,
  orderStore,
  setActiveCarrierTab,
} from '@store/orders';
import { vendorIntialState } from '@store/orders/defaultVals';
import { Add } from '@suid/icons-material';
import { Grid } from '@suid/material';
import { printLog } from '@utils/utils';
import {
  AddCarrierModalId,
  CarrierLoadingCards,
} from '@views/order/components';
import AddNewCarrier from '@views/order/components/models/AddNewCarrier';
import { JSX, Show, createEffect, createSignal } from 'solid-js';

import ActiveTabContent from './ActiveTabContent';
import { CarrierTab } from './CarrierTab';
import { VendorTab } from './VendorTab';
import classes from './classes';

type CarrierProps = {
  lgBreakpoint: number;
};

export const Carrier = (props: CarrierProps) => {
  const [carrierTabs, setCarrierTabs] = createSignal<JSX.Element[]>([]);
  const [vendorTabs, setVendorTabs] = createSignal<JSX.Element[]>([]);
  const [tabIndex, setTabIndex] = createSignal(0);
  const params = useParams();
  const [query] = useSearchParams();

  const isCreate = !params.id;
  createEffect(async () => {
    printLog('params', query);
    await GetCarrierVerificationLockdownValue();
    if (
      orderStore.isCreate &&
      !Boolean(query.quoteId) &&
      !Boolean(query.copyOrderId)
    ) {
      openModal(AddCarrierModalId);
    }
  });
  createEffect(() => {
    if (orderStore.order.loads.length === 0) setCarrierTabs([]);
    if (orderStore.order.loads.length > 0) {
      setCarrierTabs(
        orderStore.order.loads.map((item, index) =>
          CarrierTab(index, item, setTabIndex, orderStore.isReadOnly),
        ),
      );
    }
    if (orderStore.order.vendors) {
      if (orderStore.order.vendors.length === 0) setVendorTabs([]);
      if (orderStore.order.vendors.length > 0)
        setVendorTabs(
          orderStore.order.vendors.map((item, index) =>
            VendorTab(index, item, setTabIndex),
          ),
        );
    }
  });
  createEffect(() => {
    //Note: add this to set the active tab when the page is refreshed
    //eslint-disable-next-line
    if (orderStore.activeTab) {
      const { type, index } = orderStore.activeTab;
      if (type === 'load') {
        setTabIndex(index);
      } else {
        setTabIndex(index + carrierTabs().length);
      }
    }
  });
  const closeAddNewCarrier = () => {
    closeModal(AddCarrierModalId);
  };

  const handleNewCarrierTab = () => {
    setCarrierTabs([
      ...carrierTabs(),
      CarrierTab(orderStore.order.loads.length),
    ]);
    setActiveCarrierTab({
      index: orderStore.order.loads.length,
      type: TabType.load,
    });
    setTabIndex(orderStore.order.loads.length);
  };

  const handleNewVendorTab = () => {
    addNewVendorToOrder({ ...vendorIntialState });
    const index =
      carrierTabs().length + vendorTabs().length > 0
        ? carrierTabs().length + vendorTabs().length - 1
        : 0;
    setActiveCarrierTab({
      index: vendorTabs().length > 0 ? vendorTabs().length - 1 : 0,
      type: TabType.vendor,
    });
    setTabIndex(index);
  };

  const handleTabClick = async (index: number) => {
    if (index < carrierTabs().length) {
      setActiveCarrierTab({
        index: index,
        type: TabType.load,
      });
      const carrierId = orderStore.order.loads[index].carrierId;
      if (carrierId !== 0 && carrierId != null) {
        if (typeof carrierStore.carrier[index] === 'undefined') {
          await getCarrierDetail(carrierId, index);
        }
      }
      setTabIndex(index);
      // setLTLQuote(orderStore.order.loads[props.tabIndex].ltlQuote);
    } else {
      setActiveCarrierTab({
        index: index - carrierTabs().length,
        type: TabType.vendor,
      });
      setTabIndex(index);
    }
  };

  return (
    <Grid item md={12} lg={props.lgBreakpoint} width={'100%'}>
      <TabsComponent
        id="order-load-tabs"
        activeTab={tabIndex()}
        onTabClick={handleTabClick}
        tabs={[...carrierTabs(), ...vendorTabs()]}
        tabStyleClasses="rounded-t-lg"
        tabContainerStyles="ml-4 items-center"
        activeStyling="bg-[#ffe8b7]"
        border={true}
        noPadding={true}
        showChevrons={true}
        loading={orderStore.loading}
        actionButton={
          <Show when={!orderStore.loading}>
            <Popover
              eleName={
                <Add class="cursor-pointer rounded-full border-2 border-[#468DB5] text-[#468DB5]" />
              }
            >
              {(closePopover) => (
                <div>
                  <Button
                    class={classes.carrierOptions}
                    size="large"
                    onClick={() => {
                      openModal(AddCarrierModalId);
                      closePopover();
                    }}
                    label="Carrier"
                    variant="text"
                    disabled={orderStore.isReadOnly}
                  />
                  <br />
                  <Button
                    class={classes.carrierOptions}
                    onClick={() => {
                      handleNewVendorTab();
                      closePopover();
                    }}
                    label="Vendor"
                    size="large"
                    variant="text"
                    disabled={orderStore.isReadOnly}
                  />
                </div>
              )}
            </Popover>
          </Show>
        }
      />
      <div class="border-t border-gray-300 mb-2" />
      <Show
        when={
          orderStore.orderFormError &&
          orderStore.orderFormError[
            `loads[${orderStore.activeTab.index}].stopsCustomError`
          ] &&
          orderStore.activeTab.type === TabType.load
        }
      >
        {orderStore.orderFormError &&
          orderStore.orderFormError[
            `loads[${orderStore.activeTab.index}].stopsCustomError`
          ]?.map((error) => <Notification type="error" text={error} />)}
      </Show>

      <Show when={!orderStore.loading} fallback={<CarrierLoadingCards />}>
        <div class="tab">
          <ActiveTabContent />
        </div>
      </Show>

      <AddNewCarrier
        onClose={closeAddNewCarrier}
        onSubmit={handleNewCarrierTab}
      />

      <Show
        when={
          isCreate && carrierTabs().length === 0 && vendorTabs().length === 0
        }
      >
        <Typography variant="body1" component="p" class="text-center mt-2">
          Start by adding a{' '}
          <Button
            label="Carrier"
            variant="text"
            onClick={() => openModal(AddCarrierModalId)}
          />{' '}
        </Typography>
      </Show>
    </Grid>
  );
};
