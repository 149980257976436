import { LTLOptionsArray } from '@common/commonLists';
import { LTLRateQuoteResponse } from '@store/ltl';
import { LineItemViewModel } from '@store/orders/v3Types';
import { Box, Grid } from '@suid/material';
import { Typography } from '@components';
import { formatAmount } from '@utils/utils';
import { StackCard } from '@views/order/components/header/StackCard';
import { createSignal, Show } from 'solid-js';
import { FalveyInsuranceQuoteInLoad } from '@store/ltl/types';
export const CostDetailCard = (props: {
  data: LTLRateQuoteResponse;
  withInsurance?: boolean;
  isCarrier?: boolean;
  ltlInsuranceVals?: FalveyInsuranceQuoteInLoad;
}) => {
  const [accessorialsTotal, setAccessorialsTotals] = createSignal<number>();
  const getPositiveCharges = (lineItems: LineItemViewModel[]) => {
    return lineItems.filter((quote) => {
      return (
        (quote.rate as number) >= 0 &&
        !LTLOptionsArray.includes(quote.type as string)
      );
    });
  };

  const getNegativeCharges = (lineItems: LineItemViewModel[]) => {
    return lineItems.filter((quote) => {
      return (
        (quote.rate as number) < 0 &&
        !LTLOptionsArray.includes(quote.type as string)
      );
    });
  };
  const getAccessorialCharges = (lineItems: LineItemViewModel[]) => {
    let currrentTotal = 0;
    const accessorial = lineItems.map((quote) => {
      if (
        LTLOptionsArray.includes(quote.type as string) &&
        quote.rate !== undefined
      ) {
        currrentTotal += quote.rate;
        return quote;
      }
    });
    setAccessorialsTotals(currrentTotal);
    return accessorial;
  };

  const calculateTotal = () => {
    if (!Boolean(props.withInsurance)) {
      return props.data.totalCost;
    }

    const { quoteServiceFee, quotePremium } = props.ltlInsuranceVals ?? {};
    const insurancePremium = quotePremium ?? 0;
    const serviceFee = Boolean(props.isCarrier) ? 0 : quoteServiceFee ?? 0;

    return props.data.totalCost + insurancePremium + serviceFee;
  };

  return (
    <Box class="p-4 overflow-hidden">
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <StackCard title="Accessorials" content="" titleFont="0.875rem" />
          {getAccessorialCharges(props.data.lineItems).map((quote) => {
            if (quote) {
              return (
                <Typography variant="body1">{quote.type as string}</Typography>
              );
            }
            return null;
          })}
          <Grid item xs={12} class="flex justify-between">
            <Typography
              variant="body1"
              sxProps={{ whiteSpace: 'nowrap', fontWeight: '500' }}
            >
              Accessorials Total:
            </Typography>
            <Typography variant="body1">
              {formatAmount(accessorialsTotal())}
            </Typography>
          </Grid>
        </Grid>

        {getPositiveCharges(props.data.lineItems).map((quote) => {
          return (
            <Grid item xs={12}>
              <StackCard
                titleFont="0.875rem"
                contentFont="1rem"
                contentWeight="400"
                title={quote.type as string}
                content={formatAmount(quote.rate)}
              />
            </Grid>
          );
        })}
        {getNegativeCharges(props.data.lineItems).map((quote) => {
          return (
            <Grid xs={12}>
              <StackCard
                titleFont="0.875rem"
                contentFont="1rem"
                contentWeight="400"
                title={quote.type as string}
                content={formatAmount(quote.rate)}
              />
            </Grid>
          );
        })}

        <Show
          when={
            Boolean(props.isCarrier) &&
            Boolean(props.withInsurance) &&
            props.ltlInsuranceVals?.quotePremium
          }
        >
          <Grid item xs={12}>
            <StackCard
              title="Insurance"
              content={formatAmount(props.ltlInsuranceVals?.quotePremium)}
              titleFont="0.875rem"
              contentFont="1rem"
              contentWeight="500"
            />
          </Grid>
        </Show>
        <Show when={!Boolean(props.isCarrier) && Boolean(props.withInsurance)}>
          <Grid item xs={12}>
            <StackCard
              title="Insurance & Fee"
              content={formatAmount(
                (props.ltlInsuranceVals?.quotePremium ?? 0) +
                  (props.ltlInsuranceVals?.quoteServiceFee ?? 0),
              )}
              titleFont="0.875rem"
              contentFont="1rem"
              contentWeight="500"
            />
          </Grid>
        </Show>
        <Grid item>
          <StackCard
            title="Total"
            content={formatAmount(calculateTotal())}
            titleFont="0.875rem"
            contentFont="1rem"
            contentWeight="500"
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default CostDetailCard;
