import { Card, Notification } from '@components';
import Tooltip from '@components/Tooltip';
import { customerUrl } from '@constants/urls';
import { Link } from '@solidjs/router';
import { collectionDetailsStore } from '@store/collectionsDashboard';
import { Info, OpenInNew } from '@suid/icons-material';
import { Box, Divider, Grid, Typography } from '@suid/material';
import React from 'react';
import { For, Show } from 'solid-js';

const CustomerBasicDetails = () => {
  const contactBilling = () => {
    const contacts = [
      ...collectionDetailsStore.collectionDetails.customer.contacts,
    ];
    return contacts.filter((contact) => contact.type === 'Billing');
  };

  return (
    <Card
      startTitle={
        <Typography class="!w-[300px] break-all">
          {collectionDetailsStore.collectionDetails.customer.name}
        </Typography>
      }
      accordion={false}
      loading={collectionDetailsStore.isCollectionLoading}
      endIcon={
        <a
          href={`/Customer/Details/${collectionDetailsStore.collectionDetails.customer.id}`}
        >
          <Tooltip
            placement="left"
            text="Navigate to the Customer Details page"
          >
            <Info class="text-[24px] cursor-pointer m-2" />
          </Tooltip>
        </a>
      }
    >
      <Grid container spacing={4}>
        <Show
          when={
            !Boolean(collectionDetailsStore.collectionDetails.customer.verified)
          }
        >
          <Grid item md={12}>
            <Notification
              type="warning"
              text="This Customer's Billing Information Has not been verified."
            />
          </Grid>
        </Show>
        <Grid item md={7}>
          <Show
            when={
              collectionDetailsStore.collectionDetails.customer
                .billingInstructions
            }
          >
            <Grid item>
              <Typography class="!font-medium underline">
                Billing Instructions:
              </Typography>
              <p>
                {
                  collectionDetailsStore.collectionDetails.customer
                    .billingInstructions
                }
              </p>
            </Grid>
          </Show>
          <Grid item>
            <Typography class="!font-medium underline">
              Billing Contacts
            </Typography>
            <For each={contactBilling()}>
              {(contact) => (
                <Box class="w-fit">
                  <Typography>
                    <span class="!font-medium">{contact.name}</span> -{' '}
                    {contact.type}
                  </Typography>{' '}
                  <Show when={contact.phone1}>
                    {contact.phone1} <span>' Ext.'{contact.extension1}</span>
                  </Show>
                  <p>{contact.email}</p>
                  <Divider />
                </Box>
              )}
            </For>
          </Grid>
        </Grid>
        <Grid item md={4.5}>
          <Grid item>
            <Show
              when={Boolean(
                collectionDetailsStore.collectionDetails.customer
                  .creditInsuranceCoverageTypeName,
              )}
            >
              <Typography>
                Insurance:{' '}
                {
                  collectionDetailsStore.collectionDetails.customer
                    .creditInsuranceCoverageTypeName
                }
              </Typography>
            </Show>
            <Typography>
              Status: {collectionDetailsStore.collectionDetails.customer.status}
            </Typography>
            <Typography>
              Terms:{' '}
              {collectionDetailsStore.collectionDetails.customer.paymentTerms}
            </Typography>
            <Typography>
              Invoice Method:{' '}
              {collectionDetailsStore.collectionDetails.customer.invoiceMethod}
            </Typography>
          </Grid>
          <Grid item>
            <Typography class="!font-medium !text-lg mt-[10px]">
              Reports
            </Typography>
            <Box class="text-[#026EA1] text-sm">
              <Link
                href={customerUrl.customerPastDueInvoices(
                  collectionDetailsStore.collectionDetails.customer.id,
                )}
                target="_blank"
              >
                <>
                  Past Due Invoices <OpenInNew />
                </>
              </Link>
            </Box>
            <Box class="text-[#026EA1] text-sm">
              <Link
                href={customerUrl.customerOpenInvoices(
                  collectionDetailsStore.collectionDetails.customer.id,
                )}
                target="_blank"
              >
                <>
                  Outstanding Invoices <OpenInNew />
                </>
              </Link>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
};

export default CustomerBasicDetails;
