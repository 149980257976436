import { Checkbox } from '@suid/material';
import { ICellRendererParams } from 'ag-grid-community';
import { ILoadBoardViewModel } from '@store/loadboard';
import { Show } from 'solid-js';
import { toggleRateCon } from '@store/orders';

const RateConCellRenderer = (
  data: ICellRendererParams<ILoadBoardViewModel>,
) => {
  return (
    <Show when={data.data} fallback={<></>}>
      <div>
        <Checkbox
          checked={Boolean(data.data?.confirmationSigned)}
          onChange={() => {
            let recieved: boolean;
            if (Boolean(data.data?.confirmationSigned)) {
              recieved = false;
            } else {
              recieved = true;
            }
            void toggleRateCon(data.data?.loadId as number, recieved);
          }}
        ></Checkbox>
      </div>
    </Show>
  );
};

export default RateConCellRenderer;
