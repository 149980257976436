import {
  BasicModal,
  BasicTable,
  Card,
  DatePicker,
  MenuItemType,
  Notification,
  ToastType,
  Typography,
} from '@components';
import { Column } from '@components/BasicTable/BasicTable';
import { PopoverCell } from '@components/Grid/components';
import { closeModal, openModal } from '@store/modals';
import {
  GeocodeModel,
  ILoadEditViewModel,
  LastUsedTopStopModel,
  fetchStopsForStopsModal,
  getGoogleGeocode,
  orderStore,
  setOrderFirstRender,
  setOrderStore,
  updateLoadPropertyAtIndex,
} from '@store/orders';
import {
  ArrowCircleDown,
  ArrowCircleUp,
  Edit,
  LocationOn,
} from '@suid/icons-material';
import AddCircleOutlineOutlinedIcon from '@suid/icons-material/AddCircleOutlineOutlined';
import DeleteIcon from '@suid/icons-material/Delete';
import { Box, Stack } from '@suid/material';
import { formatPhoneNumber } from '@utils/phoneNumberFormat';
import { handleToast, printError } from '@utils/utils';
import cloneDeep from 'lodash/cloneDeep';
import { Index, Show, createEffect, createMemo, createSignal } from 'solid-js';
import { produce } from 'solid-js/store';

import { LtlQuotingModal } from '../../ltlQuotingModal';
import StopStreetViewMap from './StopStreetViewMap/StopStreetViewMap';
import StopsMileage from './StopsMileage';
import StopsTracking from './StopsTracking';
import { ShipmentChangesModal } from './components';
import StopEditor from './stopsEditor/StopEditor';

const iconStyles = {
  color: '#0070A2',
  cursor: 'pointer',
};

type Props = {
  load: ILoadEditViewModel;
  tabIndex: number;
};
export const Stops = (props: Props) => {
  const [lat, setLat] = createSignal<number>();
  const [lng, setLng] = createSignal<number>();
  const [activeStop, setActiveStop] = createSignal<LastUsedTopStopModel | null>(
    null,
  );
  const [activeView, setActiveView] = createSignal('stops-main');

  const modalId = 'stops-modal';
  const modalMapId = `map-modal-${props.tabIndex}`;
  const shipmentChangesModalId = 'shipmentChangesModal';

  const deleteRow = (rowId: number) => {
    const stops = cloneDeep([
      ...(orderStore.order.loads[props.tabIndex].stops ?? []),
    ]);
    const stopIndex = stops.findIndex((stop) => stop.id === rowId);
    let updatedItems = cloneDeep([
      ...(orderStore.order.loads[props.tabIndex].items ?? []),
    ]);
    updatedItems = updatedItems
      .map((itm) => {
        if (
          itm.pickUpId === stops[stopIndex].id ||
          itm.dropOffId === stops[stopIndex].id
        ) {
          if (itm.operationType !== 'Insert') {
            itm.operationType = 'Delete';
          } else {
            return null;
          }
        }
        return itm;
      })
      .filter((itm) => itm !== null);
    if (stops[stopIndex].operationType == 'Insert') {
      stops.splice(stopIndex, 1);
    } else {
      stops[stopIndex] = {
        ...stops[stopIndex],
        operationType: 'Delete',
      };
    }
    updateLoadPropertyAtIndex({ stops: stops, items: updatedItems });
  };

  const handleDateChange = (value: string, index: number) => {
    setOrderStore(
      'order',
      'loads',
      orderStore.activeTab.index,
      'stops',
      index,
      produce((stop) => {
        stop.operationType =
          stop.operationType === 'None' ? 'Update' : stop.operationType;
        stop.stopDateTime = value;
      }),
    );
  };

  const getGeoCoordinates = async (stop: LastUsedTopStopModel) => {
    const geocode: GeocodeModel = {
      address: stop.address1 as string,
      city: stop.city as string,
      state: stop.state as string,
    };
    const response = await getGoogleGeocode(geocode);
    setLat(response.lat);
    setLng(response.lng);
  };

  const showTotalDistance = (rowData: LastUsedTopStopModel) => {
    if (
      Boolean(
        orderStore.order.loads[orderStore.activeTab.index].practicalMileage,
      ) &&
      (orderStore.order.loads[orderStore.activeTab.index]?.stops?.length ?? 0) >
        1 &&
      rowData.stopOrder ===
        (orderStore.order.loads[orderStore.activeTab.index]?.stops?.length ??
          0) -
          1
    ) {
      return true;
    }
    return false;
  };
  const showDistance = (rowData: LastUsedTopStopModel) => {
    if (rowData.stopOrder !== undefined && rowData.stopOrder !== null) {
      if (
        Boolean(rowData.practicalMileageToNextStop) &&
        (orderStore.order.loads[orderStore.activeTab.index]?.stops?.length ??
          0) > 2 &&
        rowData.stopOrder <
          (orderStore.order.loads[orderStore.activeTab.index]?.stops?.length ??
            0) -
            1
      ) {
        return true;
      }
    }
    return false;
  };

  const columns: Column<LastUsedTopStopModel>[] = [
    {
      name: 'Date',
      key: 'date',
      renderCell: (rowData, index) => (
        <Box class="flex flex-col relative top-[8px] high-res:flex-row high-res:items-center gap-1">
          <span class="mr-1">
            {rowData.pickUp && <ArrowCircleUp sx={iconStyles} />}
            {rowData.dropOff && <ArrowCircleDown sx={iconStyles} />}
          </span>
          <DatePicker
            fullWidth={false}
            size="small"
            sx={{ maxWidth: '140px' }}
            name="stopDateTime"
            value={rowData.stopDateTime as string}
            handleChange={(value: string) => handleDateChange(value, index)}
            disabled={orderStore.isReadOnly}
          />
          <div class="high-res:hidden text-sm">{rowData.time}</div>
        </Box>
      ),
    },
    {
      name: 'Address',
      key: 'location',
      renderCell: (rowData) => {
        return (
          <Stack>
            <div class="font-semibold text-sm">{rowData.locationName}</div>
            <div class="text-sm">{rowData.address1}</div>
            <div class="text-sm">{rowData.address2}</div>
            <div class="text-sm">
              {rowData.city}, {rowData.state} {rowData.zip}
            </div>
            <div class="text-sm">
              {rowData.contact}
              <br />
              {formatPhoneNumber(rowData.phone ?? '')}
            </div>
            {showTotalDistance(rowData) && (
              <div class=" high-res:flex">
                <div class="text-sm font-semibold whitespace-nowrap">
                  Total Distance: &nbsp;
                </div>
                <div class="text-sm whitespace-nowrap">
                  {` ${
                    orderStore.order.loads[orderStore.activeTab.index]
                      .practicalMileage
                  } Miles (P)`}
                </div>
              </div>
            )}
            {showDistance(rowData) && (
              <Box sx={{ display: 'flex' }}>
                <Typography
                  component="p"
                  sxProps={{ fontWeight: 'bold' }}
                  variant="inherit"
                >
                  Distance: &nbsp;
                </Typography>
                <Typography
                  variant="inherit"
                  sxProps={{ whiteSpace: 'nowrap' }}
                >
                  {` ${rowData.practicalMileageToNextStop?.toFixed()} Miles (P)`}
                </Typography>
              </Box>
            )}
          </Stack>
        );
      },
    },
    {
      name: 'Map',
      key: 'map',
      renderCell: (rowData) => (
        <LocationOn
          sx={iconStyles}
          onClick={async () => {
            await getGeoCoordinates(rowData);
            openModal(modalMapId);
          }}
        />
      ),
    },
    {
      name: 'Time',
      key: 'time',
      minWidth: 120,
      headerClasses: '!hidden high-res:!table-cell',
      renderCell: (rowData) => {
        return <div class="text-sm">{rowData.time ?? ''}</div>;
      },
    },
    {
      name: 'Pick/Drop #',
      key: 'pickDrop',
      renderCell: (rowData) => (
        <PopoverCell
          id={rowData.id}
          value={rowData.referenceNum}
          width={'100px'}
        />
      ),
    },
    {
      name: 'Items',
      key: 'items',
      minWidth: 130,
      renderCell: (rowData) => {
        return (
          <Index
            each={orderStore.order.loads[orderStore.activeTab.index].items}
            fallback={<></>}
          >
            {(itm) => {
              return (
                <Show
                  when={
                    itm().operationType !== 'Delete' &&
                    (itm().pickUpId == rowData.id ||
                      itm().dropOffId == rowData.id)
                  }
                  fallback={<></>}
                >
                  <div class="text-sm">{`${itm().quantity} ${itm().item} (${
                    itm().description
                  })`}</div>
                </Show>
              );
            }}
          </Index>
        );
      },
    },
    {
      name: 'Stop Instructions',
      key: 'instructions',
      minWidth: 130,
      renderCell: (rowData) => (
        <div class="text-sm">
          <Show
            when={
              Boolean(rowData.description) &&
              (rowData.description?.length ?? 0) > 0
            }
          >
            <Box sx={{ overflowWrap: 'break-word', whiteSpace: 'pre-wrap' }}>
              {rowData.description}
            </Box>
          </Show>
          <Show
            when={
              Boolean(rowData.instructions) &&
              (rowData.instructions?.length ?? 0) > 0
            }
          >
            <Box sx={{ overflowWrap: 'break-word', whiteSpace: 'pre-wrap' }}>
              {rowData.instructions}
            </Box>
          </Show>
        </div>
      ),
    },
    {
      name: 'Actions',
      key: '#',
      renderCell: (rowData) => (
        <Stack class="flex" direction="row">
          <Box class="cursor-pointer">
            <Edit
              onClick={() => {
                if (orderStore.isReadOnly) {
                  return;
                }

                setActiveStop(rowData);
                openModal(modalId);
                if (orderStore.firstRender && props.load.mode === 'LTL') {
                  openModal(shipmentChangesModalId);
                  setOrderFirstRender(false);
                }
              }}
              sx={{
                ...iconStyles,
                fontSize: '1.5rem',
                opacity: orderStore.isReadOnly ? '0.5' : undefined,
                cursor: orderStore.isReadOnly ? 'default' : undefined,
              }}
            />
          </Box>
          <Box class="cursor-pointer">
            <DeleteIcon
              onClick={() => {
                if (orderStore.isReadOnly) {
                  return;
                }

                deleteRow(rowData.id);
              }}
              sx={{
                ...iconStyles,
                fontSize: '1.5rem',
                color: '#B00020',
                opacity: orderStore.isReadOnly ? '0.5' : undefined,
                cursor: orderStore.isReadOnly ? 'default' : undefined,
                marginLeft: '2px',
              }}
            />
          </Box>
        </Stack>
      ),
    },
  ];

  const footerRow = [
    {
      renderCell: () => (
        <AddCircleOutlineOutlinedIcon
          onClick={() => {
            if (orderStore.isReadOnly) {
              return;
            }

            setActiveStop(null);
            openModal(modalId);
          }}
          sx={{
            color: '#0070A2',
            opacity: orderStore.isReadOnly ? '0.5' : undefined,
            cursor: orderStore.isReadOnly ? 'default' : 'pointer',
          }}
        />
      ),
      key: 'ideaTitle',
    },
    {
      key: '#',
    },
    {
      key: '#',
    },
    {
      key: '#',
    },
    {
      key: '#',
    },
    {
      key: '#',
    },
    {
      key: '#',
    },
    {
      key: '#',
    },
    {
      key: '#',
    },
  ];

  const prepareStopsData = createMemo(() => {
    let hasDropOffStop = false;
    const dropOffOptions: MenuItemType[] = [];
    const pickUpOptions: MenuItemType[] = [];
    const stopIds: { id: number; locationName: string }[] = [];
    let smallestId = 0;
    (orderStore.order.loads[orderStore.activeTab.index]?.stops ?? []).forEach(
      (stop) => {
        if (stop.operationType === 'Delete') return; // NOTE: this is to remove the deleted stops from the selction
        stopIds.push({
          id: stop.id,
          locationName: stop.locationName ?? '',
        });
        if (stop.pickUp) {
          pickUpOptions.push({
            label: stop.locationName ?? '',
            value: stop.id.toString(),
          });
        } else {
          hasDropOffStop = true;
          dropOffOptions.push({
            label: stop.locationName ?? '',
            value: stop.id.toString(),
          });
        }
        if (stop.id < smallestId) {
          smallestId = stop.id;
        }
      },
    );
    let currentNewLoadItemId = 0;
    orderStore.order.loads[orderStore.activeTab.index].items?.forEach((itm) => {
      if (itm.id < currentNewLoadItemId) {
        currentNewLoadItemId = itm.id;
      }
    });
    return {
      hasDropOffStop,
      dropOffOptions,
      pickUpOptions,
      stopIds,
      mode: orderStore.order.loads[orderStore.activeTab.index]?.mode,
      currentNewStopId: smallestId < 0 ? smallestId - 1 : -1,
      currentNewLoadItemId:
        currentNewLoadItemId < 0 ? currentNewLoadItemId - 1 : -1,
    };
  });

  createEffect(() => {
    void (async () => {
      if (Boolean(orderStore.order.customerId)) {
        try {
          await fetchStopsForStopsModal();
        } catch (error) {
          printError(error);
          handleToast(ToastType.Error, (error as Error).message);
        }
      }
    })();
  });

  return (
    <Card
      startTitle="Stops"
      startTitleAction={<StopsTracking />}
      loading={orderStore.loading}
      contentClass="!px-1"
    >
      <Show
        when={
          orderStore.isStopsEdited &&
          orderStore.order.loads[orderStore.activeTab.index].mode === 'LTL'
        }
      >
        <Notification
          sxProps="!mb-6 !mt-0"
          type="warning"
          text={
            <Typography variant="body1" component="h4">
              If you made recent changes, we recommend{' '}
              <span
                class="text-[#0070A2] font-medium hover:underline cursor-pointer"
                onClick={() => {
                  openModal('ltl-quoting-modal');
                }}
              >
                requoting
              </span>{' '}
              this shipment for most accurate pricing.
            </Typography>
          }
        />
      </Show>
      <LtlQuotingModal />
      <StopsMileage
        tabIndex={props.tabIndex}
        disabled={orderStore.isReadOnly}
      />
      <BasicTable<LastUsedTopStopModel>
        columns={columns}
        columnNoWrap={true}
        rows={props.load.stops ?? []}
        footerRow={footerRow}
        footerBorder={false}
        dragDropEnable={!orderStore.isReadOnly}
        onDragEnd={(updatedItems) => {
          const updatedStops = updatedItems.map((item, index: number) => ({
            ...item,
            stopOrder: index,
            operationType:
              item.operationType === 'None' ? 'Update' : item.operationType,
          }));
          setOrderStore(
            'order',
            'loads',
            props.tabIndex,
            'stops',
            updatedStops,
          );
        }}
        cellClasses="!px-[1px] !py-[1px] !text-sm !align-baseline"
      />
      <BasicModal
        id={modalId}
        width="100%"
        modalStyles={{ maxWidth: '90vw', background: 'white' }}
        header={false}
        footer={false}
        onClose={() => {
          if (
            activeView() === 'ltl-product-catalog' ||
            activeView() === 'select-from-addresses'
          ) {
            setActiveView('stops-main');
          } else {
            setActiveStop(null);
            closeModal(modalId);
          }
        }}
        mainContainerStyles={{ width: '90vw' }}
        title={'Create a Stop'}
      >
        <StopEditor
          stop={activeStop()}
          hasDropOffStop={prepareStopsData().hasDropOffStop}
          dropOffOptions={prepareStopsData().dropOffOptions}
          pickUpOptions={prepareStopsData().pickUpOptions}
          stopIds={prepareStopsData().stopIds}
          currentNewStopId={prepareStopsData().currentNewStopId}
          currentNewLoadItemId={prepareStopsData().currentNewLoadItemId}
          mode={prepareStopsData().mode}
          setActiveView={setActiveView}
          activeView={activeView}
        />
      </BasicModal>
      <StopStreetViewMap lat={lat()} lng={lng()} modalId={modalMapId} />
      <BasicModal
        id={shipmentChangesModalId}
        footer={false}
        header
        width="700px"
        title="Shipment Details Have Changed"
        hideBackdrop
      >
        <ShipmentChangesModal modalId="shipmentChangesModal" />
      </BasicModal>
    </Card>
  );
};
