﻿import { apiClient, v3Client } from '@api/apiClient';

export const DownloadCSVFileV3 = async (apiUrl: string, fileName: string) => {
  const response = await v3Client.get<string>(apiUrl);
  const a = document.createElement('a');
  document.body.appendChild(a);
  const url = window.URL.createObjectURL(
    new Blob([response as unknown as string]),
  );
  a.href = url;
  a.download = fileName;
  a.click();
  setTimeout(() => {
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  }, 0);
};

export const DownloadCSVFileV4 = async (apiUrl: string, fileName: string) => {
  const t = await apiClient.get<string>(apiUrl);
  const a = document.createElement('a');
  document.body.appendChild(a);
  const url = window.URL.createObjectURL(new Blob([t as unknown as string]));
  a.href = url;
  a.download = fileName;
  a.click();
  setTimeout(() => {
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  }, 0);
};
