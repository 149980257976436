import { Tab, TabsComponent, TransitWarningGrid } from '@components';
import { ILoadBoardViewModel } from '@store/loadboard/types';
import Close from '@suid/icons-material/Close';
import { Warning } from '@suid/icons-material';
import { Grid } from '@suid/material';
import { RowClassParams } from 'ag-grid-community';
import { Match, Show, Switch, createSignal } from 'solid-js';

type PropsT = {
  oldPicks: ILoadBoardViewModel[];
  oldDrops: ILoadBoardViewModel[];
  getRowStyle?: (params: RowClassParams) => { background?: string };
  onUpdateItems: (newItem: string, col: string, loadId: number) => void;
  onRowClicked?: ((e: unknown, col?: string | undefined) => void) | undefined;
  onRefreshGrid?: () => void;
};
export const TransitWarning = (props: PropsT) => {
  const [activeTab, setActiveTab] = createSignal(0);
  const [isExpanded, setIsExpanded] = createSignal(0);
  const [showTransitWarning, setShowTransitWarning] = createSignal(true);

  const onToggle = () => {
    setIsExpanded(isExpanded() === 0 ? 1 : 0);
  };

  return (
    <Show when={showTransitWarning()}>
      <Grid
        class={
          'border-[#F59D25] flex items-center px-4 py-2 rounded-md my-2 border-2 rounded-5 shadow-md'
        }
        sx={{
          boxShadow: '0px 8px 5px rgba(0, 0, 0, 0.16)',
          marginLeft: '0.2em',
          marginRight: '0.2em',
        }}
      >
        <div style={{ width: '-webkit-fill-available' }}>
          <div
            onClick={onToggle}
            style={{
              display: 'flex',
              'justify-content': 'center',
              'align-items': 'center',
              margin: '0',
              cursor: 'pointer',
            }}
          >
            <Warning style={{ 'font-size': '24px', color: '#F59D25' }} />
            <span
              style={{
                'font-size': '20px',
                'user-select': 'none',
              }}
            >
              You have Transit Warnings for some of your shipments
            </span>
          </div>
          {isExpanded() === 1 && (
            <div>
              <TabsComponent
                tabs={[
                  `Pick Up [${props.oldPicks.length}]`,
                  `Drop Off [${props.oldDrops.length}]`,
                ].map((item) => (
                  <Tab>{item}</Tab>
                ))}
                activeTab={activeTab()}
                onTabClick={setActiveTab}
              />
              <Switch>
                <Match when={activeTab() === 0}>
                  <TransitWarningGrid
                    items={
                      props.oldPicks as unknown as Record<string, unknown>[]
                    }
                    onUpdateItems={props.onUpdateItems}
                    getRowStyle={props.getRowStyle}
                  />
                </Match>
                <Match when={activeTab() === 1}>
                  <TransitWarningGrid
                    items={
                      props.oldDrops as unknown as Record<string, unknown>[]
                    }
                    onUpdateItems={props.onUpdateItems}
                    getRowStyle={props.getRowStyle}
                  />
                </Match>
              </Switch>
            </div>
          )}
        </div>
        <Show when={isExpanded() === 0}>
          <Close
            sx={{
              cursor: 'pointer',
              fontSize: '20px',
            }}
            onClick={() => setShowTransitWarning(false)}
          />
        </Show>
      </Grid>
    </Show>
  );
};

export default TransitWarning;
