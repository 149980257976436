import { lazy } from 'solid-js';

const Carrier = lazy(() => import('./Carrier'));
const CarrierDetails = lazy(() => import('./carrierDetails'));
import { CarrierLaneSearchRoute } from './laneSearch';
import { CarrierPlaylists } from './playlists';

const CarrierRoute = {
  path: '/carrier',
  component: Carrier,
};

const CarrierDetailsRoute = {
  path: '/carrier/details/:id',
  component: CarrierDetails,
};

const NewCarrierDetailsRoute = {
  path: '/carrier/details',
  component: CarrierDetails,
};

export {
  CarrierLaneSearchRoute,
  CarrierRoute,
  CarrierPlaylists,
  CarrierDetailsRoute,
  NewCarrierDetailsRoute,
};
