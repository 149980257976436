import {
  MenuItemType,
  Select,
  gridStateWithAdditionalProps,
} from '@components';
import { TextInput } from '@components/forms';
import { getEffectiveIsEnabledForUserByFeatureName } from '@store/Global/service';
import {
  FilterPassT,
  ILoadBoardViewModel,
  SavedSearchCategory,
  appendNewSavedSearch,
  columnDefsMap,
  loadBoardStore,
  saveLoadboardCache,
} from '@store/loadboard';
import { saveFilter } from '@store/loadboard/services';
import { userStore } from '@store/user';
import {
  Download as DownloadIcon,
  Publish as PublishIcon,
} from '@suid/icons-material';
import CloseIcon from '@suid/icons-material/Close';
import FilterListIcon from '@suid/icons-material/FilterList';
import RefreshIcon from '@suid/icons-material/Refresh';
import SaveIcon from '@suid/icons-material/Save';
import {
  Checkbox,
  CircularProgress,
  FormControlLabel,
  IconButton,
  Grid as MuiGrid,
  Stack,
} from '@suid/material';
import { SelectChangeEvent } from '@suid/material/Select';
import {
  doesExternalFilterFactory,
  findMenuItemByValue,
  getSortModelFromColumnState,
  isExternalFilterPresent,
  printLog,
} from '@utils/utils';
import {
  // AdvancedFilterModel,
  ColDef,
  GridApi,
  GridOptions,
  IRowNode,
  SideBarDef,
} from 'ag-grid-community';
import { Component, createEffect, createSignal, onMount } from 'solid-js';
import { effect } from 'solid-js/web';

import { closeModal, isModalOpen, openModal } from '../../../store/modals';
import { ManageModal } from './ManageModal';
import { PostUnPostLoadModal } from './PostUnPostLoadModal';
import { SaveModal } from './SaveModal';
import classes from './classes';
import {
  FilterT,
  columnsToolPanel,
  filterByItems,
  filterRulesByValue,
  filtersToolPanel,
} from './constants';

const defaultItem = {
  label: '',
  value: '',
};

type FiltersProps = {
  gridReady: boolean;
  gridOptions: GridOptions;
  columnDefs: ColDef[];
  loading: boolean;
  gridApi?: GridApi;
  onRefreshGrid?: () => void;
  setCustomFilterCache: (filter: string) => void;
  onUpdatePostings: (postings: ILoadBoardViewModel[]) => void;
};

const Filters: Component<FiltersProps> = (props: FiltersProps) => {
  const [filterBy, setFilterBy] = createSignal<MenuItemType>(defaultItem);
  const [quickSearchString, setQuickSearchString] = createSignal('');
  const [advancedFilters, setAdvancedFilters] = createSignal(false);
  const [filterSaveError, setFilterSaveError] = createSignal(false);
  const [prepopulatedComment, setPrepopulatedComment] = createSignal(false);
  const [menuItems, setMenuItems] = createSignal<MenuItemType[]>([]);
  const [postUnpost, setPostUnpost] = createSignal<'none' | 'post' | 'unpost'>(
    'none',
  );
  const [loaded, setLoaded] = createSignal(false);
  const [rowCount, setRowCount] = createSignal(0);
  const [myFilters, setMyFilters] = createSignal(
    loadBoardStore.savedSearches.filter((s) => {
      return (
        s.category === SavedSearchCategory.Basic ||
        s.category === SavedSearchCategory.Advanced
      );
    }),
  );
  const [filterPass, setFilterPass] = createSignal<FilterPassT>({
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    fn: (node: IRowNode) => {
      return true;
    },
    type: undefined,
    filter: '',
  });

  effect(() => {
    setMenuItems([
      { value: '', label: 'Select' },
      {
        value: '',
        label: 'MY FILTERS',
        disabled: true,
      },
      ...myFilters().map((f) => {
        const label =
          f.category === SavedSearchCategory.Advanced ? ' (Adv)' : '';
        if (f.name.length > 15) {
          return {
            value: `my:${f.name}`,
            label: `${f.name.slice(0, 15)}...${label}`,
          };
        }
        return {
          value: `my:${f.name}`,
          label: `${f.name}${label}`,
        };
      }),
      ...filterByItems,
      {
        value: '',
        label: 'MY GROUP',
        disabled: true,
      },
      ...Object.keys(loadBoardStore.membersByGroupName).map((key) => {
        return {
          value: `by_Group:${key}`,
          label: key,
        };
      }),
      {
        value: '',
        label: 'MY CUSTOMER GROUP',
        disabled: true,
      },
      ...Object.keys(loadBoardStore.officeGroupIdsByMachingNames)
        .filter((g) => g != 'The Office' && g != 'Managers')
        .map((key) => {
          return {
            value: `by_Customer:${key}`,
            label: key,
          };
        }),
    ]);
  });

  onMount(async () => {
    const prepopulatedComment = await getEffectiveIsEnabledForUserByFeatureName(
      'PrepopulateOrderIdOnMultiPostingCommentEnabled',
    );

    setPrepopulatedComment(Boolean(prepopulatedComment));

    const selectedFilter = sessionStorage.getItem('selectedFilter');
    if (Boolean(selectedFilter)) {
      try {
        setFilterBy(JSON.parse(selectedFilter as string));
      } catch (e) {
        const item = findMenuItemByValue(selectedFilter as string, menuItems());

        setFilterBy(item);
        sessionStorage.setItem('selectedFilter', JSON.stringify(filterBy()));
      }
    }
  });

  createEffect(() => {
    if (props.gridApi === undefined) {
      return;
    }

    props.gridApi.setGridOption(
      'isExternalFilterPresent',
      isExternalFilterPresent,
    );

    props.gridApi.setGridOption('doesExternalFilterPass', filterPass().fn);
  });

  createEffect(() => {
    if (props.gridReady && props.gridApi !== undefined) {
      const searchFilter = props.gridApi.getQuickFilter() ?? '';
      setQuickSearchString(searchFilter);
    }

    void setRowCount(props.gridApi?.getModel()?.getRowCount() ?? 0);
  });

  const saveFilterModalId = 'saveFilterModal';
  const manageSavedTemplatesId = 'manageSavedTemplates';
  const postUnpostLoadModalId = 'postUnpostLoadModalId';

  // Approve by jeremy
  // eslint-disable-next-line complexity
  const onFilterByChange = (event: SelectChangeEvent) => {
    if (props.gridApi === undefined) {
      return;
    }
    const value = event.target.value;
    const myFilter = value.match(/my:(.*$)/);
    const byGroupFilter = value.match(/by_Group:(.*$)/);
    const byCustomerFilter = value.match(/by_Customer:(.*$)/);

    const selectedFilter = findMenuItemByValue(value, menuItems());
    sessionStorage.setItem('selectedFilter', JSON.stringify(selectedFilter));
    setFilterBy(selectedFilter);

    if (value === '') {
      props.gridApi.setFilterModel(null);
      return;
    } else if (myFilter !== null) {
      props.gridApi.setGridOption('isExternalFilterPresent', () => false);
      const filterName = myFilter[1];
      const filter = loadBoardStore.savedSearches.find((s) => {
        return s.name === filterName;
      });

      if (filter !== undefined) {
        if (
          (filter.category === SavedSearchCategory.Basic &&
            advancedFilters()) ||
          (filter.category === SavedSearchCategory.Advanced &&
            !advancedFilters())
        ) {
          onAdvancedFiltersEnabled();
        }

        const gridState = JSON.parse(
          filter.params,
        ) as gridStateWithAdditionalProps;
        if (gridState.rowGroup !== undefined) {
          props.gridApi.setRowGroupColumns(gridState.rowGroup.groupColIds);
        }
        props.gridApi.setGridOption('quickFilterText', gridState.quickSearch);

        if (gridState.columnPinning !== undefined) {
          gridState.columnPinning.leftColIds.forEach((col) => {
            props.gridApi?.setColumnPinned(col, 'left');
          });
          gridState.columnPinning.rightColIds.forEach((col) => {
            props.gridApi?.setColumnPinned(col, 'right');
          });
        }

        if (gridState.filter !== undefined) {
          props.gridApi.setFilterModel(gridState.filter.filterModel);
        } else {
          props.gridApi.setFilterModel(null);
        }
        //If user is in a group and postingId is not included in the order colId due to preexisting cache, add it to the front of the array
        if (
          !Boolean(
            gridState.columnOrder?.orderedColIds.includes('postingId'),
          ) &&
          Boolean(userStore.user.postingGroupId)
        ) {
          gridState.columnOrder?.orderedColIds.unshift('postingId');
        }

        if (gridState.sideBar !== undefined) {
          props.gridApi.setSideBarVisible(gridState.sideBar.visible);
          props.gridApi.setSideBarPosition(gridState.sideBar.position);
        }
        if (gridState.columnOrder !== undefined) {
          const newColumnDefs = gridState.columnOrder.orderedColIds
            .map((col) => {
              return columnDefsMap[col];
            })
            // eslint-disable-next-line  @typescript-eslint/no-unnecessary-condition
            .filter((v) => v !== undefined)
            .filter((v) => {
              //If user is not in a group, return all columns except postingId
              if (!Boolean(userStore.user.postingGroupId)) {
                return v.field !== 'postingId';
              }
              //Otherwise, return all columns
              return v;
            });

          //now merge in ones that are not in the ordered list
          props.columnDefs.forEach((col) => {
            if (!newColumnDefs.find((def) => def.field === col.field)) {
              newColumnDefs.push(col);
            }
          });
          props.gridApi.setGridOption('columnDefs', newColumnDefs);
        }
        if (gridState.sort !== undefined) {
          props.gridApi.applyColumnState({
            state: [...gridState.sort.sortModel],
            defaultState: { sort: null },
          });
        }

        if (gridState.columnVisibility !== undefined) {
          const visibility = gridState.columnVisibility.hiddenColIds
            .map((col) => {
              return props.columnDefs.find((def) => def.field === col);
            })
            .filter((def) => def !== undefined)
            .map((def) => def?.field) as string[];
          props.gridApi.setColumnsVisible(visibility, false);
        } else {
          const visibility = props.columnDefs
            .map((def) => def.field)
            .filter((def) => def !== undefined) as string[];
          props.gridApi.setColumnsVisible(visibility, true);
        }

        if (gridState.columnSizing !== undefined) {
          props.gridApi.setColumnWidths(
            gridState.columnSizing.columnSizingModel.map((size) => {
              return {
                key: size.colId,
                newWidth: size.width !== undefined ? size.width : 100,
              };
            }),
          );
        }
      }
      props.setCustomFilterCache('');
      return;
    } else if (byGroupFilter !== null) {
      const groupName = byGroupFilter[1];
      setFilterPass({
        fn: doesExternalFilterFactory(groupName, 'group'),
        type: 'group',
        filter: groupName,
      });

      props.gridApi.setFilterModel(null);
      props.gridApi.onFilterChanged();
      props.setCustomFilterCache(value);
      return;
    } else if (byCustomerFilter !== null) {
      setFilterPass({
        fn: doesExternalFilterFactory(byCustomerFilter[1], 'customer'),
        type: 'customer',
        filter: byCustomerFilter[1],
      });

      props.gridApi.setFilterModel(null);
      props.gridApi.onFilterChanged();
      props.setCustomFilterCache(value);
      return;
    }

    const rules = filterRulesByValue[value] as FilterT[] | undefined;
    if (rules !== undefined) {
      const filterModel: Record<string, unknown> = {};

      for (const rule of rules) {
        filterModel[rule.label] = rule.getFilter();
      }

      props.gridApi.setGridOption('isExternalFilterPresent', () => false);
      props.setCustomFilterCache(value);
      props.gridApi.setFilterModel(filterModel);
    }
  };

  const clearFilters = () => {
    if (props.gridApi === undefined) {
      return;
    }

    props.gridApi.setFilterModel(null);
    props.gridApi.setAdvancedFilterModel(null);
    props.gridApi.setGridOption('isExternalFilterPresent', () => false);
    props.setCustomFilterCache('');
    sessionStorage.removeItem('savedSearches');
    sessionStorage.removeItem('selectedFilter');
    saveLoadboardCache(undefined);
    setFilterBy(defaultItem);
  };

  const clearAllFilters = (activeFilter?: string) => {
    if (props.gridApi === undefined) {
      return;
    }

    // This api call clears the sorting from the columns.
    props.gridApi.applyColumnState({
      state: [],
      defaultState: { sort: null },
    });

    if (activeFilter === undefined) {
      setQuickSearchString('');
      props.gridApi.setGridOption('quickFilterText', '');
      clearFilters();
      return;
    }

    const myFilter = activeFilter.match(/my:(.*$)/);
    const rules = filterRulesByValue[activeFilter] as FilterT[] | undefined;

    setFilterBy(defaultItem);
    sessionStorage.removeItem('selectedFilter');

    if (rules !== undefined) {
      // This logic might break if we have hard-coded filters that are advanced,
      // rather than basic. We should revisit this if this becomes an issue.
      const filterModel = { ...props.gridApi.getFilterModel() };

      for (const rule of rules) {
        delete filterModel[rule.label];
      }

      props.gridApi.setFilterModel(filterModel);
    } else if (myFilter !== null) {
      props.gridApi.setFilterModel(null);
      props.gridApi.setAdvancedFilterModel(null);
    }
  };

  const onFilterModelSave = async (name: string, cb: () => void) => {
    if (props.gridApi === undefined) {
      return;
    }

    let model: Record<string, unknown>;
    let category = SavedSearchCategory.Basic;

    if (!advancedFilters()) {
      model = props.gridApi.getFilterModel();
    } else {
      model = props.gridApi.getAdvancedFilterModel() as unknown as Record<
        string,
        unknown
      >;
      category = SavedSearchCategory.Advanced;
    }

    const gridState = props.gridApi.getState();
    const columns = props.gridApi.getColumnState();
    const quickSearch = props.gridApi.getQuickFilter() ?? '';
    const completeState = {
      ...gridState,
      quickSearch,
      customFilter: model,
      sort: {
        sortModel: getSortModelFromColumnState(columns),
      },
    };

    try {
      const newFilter = await saveFilter(completeState, category, name);
      newFilter.userId = userStore.user.id;
      appendNewSavedSearch(newFilter);
      closeModal(saveFilterModalId);
    } catch (e) {
      printLog(e);
      setFilterSaveError(true);
    } finally {
      cb();
    }
  };

  const onAdvancedFiltersEnabled = () => {
    const newVal = !advancedFilters();
    setAdvancedFilters(newVal);

    if (props.gridApi === undefined) {
      return;
    }

    props.gridApi.setFilterModel(null);
    props.gridApi.refreshCells();
    props.gridOptions.enableAdvancedFilter = newVal;
    props.gridApi.setGridOption('enableAdvancedFilter', newVal);

    const sideBar: SideBarDef = {};

    if (!newVal) {
      sideBar.toolPanels = [filtersToolPanel, columnsToolPanel];
    } else {
      sideBar.toolPanels = [columnsToolPanel];
    }

    props.gridApi.setGridOption('sideBar', sideBar);
  };

  const setCustomerFilter = (filter: string) => {
    const gridState = loadBoardStore.cache
      ? (JSON.parse(
          loadBoardStore.cache.params,
        ) as gridStateWithAdditionalProps)
      : { customFilter: undefined };
    const currentFilterBy = filterBy().value;

    const selectedFilter = findMenuItemByValue(filter, menuItems());
    sessionStorage.setItem('selectedFilter', JSON.stringify(selectedFilter));
    setFilterBy(selectedFilter);

    const byGroupFilter = filter.match(/by_Group:(.*$)/);
    const byCustomerFilter = filter.match(/by_Customer:(.*$)/);

    if (byGroupFilter !== null && props.gridApi !== undefined) {
      const groupName = byGroupFilter[1];

      if (filterPass().type !== 'group') {
        setFilterPass({
          fn: doesExternalFilterFactory(groupName, 'group'),
          type: 'group',
          filter: groupName,
        });
      }

      if (currentFilterBy === filter && gridState.customFilter === filter) {
        return;
      }

      props.gridApi.onFilterChanged();
      props.setCustomFilterCache(filter);
    } else if (byCustomerFilter !== null && props.gridApi !== undefined) {
      if (filterPass().type !== 'customer') {
        setFilterPass({
          fn: doesExternalFilterFactory(byCustomerFilter[1], 'customer'),
          type: 'customer',
          filter: byCustomerFilter[1],
        });
      }

      if (currentFilterBy === filter && gridState.customFilter === filter) {
        return;
      }

      props.gridApi.onFilterChanged();
    }
  };

  effect(() => {
    if (loadBoardStore.cache === undefined) {
      return;
    }
    const gridState = JSON.parse(
      loadBoardStore.cache.params,
    ) as gridStateWithAdditionalProps;
    if (gridState.customFilter) {
      const selectedFilter = findMenuItemByValue(
        gridState.customFilter,
        menuItems(),
      );
      sessionStorage.setItem('selectedFilter', JSON.stringify(selectedFilter));

      if (
        typeof gridState.customFilter == 'string' &&
        (gridState.customFilter.includes('my:') ||
          gridState.customFilter.includes('by_Group:') ||
          gridState.customFilter.includes('by_Customer:'))
      ) {
        setFilterBy(selectedFilter);
        setCustomerFilter(gridState.customFilter);
      } else if (gridState.customFilter) {
        setFilterBy(selectedFilter);
      }
    }
  });

  // Approve by James
  // eslint-disable-next-line complexity
  effect(() => {
    if (filterSaveError() && !isModalOpen(saveFilterModalId)) {
      setFilterSaveError(false);
    }

    if (loadBoardStore.cache !== undefined) {
      const gridState = JSON.parse(
        loadBoardStore.cache.params,
      ) as gridStateWithAdditionalProps;

      if (
        gridState.filter !== undefined &&
        props.gridApi !== undefined &&
        !loaded()
      ) {
        if (gridState.filter.advancedFilterModel !== undefined) {
          if (!advancedFilters()) {
            onAdvancedFiltersEnabled();
          }
          props.gridApi.setAdvancedFilterModel(
            gridState.filter.advancedFilterModel,
          );
        } else if (gridState.filter.filterModel !== undefined) {
          if (advancedFilters()) {
            onAdvancedFiltersEnabled();
          }

          props.gridApi.setFilterModel(gridState.filter.filterModel);
        }

        props.gridApi.refreshCells();
        setLoaded(true);
      }
    }

    setMyFilters(loadBoardStore.filters);
  });

  return (
    <Stack spacing={2}>
      <MuiGrid
        class="flex flex-nowrap gap-3 items-center"
        sx={{
          backgroundColor: '#fff',
          padding: '10px',
        }}
      >
        <TextInput
          label="Find..."
          variant="outlined"
          id="full-text-find"
          fullWidth={false}
          sxProps={{
            background: 'white',
          }}
          size="small"
          value={quickSearchString()}
          onChange={(value) => {
            const fullTextFilter = value as string;
            if (props.gridApi !== undefined) {
              clearFilters();
              setQuickSearchString(fullTextFilter);
              props.gridApi.setGridOption('quickFilterText', fullTextFilter);
            }
          }}
        />
        <Select
          label="Filters"
          multiple={false}
          value={filterBy().value}
          menuItems={menuItems()}
          renderValue={(value) => {
            if (value.includes(':')) {
              return value.split(':')[1];
            }

            const selectedOption = filterByItems.find((filter) => {
              return filter.value === value;
            });

            return selectedOption ? selectedOption.label : value;
          }}
          onChange={(event) => {
            onFilterByChange(event);
            setQuickSearchString('');
            props.gridApi?.setGridOption('quickFilterText', '');
            void setRowCount(props.gridApi?.getDisplayedRowCount() ?? 0);
          }}
          width="260px"
          size="small"
          padEnabled
        ></Select>
        <IconButton
          aria-label="Manage"
          title="Manage Filters"
          size="medium"
          color="info"
          onClick={() => openModal(manageSavedTemplatesId)}
        >
          <FilterListIcon />
        </IconButton>
        <IconButton
          aria-label="Save"
          title="Save Filters"
          size="medium"
          color="info"
          onClick={() => openModal(saveFilterModalId)}
        >
          <SaveIcon />
        </IconButton>
        <IconButton
          aria-label="Refresh"
          title="Refresh Loadboard"
          size="medium"
          color="info"
          onClick={() => props.onRefreshGrid?.()}
          disabled={props.loading}
          sx={
            props.loading
              ? {
                  marginLeft: '2px',
                  marginRight: '2px',
                }
              : undefined
          }
        >
          {props.loading ? <CircularProgress size={20} /> : <RefreshIcon />}
        </IconButton>
        <IconButton
          aria-label="Clear All Filters"
          title="Clear All Filters"
          size="medium"
          color="info"
          aria-owns=""
          aria-haspopup="true"
          onClick={() => {
            clearAllFilters();
            void setRowCount(props.gridApi?.getDisplayedRowCount() ?? 0);
          }}
        >
          <CloseIcon />
        </IconButton>
        <IconButton
          aria-label="Post load(s)"
          title="Post load(s)"
          size="medium"
          color="info"
          aria-owns=""
          aria-haspopup="true"
          onClick={() => {
            setPostUnpost('post');
            openModal(postUnpostLoadModalId);
          }}
        >
          <PublishIcon />
        </IconButton>
        <IconButton
          aria-label="Unpost load(s)"
          title="Unpost load(s)"
          size="medium"
          color="info"
          aria-owns=""
          aria-haspopup="true"
          onClick={() => {
            setPostUnpost('unpost');
            openModal(postUnpostLoadModalId);
          }}
        >
          <DownloadIcon />
        </IconButton>
        <FormControlLabel
          label="Advanced Filters"
          control={
            <Checkbox
              checked={advancedFilters()}
              onChange={() => {
                onAdvancedFiltersEnabled();
                void setRowCount(props.gridApi?.getDisplayedRowCount() ?? 0);
              }}
            />
          }
        />
        <div class={`${classes.totalAmount}`}>
          <div class={`${classes.totalAmountRegularFont2} `}>Load Count</div>
          <div class={` ${classes.totalAmountRegularFont}`}>{rowCount()}</div>
        </div>
      </MuiGrid>
      <ManageModal id={manageSavedTemplatesId} onCloseModal={closeModal} />
      <SaveModal
        id={saveFilterModalId}
        onCloseModal={closeModal}
        onSave={onFilterModelSave}
        hasError={filterSaveError()}
      />
      {postUnpost() !== 'none' && (
        <PostUnPostLoadModal
          id={postUnpostLoadModalId}
          action={postUnpost()}
          onCloseModal={closeModal}
          refreshGrid={props.onRefreshGrid}
          prepopulatedComment={prepopulatedComment()}
          onUpdatePostings={props.onUpdatePostings}
        />
      )}
    </Stack>
  );
};

export default Filters;
