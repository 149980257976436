import { BaseApiResponseT } from '@common/types';
import { IRowNode } from 'ag-grid-community';

export interface ILoadBoardViewModel {
  loadId: number;
  orderId: number;
  customerName: string;
  customerId: number;
  referenceNumber?: string | null;
  carrierName: string;
  status: string;
  office: string;
  officeId: number;
  owner: string;
  ownerId: number;
  assignedTo: string;
  accountManager: string;
  assignedToGroupId?: number | null;
  salesManager: string;
  coveredBy: string;
  coveredById: number;
  origin?: string;
  originCity?: string;
  originState?: string;
  originCountry?: string;
  mode: string;
  destination: string;
  destinationCity?: string;
  destinationState?: string;
  destinationCountry?: string;
  pickUps: number;
  pickDate: Date;
  pickUpTime?: Date | null;
  dropOffs: number;
  dropDate: Date;
  dropOffTime?: Date | null;
  equipment: 'F' | 'R'; // Other?
  flagId?: number | null;
  flagText?: string | null;
  flagType?: unknown | null; // ?
  assignedGroupId?: number | null;
  product?: string | null;
  claim: boolean;
  lastCheckCallDate?: Date | null;
  lastCheckCallComment?: string | null;
  sharedWithOfficeId?: Date | null;
  confirmationSigned: boolean;
  postingId?: number | null;
  miles: number;
  assignedToId: number;
  customerGroups?: string;
  salesManagerId?: number;
  accountManagerId?: number;
  loadNumber: string;
  sharedCapacity?: boolean;
  trackingNumber: string;
  proNumber: string;
  stopPickup: string;
  stopDropoff: string;
  my: string;
  myCustomers: string;
  stopsRatio: string;
  nextCheckCallDateUtc?: Date | null;
  pendingBids: number;
  totalBids: number;
  matchOriginDistance: number;
  matchDestinationDistance: number;
  weightKg: number | null;
  weightLbs: number | null;
  numStops: number;
  hazmatRequired: boolean;
  tarpRequired: boolean;
  teamRequired: boolean;
  overDimension: boolean;
  rampsRequired: boolean;
  maxPrice: number | null;
  minPrice: number | null;
  driverPhone: string | null;
  cargoValue: number | null;
  loadCreatedDateUtc: string | null;
}

export type LoadBoardMetaDataT = {
  loadId: number;
  ownerId: number;
  customerId: number;
  coveredById: number;
  assignedToGroupId: number | undefined;
  assignedToId: number | undefined;
  loadboardLine: ILoadBoardViewModel;
};

export type LoadBoardApiResponseT = {
  isEnabled: boolean;
  pendingApprovals: unknown[];
  message: string | null;
  items: ILoadBoardViewModel[];
  totalCount: number;
  page: number;
  pageSize: number;
  postFilterCount: number;
  preFilterCount: number;
  membersByGroupName: { [key: string]: string[] };
  officeGroupIdsByMachingNames: { [key: string]: number };
};

export type LoadBoardState = {
  items: ILoadBoardViewModel[];
  filteredItems: ILoadBoardViewModel[];
  savedSearches: SavedSearchT[];
  filters: SavedSearchT[];
  cache: SavedSearchT | undefined;
  isLoading: boolean;
  isError: boolean;
  errorMessage: string | null;
  updateLock: boolean;
  overlay: boolean;
  membersByGroupName: { [key: string]: string[] };
  officeGroupIdsByMachingNames: { [key: string]: number };
};

export enum LoadBoardEventEnum {
  Created = 'Load:Created',
  Updated = 'Load:Updated',
  Deleted = 'Load:Deleted',
  View = 'orderview',
  Leave = 'orderleave',
  Save = 'ordersave',
  Lock = 'orderlock',
  Unlock = 'orderunlock',
}

export type LoadBoardWSMsgT = {
  dataJson: string;
  eventName: LoadBoardEventEnum;
  groupsToSend: string[];
  groupName: string | null;
  hasGroup: boolean;
  leaveGroup: boolean;
  requestId: string;
  correlationId: string;
  sendToAll: boolean;
  id: number | null;
  userId: number | null;
};

export enum SavedSearchCategory {
  Legacy = 'Loadboard',
  Advanced = 'LoadboardVNEXTAdvanced',
  Basic = 'LoadboardVNEXTBasic',
  Cache = 'LoadboardCache',
  Layout = 'Layout',
}

export enum LayoutEnum {
  Default = 0,
  CarrierCap = 3,
  AccountManager = 4,
}

export type SavedSearchT = {
  id: number;
  userId: number;
  category: SavedSearchCategory;
  name: string;
  params: string;
};

export type SavedSearchApiResponseT = BaseApiResponseT<SavedSearchT[]>;
export type SaveSearchSettingsApiResponseT = BaseApiResponseT<SavedSearchT>;

export type PostLoadT = {
  Comment: string;
  LoadId: number;
};

export type PostMultipleResponseT = {
  errors: { error: string; message: string }[] | null;
  partialSuccess: boolean;
  failedPostingIds: number[];
  success: boolean;
  postings: ILoadBoardViewModel[];
};

export type UnPostMultipleResponseT = {
  errors: { error: string; message: string }[] | null;
  partialSuccess: boolean;
  success: boolean;
  postings: number[];
};

export type ValuesOf<T extends Readonly<string[]>> = T[number];

export type FilterPassT = {
  fn: (n: IRowNode) => boolean;
  type: 'group' | 'customer' | undefined;
  filter: string;
};
