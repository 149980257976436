import * as yup from 'yup';

const vendorLineItemSchema = yup.object().shape({
  type: yup.string().nullable(),
  description: yup.string().nullable(),
  quantity: yup
    .number()
    .nullable()
    .moreThan(0, 'Quantity must be greater than 0')
    .meta({ section: 'vendor' }),
  rate: yup
    .number()
    .nullable()
    .moreThan(0, 'Rate must be greater than 0')
    .meta({ section: 'vendor' }),
});

export const vendorSchema = yup.object().shape({
  name: yup
    .string()
    .required('Vendor Name is required')
    .meta({ section: 'vendor' }),
  contactName: yup
    .string()
    .required('Vendor Contact Name is required')
    .meta({ section: 'vendor' }),
  contactPhone: yup
    .string()
    .required('Vendor Contact Phone is required')
    .min(10, 'Phone number must be at least 10 digits')
    .meta({ section: 'vendor' }),
  mailingAddress1: yup
    .string()
    .required('Vendor Address is required')
    .meta({ section: 'vendor' }),
  mailingCity: yup
    .string()
    .required('Vendor City is required')
    .meta({ section: 'vendor' }),
  mailingState: yup
    .string()
    .required('Vendor State is required')
    .meta({ section: 'vendor' }),
  mailingZip: yup
    .string()
    .required('Vendor Zip is required')
    .meta({ section: 'vendor' }),
  serviceType: yup
    .string()
    .required('Vendor Service Type is required')
    .meta({ section: 'vendor' }),
  //should only be required if serviceType is other
  serviceTypeText: yup
    .string()
    .nullable()
    .test(
      'is-other-service-type',
      'Text is required for Other Service Type',
      function (value) {
        const { serviceType } = this.parent;
        return (
          serviceType !== 'Other' || (serviceType === 'Other' && Boolean(value))
        );
      },
    )
    .meta({ section: 'vendor' }),
  description: yup
    .string()
    .required('Vendor Description is required')
    .meta({ section: 'vendor' }),
  lineItems: yup.array().of(vendorLineItemSchema),
});
