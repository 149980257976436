import {
  Info,
  Cancel,
  Warning,
  ArrowCircleDown,
  ArrowCircleUp,
  InsertInvitation,
} from '@suid/icons-material';
import { Show, createEffect, createSignal, onMount } from 'solid-js';
import { CheckboxInput, TextInput } from '@components/forms';
import { closeModal } from '@store/modals';
import { BasicTable, Typography, Button, TimePicker } from '@components';
import { Divider, Stack } from '@suid/material';
import {
  LastUsedTopStopModel,
  orderStore,
  enableLoadTracking,
  ILoadEditViewModel,
  updateLoadPropertyAtIndex,
} from '@store/orders';
import {
  formatDateString as formatDate,
  timeZoneAbbreviation,
} from '@utils/dateFormat';
import macroPoint from '@assets/macroPoint.svg';
import project44 from '@assets/project44.svg';
import { createForm } from '@felte/solid';
import { printLog } from '@utils/utils';
import {
  TrackingProviders,
  TrackingData,
  TrackingStopWindow,
} from '@store/orders/types';
import { Column } from '@components/BasicTable/BasicTable';

import cls from './classes';

type Props = {
  load: ILoadEditViewModel;
  modalId: string;
  tabIndex: number;
  setTracking: (e: boolean) => void;
};

export const AcceptTrackingModal = (props: Props) => {
  const [isMacroLoading, setMacroloading] = createSignal(false);
  const [isProject44Loading, setProject44Loading] = createSignal(false);
  const [isProjectOverride, setProjectOverride] = createSignal(false);
  const [isMacroOverride, setMacroOverride] = createSignal(false);
  const [hasTrackingPreference, setTrackingPreference] = createSignal(false);

  // this to keep the copy of the initial values so in case of cancel of modal we need to populated the old values
  const initialTruckNumber =
    orderStore.order.loads[orderStore.activeTab.index].truckNumber;
  const initialTrailerNumber =
    orderStore.order.loads[orderStore.activeTab.index].trailerNumber;

  const initialValues = {
    driverPhone: props.load.driverPhoneNumber,
    loadId: orderStore.order.loads[props.tabIndex].id,
    trackingStartDate: new Date().toISOString(),
    settings: {
      truckNumber: '',
      trailerNumber: '',
      trackingEmail: '',
      timezone: timeZoneAbbreviation(),
    },
    trackingStopWindows: [] as TrackingStopWindow[],
  };

  const iconStyles = { color: '#0070A2' };

  createEffect(() => {
    const trackingStopsWindows =
      orderStore.order.loads[props.tabIndex].stops?.map((val) => {
        return { Id: val.id, EDT: '', ETA: '' };
      }) || [];
    setFields('trackingStopWindows', trackingStopsWindows);
    const preferedTrackingService = getKeyByValue(
      orderStore.order.trackingVisibilityProvider,
    );
    if (preferedTrackingService === 'Macropoint') {
      setMacroOverride(true);
      setTrackingPreference(true);
    } else if (preferedTrackingService === 'Project44') {
      setProjectOverride(true);
      setTrackingPreference(true);
    }
  });

  const DateCell = ({ rowData }: { rowData: LastUsedTopStopModel }) => (
    <div class="flex items-center mb-[40px]">
      <span class="mr-4">
        {rowData.pickUp && <ArrowCircleUp sx={iconStyles} />}
        {rowData.dropOff && <ArrowCircleDown sx={iconStyles} />}
      </span>
      <div class="flex gap-2">
        <Typography component="p" variant="inherit">
          {formatDate(rowData.stopDateTime + 'Z', 'MMM dd yyyy')}
        </Typography>
        <InsertInvitation sx={iconStyles} />
      </div>
    </div>
  );

  const TimeCell = ({ rowData }: { rowData: LastUsedTopStopModel }) => (
    <div class="mb-[40px]">{rowData.time}</div>
  );

  const LocationCell = ({ rowData }: { rowData: LastUsedTopStopModel }) => (
    <Stack spacing={1}>
      <Typography
        component="p"
        sxProps={{ fontWeight: 'bold' }}
        variant="inherit"
      >
        {rowData.locationName}
      </Typography>
      <Typography component="p" variant="inherit">
        {rowData.address1}, {rowData.address2}, {rowData.city}, {rowData.state}
      </Typography>
      <Typography component="p" variant="inherit">
        {rowData.contact}
      </Typography>
    </Stack>
  );

  const ArrivalTimeCell = ({ rowData }: { rowData: LastUsedTopStopModel }) => (
    <div class="mb-[40px]">
      <TimePicker
        value={
          (data().trackingStopWindows.length &&
            data().trackingStopWindows[rowData.stopOrder ?? 0].ETA) ||
          ''
        }
        onChange={(newTime) =>
          setFields(`trackingStopWindows[${rowData.stopOrder}].ETA`, newTime)
        }
      />
    </div>
  );

  const DepartureTimeCell = ({
    rowData,
  }: {
    rowData: LastUsedTopStopModel;
  }) => (
    <div class="mb-[40px]">
      <TimePicker
        value={
          (data().trackingStopWindows.length &&
            data().trackingStopWindows[rowData.stopOrder ?? 0].EDT) ||
          ''
        }
        onChange={(newTime) =>
          setFields(`trackingStopWindows[${rowData.stopOrder}].EDT`, newTime)
        }
      />
    </div>
  );

  const columns: Column<LastUsedTopStopModel>[] = [
    {
      name: 'Date',
      key: 'date',
      minWidth: 200,
      renderCell: (rowData) => <DateCell rowData={rowData} />,
    },
    {
      name: 'Time',
      key: 'time',
      minWidth: 120,
      renderCell: (rowData) => <TimeCell rowData={rowData} />,
    },
    {
      name: 'Location',
      key: 'location',
      maxWidth: 220,
      renderCell: (rowData) => <LocationCell rowData={rowData} />,
    },
    {
      name: 'Est. Arrival Time',
      key: 'ArrivalTime',
      renderCell: (rowData) => <ArrivalTimeCell rowData={rowData} />,
    },
    {
      name: 'Est. Departure Time',
      key: 'DepartureTime',
      renderCell: (rowData) => <DepartureTimeCell rowData={rowData} />,
    },
  ];

  const startLoader = (serviceId: number) => {
    serviceId === 1 ? setMacroloading(true) : setProject44Loading(true);
  };

  const stopLoader = (serviceId: number) => {
    serviceId === 1 ? setMacroloading(false) : setProject44Loading(false);
  };
  const { data, form, setFields } = createForm<TrackingData>({
    initialValues: initialValues,
    onSubmit: async (values) => {
      startLoader(values.serviceId);
      const response = await enableLoadTracking(values);
      stopLoader(values.serviceId);
      if (response) {
        closeModal(props.modalId);
        updateLoadPropertyAtIndex({
          truckNumber: values.settings.truckNumber,
          trailerNumber: values.settings.trailerNumber,
          trackingEmail: values.settings.trackingEmail,
        });
      }
    },
    onError: (errors) => {
      printLog(errors, 'Form Errors');
    },
  });

  onMount(() => {
    const currentLoad = orderStore.order.loads[orderStore.activeTab.index];
    setFields('settings', (prevSettings) => ({
      ...prevSettings,
      truckNumber: currentLoad.truckNumber ?? prevSettings.truckNumber,
      trailerNumber: currentLoad.trailerNumber ?? prevSettings.trailerNumber,
      email: currentLoad.trackingEmail ?? prevSettings.trackingEmail,
    }));
  });

  const getKeyByValue = (value: number | undefined) => {
    for (const key in TrackingProviders) {
      if (TrackingProviders[key as keyof typeof TrackingProviders] === value) {
        return key;
      }
    }
  };

  const getPrefferedService = () => {
    const trackingProvider = getKeyByValue(
      orderStore.order.trackingVisibilityProvider,
    );
    if (trackingProvider !== undefined) {
      return (
        <span class={cls.trackingInfoStyle}>
          {`This customer prefers ${trackingProvider} for tracking.`}
        </span>
      );
    }
  };

  return (
    <form ref={form}>
      <div class={cls.modalMetaData}>
        <div class={cls.infoGridStyle}>
          <Info style={{ 'font-size': '24px', color: '#026EA1' }} />
          <span class={cls.trackingInfoStyle}>
            If you select tracking, any fees will appear as an adjustment on
            this load
          </span>
        </div>
        <Divider />
        <BasicTable<LastUsedTopStopModel>
          columns={columns}
          rows={orderStore.order.loads[orderStore.activeTab.index]?.stops ?? []}
          footerBorder={false}
          class="h-[1200px]"
        />
        {hasTrackingPreference() && (
          <div class={cls.warningInfoGrid}>
            <Warning style={{ 'font-size': '24px', color: '#F59D25' }} />
            {getPrefferedService()}
          </div>
        )}
        <div class={cls.trackingProviderContainer}>
          <div class={cls.trackingProvidertCardStyles}>
            <div class={cls.logo}>
              <img src={macroPoint} alt="" />
            </div>
            <Divider />
            <TextInput
              type="text"
              label="Truck"
              id="truck"
              onChange={(value) => {
                setFields('settings.truckNumber', value as string);
              }}
              value={data().settings.truckNumber || ''}
            />
            <TextInput
              type="text"
              label="Trailer"
              id="trailer"
              onChange={(value) => {
                setFields('settings.trailerNumber', value as string);
              }}
              value={data().settings.trailerNumber || ''}
            />
            <TextInput
              type="text"
              label="Email"
              id="email"
              onChange={(value) => {
                setFields('settings.trackingEmail', value as string);
              }}
              value={data().settings.trackingEmail}
            />
            <div class={cls.infoGridStyle}>
              <Info style={{ 'font-size': '24px', color: '#026EA1' }} />
              <span class={cls.trackingInfoStyle}>
                Optional: Enter truck and trailer number to enable ELD Tracking
              </span>
            </div>
            <div class={cls.trackingFrequency}>
              <span class={cls.fontBold}>Every 15-20 Minutes:</span>
              <div>
                <span class="font-bold text-3xl">$2.50 /</span>
                <span class={cls.fontBold}>Load</span>
              </div>
              <Show
                when={
                  getKeyByValue(orderStore.order.trackingVisibilityProvider) ===
                  'Project44'
                }
              >
                <CheckboxInput
                  onChange={(prev) => setMacroOverride(prev)}
                  name="Override"
                  label="Override"
                />
              </Show>
            </div>

            <Button
              label="Select"
              type="submit"
              variant="contained"
              onClick={() =>
                setFields('serviceId', TrackingProviders.Macropoint)
              }
              isLoading={isMacroLoading()}
              disabled={
                (hasTrackingPreference() && !isMacroOverride()) ||
                isMacroLoading()
              }
            />
          </div>

          <div class={cls.trackingProvidertCardStyles}>
            <div class={cls.logo}>
              <img src={project44} alt="" />
            </div>
            <Divider />
            <div class={cls.trackingFrequency}>
              <span class={cls.fontBold}>Every 30 Minutes:</span>
              <div>
                <span class="font-bold text-3xl">$3.50 /</span>
                <span class={cls.fontBold}>Load</span>
              </div>
              <Show
                when={
                  getKeyByValue(orderStore.order.trackingVisibilityProvider) ===
                  'Macropoint'
                }
              >
                <CheckboxInput
                  onChange={(prev) => setProjectOverride(prev)}
                  name="Override"
                  label="Override"
                />
              </Show>
            </div>
            <div class={cls.project44Btn}>
              <Button
                class={cls.fullWidth}
                label="Select"
                type="submit"
                variant="contained"
                onClick={() =>
                  setFields('serviceId', TrackingProviders.Project44)
                }
                isLoading={isProject44Loading()}
                disabled={
                  (hasTrackingPreference() && !isProjectOverride()) ||
                  isProject44Loading()
                }
              />
            </div>
          </div>
        </div>
      </div>
      <div class={cls.trackingBtn}>
        <Button
          variant="outlined"
          label="No Thanks"
          type="Cancel"
          startIcon={<Cancel />}
          onClick={() => {
            props.setTracking(
              orderStore.order.loads[props.tabIndex].trackingEnabled,
            );
            updateLoadPropertyAtIndex({
              truckNumber: initialTruckNumber,
              trailerNumber: initialTrailerNumber,
            });
            closeModal(props.modalId);
          }}
        />
      </div>
    </form>
  );
};
