import { carrierUrls } from '@constants/urls';
import { ILoadEditViewModel } from '@store/orders';
import { OpenInNew } from '@suid/icons-material';
import { Box, Button, Stack } from '@suid/material';

type PropsT = {
  load: ILoadEditViewModel;
  tabIndex: number;
  isCarrierSelected: boolean;
};

export const CarrierDetailActionButtons = (props: PropsT) => {
  return (
    <Stack direction="row-reverse" gap={1} alignItems="center" mb="-5px">
      <Box>
        <Button
          variant="contained"
          size="small"
          class="!text-armstrong-sm !bg-white !border-[#123B50] !border font !rounded !text-[#123B50]"
          startIcon={<OpenInNew />}
          sx={{
            border: '1px solid',
          }}
          onClick={() =>
            window.open(carrierUrls.billOfLading(props.load.id), '_blank')
          }
        >
          Bill of Lading
        </Button>
      </Box>
      <Box>
        <Button
          variant="contained"
          size="small"
          class="!text-armstrong-sm !bg-white !border-[#123B50] !border font !rounded !text-[#123B50]"
          startIcon={<OpenInNew />}
          sx={{
            border: '1px solid',
          }}
          onClick={() =>
            window.open(carrierUrls.rateConfirmation(props.load.id), '_blank')
          }
        >
          Rate Confirmation
        </Button>
      </Box>
    </Stack>
  );
};
