import { v3Client, v3FileUploadClient } from '@api/apiClient';
import { CarrierDocument, FactoringCompany } from '@store/carriers';
import { AddOrEditCustomerCommentPayload } from '@store/customers/customerDetails';
import { handleToast } from '@utils/utils';
import { ToastType } from '@components';

import {
  FactoringCompanyDetailsApiRespone,
  FactoringCompanyDetailsEditHistory,
  FactoringCompanyDetailsLoadHistory,
} from './types';

export const fetchCustomerFactoringCompanyDetails = async (id: string) => {
  try {
    const response = await v3Client.get(`FactoringCompany/${id}`);
    return response as unknown as FactoringCompanyDetailsApiRespone;
  } catch (error: unknown) {
    throw new Error(
      `Failed to fetch customer factoring company Data:  ${error}`,
    );
  }
};

export const deleteFactoringCompanyDetails = async (id: number) => {
  try {
    const res: {
      success: boolean;
    } = await v3Client.delete(`FactoringCompany/${id}`);
    return res.success;
  } catch (error: unknown) {
    if (error instanceof Error) {
      handleToast(
        ToastType.Error,
        `Failed to delete factoring company: ${error.message}`,
      );
    }
  }
};

export const saveNewFactoringCompantDetails = async (
  data: FactoringCompany,
) => {
  try {
    const response = await v3Client.post('FactoringCompany', data);
    return response as unknown as {
      success: boolean;
    };
  } catch (error: unknown) {
    if (error instanceof Error) {
      handleToast(
        ToastType.Error,
        `Failed to save new factoring company: ${error.message}`,
      );
    }
  }
};

export const fetchFactoringCompanyDetailsLoadHistory = async (id: string) => {
  try {
    const response = await v3Client.get(`FactoringCompany/LoadHistory/${id}`);
    return response as unknown as FactoringCompanyDetailsLoadHistory[];
  } catch (error: unknown) {
    if (error instanceof Error) {
      handleToast(
        ToastType.Error,
        `Failed to fetch factoring company load history: ${error.message}`,
      );
    }
  }
};

export const updateFactoringCompanyDetails = async (
  id: number,
  data: FactoringCompany,
) => {
  try {
    const response = await v3Client.put(`FactoringCompany/${id}`, data);
    return response as unknown as {
      success: boolean;
    };
  } catch (error: unknown) {
    if (error instanceof Error) {
      handleToast(
        ToastType.Error,
        `Failed to update factoring company: ${error.message}`,
      );
    }
  }
};

export const fetchFactoringCompanyDetailsDocuments = async (id: string) => {
  try {
    const response = await v3Client.get(`Document/FactoringCompany/${id}`);
    return response as unknown as CarrierDocument[];
  } catch (error: unknown) {
    if (error instanceof Error) {
      handleToast(
        ToastType.Error,
        `Failed to fetch factoring company documents: ${error.message}`,
      );
    }
  }
};

export const uploadFactoringCompanyDetailsDocument = async (
  id: number,
  file: File,
) => {
  try {
    const formData = new FormData();
    formData.append(`${file.name}`, file);
    const res = await v3FileUploadClient.post(
      `/Document/FactoringCompany/${id}`,
      formData,
    );
    return res.data as unknown as {
      url: string;
    };
  } catch (error: unknown) {
    throw new Error(`Failed to upload factoring company document: ${error}`);
  }
};

export const deleteFactoringCompanyDetailsDocument = async (
  payload: FormData,
) => {
  try {
    const urlEncodedData = new URLSearchParams();
    for (const pair of payload.entries()) {
      urlEncodedData.append(pair[0], String(pair[1]));
    }
    await v3FileUploadClient.post('/Document/DeleteFile', urlEncodedData, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });
  } catch (error: unknown) {
    throw new Error('Deleting factoring company document failed');
  }
};

export const deleteFactoringCompanyDetailsComment = async (
  commentId: number,
) => {
  try {
    const response: {
      success: boolean;
    } = await v3Client.delete(`/Comment/FactoringCompany/${commentId}`);
    return response.success;
  } catch (error: unknown) {
    if (error instanceof Error) {
      handleToast(
        ToastType.Error,
        `Failed to delete factoring company comment: ${error.message}`,
      );
    }
  }
};

export async function addFactoringCompanyDetailsComment(
  payload: AddOrEditCustomerCommentPayload,
) {
  try {
    const response = await v3Client.post(
      `Comment/FactoringCompany/${payload.id}`,
      payload,
    );
    return response.data as unknown as Comment[];
  } catch (error: unknown) {
    throw new Error(`Failed to Add Comment on Customer general tab: ${error}`);
  }
}

export async function fetchFactoringCompanyDetailsEditHistory(id: string) {
  try {
    const response = await v3Client.get(
      `/FactoringCompany/EditHistory/${id}?id=${id}`,
    );
    return response as unknown as FactoringCompanyDetailsEditHistory[];
  } catch (error: unknown) {
    if (error instanceof Error) {
      handleToast(
        ToastType.Error,
        'Failed to fetch factoring company edit history',
      );
    }
  }
}
