import { Button, Typography } from '@components';
import { orderUrls } from '@constants/urls';
import { Link } from '@solidjs/router';
import { customerStore, orderStore } from '@store/orders';
import { OpenInNew } from '@suid/icons-material';
import { Show } from 'solid-js';
import { Box } from '@suid/material';

import classes from './classes';

export const CustomerInfoHeader = () => {
  const cls = classes.customerInfo;

  return (
    <Show when={Boolean(orderStore.order.customerId)}>
      <Box class={cls.profileContainer}>
        <Box class="flex items-center">
          <Typography component="h1" variant="h6" class={cls.profileName}>
            {customerStore.customer.name}
          </Typography>
          <Link
            href={orderUrls.profile(orderStore.order.customerId as number)}
            target="_blank"
            class={`${
              orderStore.order.customerId === null && 'pointer-events-none'
            } ml-auto`}
          >
            <Button
              variant="contained"
              label="Profile"
              size="small"
              startIcon={<OpenInNew />}
              sx={cls.profileStyle}
              disabled={orderStore.order.customerId === null}
            />
          </Link>
        </Box>
        <Box>
          <Link
            href={orderUrls.orderSheet(orderStore.order.id)}
            class={`${
              orderStore.order.customerId !== null
                ? cls.anchorLink
                : cls.disabledAnchorLink
            }`}
            target="_blank"
          >
            ORDER SHEET
          </Link>
          <Link
            href={orderUrls.customerConfirmation(orderStore.order.id)}
            target="_blank"
            class={`${
              orderStore.order.customerId !== null
                ? cls.anchorLink
                : cls.disabledAnchorLink
            }`}
          >
            CUSTOMER CONFIRMATION
          </Link>
          <Link
            href={orderUrls.invoice(orderStore.order.id)}
            target="_blank"
            class={`${
              orderStore.order.customerId !== null
                ? cls.anchorLink
                : cls.disabledAnchorLink
            }`}
          >
            INVOICE
          </Link>
        </Box>
      </Box>
    </Show>
  );
};
