import { Autocomplete, BasicModal, Button, ToastType } from '@components';
import { DialogBox } from '@components/DialogBox';
import { CheckboxInput, TextInput } from '@components/forms';
import { createForm } from '@felte/solid';
import { openDialogBox } from '@store/dialogBox';
import { closeModal, openModal } from '@store/modals';
import {
  ILoadComments,
  ILocationSearch,
  changeCommentVisibility,
  getCordinates,
  orderStore,
  removeComment,
  sendComment,
} from '@store/orders';
import DeleteIcon from '@suid/icons-material/Delete';
import FileDownloadOutlinedIcon from '@suid/icons-material/FileDownloadOutlined';
import {
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@suid/material';
import { handleToast, isAdmin, isSuperAdmin } from '@utils/utils';
import { setCustomerCommentVisiblityForComponent } from '@views/order/validations/helperFunctions';
import { DateTime } from 'luxon';
import { Show, createEffect, createSignal } from 'solid-js';

import { CustomerUpdateModal } from '../carrier/tracking/CustomerUpdateModal';

const modalStyles = {
  minWidth: '900px',
  height: '500px',
  background: 'white',
  borderRadius: '5px',
};

type customerTrackingProps = {
  activeCarrierTab: number;
};

export type TrackingCommentSchema = {
  comment: string;
  loadId: number;
  state: string | null;
  city: string | null;
  latitude: string | null;
  longitude: string | null;
  temperature: number | null;
  visibleToCustomer: boolean;
  timestamp: string;
};

export const CustomerTracking = (props: customerTrackingProps) => {
  const [comments, setComments] = createSignal<ILoadComments[] | undefined>();
  const [selectedComment, setSelectedComment] = createSignal<ILoadComments>();
  const [deleteRow, setDeleteRow] = createSignal<number | null>(null);
  const [query, setQuery] = createSignal<string>('');

  const customerUpdateModalId = 'customerUpdateModal';

  createEffect(() => {
    if (Boolean(orderStore.order.loads[props.activeCarrierTab]?.comments)) {
      setComments(orderStore.order.loads[props.activeCarrierTab]?.comments);
    }
  });

  const { form, errors, data, setData, reset } =
    createForm<TrackingCommentSchema>({
      initialValues: {
        comment: '',
        loadId: 0,
        state: null,
        city: null,
        latitude: null,
        longitude: null,
        temperature: null,
        visibleToCustomer: setCustomerCommentVisiblityForComponent(),
        timestamp: '',
      },
      onSubmit: async () => {
        if (orderStore.order.loads[props.activeCarrierTab]?.id == 0) {
          handleToast(
            ToastType.Caution,
            'Save the Load before adding comments',
          );
          return;
        }
        setData('loadId', orderStore.order.loads[props.activeCarrierTab]?.id);
        if (Boolean(data().city) && Boolean(data().state)) {
          const coords = await getCordinates([
            {
              Address: {
                City: data().city as string,
                State: data().state as string,
              },
            },
          ]);
          if (coords[0]?.lat) {
            setData('latitude', coords[0].lat.toString());
            setData('longitude', coords[0].lng.toString());
          }
        }
        await sendComment(data());
        setData('visibleToCustomer', false);
        reset();
      },
      validate: (values) => {
        if (values.comment === '') {
          return { comment: 'Comment Is Required' };
        }
      },
    });

  const handleLocationSelect = (item: ILocationSearch) => {
    setData('city', item.city);
    setData('state', item.state);
  };

  const handleDeleteComment = async () => {
    try {
      await removeComment(
        deleteRow()!,
        orderStore.order.loads[props.activeCarrierTab]?.id,
      );
      setDeleteRow(null);
    } catch (error) {
      handleToast(ToastType.Error, (error as Error).message);
    }
  };

  createEffect(() => {
    if (orderStore.order.loads[props.activeCarrierTab].mode) {
      setData('visibleToCustomer', setCustomerCommentVisiblityForComponent());
    }
  });
  return (
    <>
      <form ref={form}>
        <Show
          when={
            orderStore.order.loads[orderStore.activeTab.index].mode !== 'LTL'
          }
        >
          <div class="text-[#123b50] font-normal text-base font-roboto flex mt-2">
            Tracking Calls
          </div>
        </Show>
        <Grid container spacing={1} marginTop={'1px'} alignItems="top">
          <Grid item xs={6}>
            <Autocomplete
              name="city"
              size="small"
              defaultValue=""
              endpoint="Location/Search/"
              version="v3"
              responseFieldName="display"
              // @ts-expect-error ILocationSearch is ok here
              onItemSelect={(item: ILocationSearch) => {
                setQuery(item.display);
                handleLocationSelect(item);
              }}
              id="autocomplete-field-location"
              label="Location"
              value={query()}
              onChange={(v) => {
                setQuery(v);
              }}
              disabled={orderStore.isReadOnly}
            />
          </Grid>
          <Grid item xs={3}>
            <TextInput
              label="Temp"
              name="temperature"
              onChange={(v: string) => {
                setData('temperature', Number(v));
              }}
              error={orderStore.isReadOnly ? undefined : errors().temperature}
              type="number"
              disabled={orderStore.isReadOnly}
            />
          </Grid>
          <Grid item marginLeft={2}>
            <CheckboxInput
              size="small"
              label="Visible to Customer"
              id="sendToCustomer"
              onChange={(v) => {
                setData('visibleToCustomer', v);
              }}
              checked={data().visibleToCustomer}
              disabled={orderStore.isReadOnly}
            />
          </Grid>
          <Grid item xs={10}>
            <TextInput
              label="Comments"
              multiline
              rows={2}
              name="comment"
              onChange={(v: string) => {
                setData('comment', v);
              }}
              error={orderStore.isReadOnly ? undefined : errors().comment}
              disabled={orderStore.isReadOnly}
            />
          </Grid>
          <Grid item class="self-center" marginLeft={1}>
            <Button
              label="Save"
              variant="contained"
              type="submit"
              startIcon={<FileDownloadOutlinedIcon />}
              disabled={orderStore.order.id === 0 || orderStore.isReadOnly}
              color="primary"
            />
          </Grid>
        </Grid>
      </form>
      <TableContainer
        component={Paper}
        sx={{ marginTop: '10px', overflowY: 'auto', maxHeight: '400px' }}
      >
        <Table>
          <TableBody>
            {comments()?.map((comment) => {
              const onCommentClick = () => {
                setSelectedComment(comment);
                openModal(customerUpdateModalId);
              };

              return (
                <TableRow>
                  <TableCell
                    class="high-res:max-w-[500px] netbook:max-w-[220px] vertical-top"
                    onClick={onCommentClick}
                  >
                    <Grid container flexDirection="column">
                      <Grid item xs={12} class="font-bold">
                        {comment.name}
                      </Grid>
                      <Grid item xs={12} class="break-words">
                        {comment.comment}
                      </Grid>
                    </Grid>
                  </TableCell>
                  <TableCell class="w-[200px]">
                    <CheckboxInput
                      label="Visible to Customer"
                      checked={comment.visibleToCustomer}
                      onChange={async () => {
                        try {
                          await changeCommentVisibility(
                            comment.id,
                            orderStore.order.loads[props.activeCarrierTab]?.id,
                          );
                        } catch (error) {
                          handleToast(
                            ToastType.Error,
                            (error as Error).message,
                          );
                        }
                      }}
                      disabled={orderStore.isReadOnly}
                    />
                  </TableCell>
                  <TableCell
                    class="w-[200px] vertical-top"
                    onClick={onCommentClick}
                  >
                    <Grid container flexDirection="column">
                      <Grid item xs={12} class="font-bold text-xs">
                        {Boolean(comment.timestamp)
                          ? DateTime.fromISO(comment.timestamp, {
                              zone: 'utc',
                            })
                              .setZone('local')
                              .toFormat('MMMM dd yyyy HH:mm a')
                              .toUpperCase()
                          : ''}
                      </Grid>
                      <Grid item xs={12}>
                        <Show when={comment.city}>
                          {comment.city}, {comment.state}
                        </Show>
                      </Grid>
                    </Grid>
                  </TableCell>
                  <TableCell onClick={onCommentClick}>
                    <Show when={comment.temperature}>
                      {comment.temperature} F
                    </Show>
                  </TableCell>
                  <TableCell>
                    <Show when={isAdmin() || isSuperAdmin()} fallback={<></>}>
                      <IconButton
                        onClick={() => {
                          setDeleteRow(comment.id);
                          openDialogBox('removeTrackingCommentDialog');
                        }}
                        disabled={orderStore.isReadOnly}
                      >
                        <DeleteIcon class="text-[#B00020] cursor-pointer" />
                      </IconButton>
                    </Show>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        <BasicModal
          id={customerUpdateModalId}
          title="Send Customer Update"
          footer={false}
          onClose={() => {
            closeModal(customerUpdateModalId);
            setSelectedComment(undefined);
          }}
          onBackdropClick={() => {
            closeModal(customerUpdateModalId);
            setSelectedComment(undefined);
          }}
          modalStyles={modalStyles}
        >
          <CustomerUpdateModal comment={selectedComment()} />
        </BasicModal>
      </TableContainer>
      <DialogBox
        id="removeTrackingCommentDialog"
        title={'Are you sure you want to remove this comment?'}
        onSubmit={handleDeleteComment}
        onSubmitText={'Delete'}
      />
    </>
  );
};
