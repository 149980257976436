import { datadogRum } from '@datadog/browser-rum';
import { Router, useRoutes } from '@solidjs/router';
import { createSocket } from '@store/Global/service';
import { setSocketConnection } from '@store/Global/store';
import { userStore } from '@store/user';
import { ThemeProvider } from '@suid/material';
import { ConfigManager } from '@utils/ConfigManager';
import { EquipmentSearchRoute } from '@views/equipmentSearch';
import { SavedQuoteRoute } from '@views/ltl';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import 'ag-grid-enterprise';
import { onMount, Show } from 'solid-js';
import { Toaster } from 'solid-toast';

import theme from './utils/theme';
import {
  CarrierDetailsRoute,
  CarrierLaneSearchRoute,
  CarrierMatchingRoute,
  CarrierPlaylists,
  CarrierRoute,
  ClaimsTabRoute,
  CollectionDetailsRoute,
  CollectionsDashboardRoute,
  CollectionsRoute,
  CustomerDetailsRoute,
  CustomersRoute,
  DocumentsPageRoute,
  DormantAccountsRoute,
  EditHistoryRoute,
  FactoringCompanyDetailsCreationRoute,
  FactoringCompanyDetailsRoute,
  FactoringCompanyRoute,
  HoldsTabRoute,
  HomeRoute,
  LoadboardRoute,
  LoadSearchRoute,
  LTLRoute,
  NewCarrierDetailsRoute,
  NewCustomerRoute,
  NewProspectRoute,
  OfficeDetailRoute,
  OfficeRoute,
  OldLoadsRoute,
  OrderDetailsRoute,
  OrderRoute,
  RevenueSearchRoute,
  LoadTendersRoute,
} from './views';

const routes = [
  HomeRoute,
  CarrierRoute,
  OrderRoute,
  LoadboardRoute,
  DormantAccountsRoute,
  SavedQuoteRoute,
  OrderDetailsRoute,
  EquipmentSearchRoute,
  CarrierMatchingRoute,
  LTLRoute,
  ClaimsTabRoute,
  HoldsTabRoute,
  OfficeRoute,
  OfficeDetailRoute,
  CustomersRoute,
  OldLoadsRoute,
  EditHistoryRoute,
  CarrierLaneSearchRoute,
  CustomerDetailsRoute,
  NewCustomerRoute,
  CarrierPlaylists,
  CarrierDetailsRoute,
  DocumentsPageRoute,
  LoadSearchRoute,
  FactoringCompanyRoute,
  FactoringCompanyDetailsRoute,
  FactoringCompanyDetailsCreationRoute,
  NewProspectRoute,
  CollectionsRoute,
  CollectionsDashboardRoute,
  NewCarrierDetailsRoute,
  CollectionDetailsRoute,
  RevenueSearchRoute,
  LoadTendersRoute,
];

const rumContext = datadogRum.getInternalContext();
let rumSessionId: string | undefined;
if (rumContext && Boolean(rumContext.session_id)) {
  rumSessionId = rumContext.session_id;
}

function App() {
  const RoutesComponent = useRoutes(routes);

  onMount(async () => {
    const socketv = await createSocket({
      connection: `${ConfigManager.apiUrl}/clientHub`,
      loginUrl: `${ConfigManager.apiUrl}/api/login/token`,
    });

    await userStore.userPromise;

    setSocketConnection(socketv);
    datadogRum.setUser({
      id: `${userStore.user.id}`,
      name: `${userStore.user.name}`,
    });
  });

  return (
    <ThemeProvider theme={theme}>
      {/* Added Toaster here so that it can be used in all the components & We dont need to import everyWhere */}
      <Toaster />
      <div class="relative">
        <Router>
          <RoutesComponent />
          <Show when={Boolean(rumSessionId)}>
            <span class="absolute right-6 -bottom-6 bg-[#0000003D] text-white text-[10px] font-medium p-1 rounded-sm leading-3">
              {rumSessionId}
            </span>
          </Show>
        </Router>
      </div>
    </ThemeProvider>
  );
}

export default App;
