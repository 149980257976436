import { TextInput } from '@components/forms';
import FormErrorComponent from '@components/forms/FormErrorComponent';
import { FormControl } from '@suid/material';
import { SxProps } from '@suid/system';
import { DateTime } from 'luxon';
import { mergeProps } from 'solid-js';

export type Props = {
  sx?: SxProps;
  class?: string;
  name?: string;
  handleChange: (value: string, index?: number) => void;
  value?: string;
  placeholder?: string;
  error?: string[] | { [key: string]: string[] } | null | undefined;
  errorBorder?: string;
  disabled?: boolean;
  label?: string;
  required?: boolean;
  size?: 'medium' | 'small' | undefined;
  type?: 'date' | 'time';
  isUTC?: boolean;
  fullWidth?: boolean;
  fontSize?: string;
};
const DEFAULTS = {
  errorBorder:
    'w-full h-full border-red-600 border text-red-600 rounded-md py-4 px-3.5',
  class:
    'w-full h-full py-4 px-3.5 border border-gray-300 rounded-md hover:border-black',
};

export const DatePicker = (props: Props) => {
  props = mergeProps(DEFAULTS, props);

  const formatDate = () => {
    if (
      props.type !== undefined &&
      props.type === 'time' &&
      Boolean(props.value?.match(/^\d{2}:\d{2}$/))
    ) {
      return props.value as string;
    }

    // Get the user's local time zone dynamically
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    let dt: DateTime | undefined = undefined;

    if (!Boolean(props.isUTC)) {
      dt = DateTime.fromISO(props.value as string, {
        zone: userTimeZone,
      }).toLocal();
    } else {
      dt = DateTime.fromISO(props.value as string, { zone: 'utc' });
    }

    return dt.toFormat(props.type !== 'time' ? 'yyyy-MM-dd' : 'HH:mm');
  };

  return (
    <>
      <FormControl
        fullWidth={props.fullWidth === undefined ? true : props.fullWidth}
        variant="outlined"
        class={'bg-white rounded-md'}
        sx={{ ...props.sx }}
        disabled={props.disabled}
      >
        <TextInput
          fontSize={props.fontSize}
          label={props.label ?? ''}
          required={props.required}
          type={props.type ?? 'date'}
          name={props.name}
          value={formatDate()}
          onChange={(value) => {
            if (value !== '') {
              if (props.type !== 'time') {
                const formattedDate = DateTime.fromFormat(
                  value as string,
                  'yyyy-MM-dd',
                ).toISO();
                props.handleChange(formattedDate as string);
              } else {
                props.handleChange(value as string);
              }
            }
          }}
          classes={Boolean(props.error) ? props.errorBorder : props.class}
          disabled={props.disabled}
          InputLabelProps={{ shrink: true }}
          size={props.size}
        />
        <FormErrorComponent error={props.error} showHelpertext />
      </FormControl>
    </>
  );
};
