import { checkIcon, inactiveIcon, Pending } from '@assets';
import { Typography } from '@components';
import { FactoringCompany } from '@store/factoringCompany';
import { Box } from '@suid/material';
import { formatPhoneNumber } from '@utils/phoneNumberFormat';

import { factoringCompanyGridClasses as cls } from './classes';

export const FactoringCompanyContact = (data: FactoringCompany) => {
  return (
    <Box class="flex flex-col">
      <Typography variant="h1" class={cls.textBold}>
        {data.primaryContactName}
      </Typography>
      <Typography variant="h1" class={cls.text}>
        {formatPhoneNumber(data.primaryContactPhone1)}
      </Typography>
    </Box>
  );
};

export const FactoringCompanyStatus = (data: FactoringCompany) => {
  const statusIcon: { [key: string]: string } = {
    Active: checkIcon,
    Pending: Pending,
    Inactive: inactiveIcon,
  };

  return data.status ? (
    <img src={statusIcon[data.status]} alt="status" class="size-5"></img>
  ) : null;
};
