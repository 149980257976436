import { CheckboxInput, TextInput } from '@components/forms';
import { BasicModal } from '@components/Modal';
import { ToastType } from '@components/Toast';
import { createForm } from '@felte/solid';
import {
  ILoadBoardViewModel,
  PostMultipleResponseT,
  UnPostMultipleResponseT,
  loadBoardStore,
} from '@store/loadboard';
import { postMultiple, unPostMultiple } from '@store/loadboard/services';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@suid/material';
import { handleToast } from '@utils/utils';
import { ErrorsTable } from '@views/order/components/ErrorsTable';
import { ErrorT } from '@views/order/components/ErrorsTable/types';
import { cloneDeep } from 'lodash';
import { DateTime } from 'luxon';
import { Show, createEffect, createSignal } from 'solid-js';

type RichLoadT = ILoadBoardViewModel & {
  comment: string;
  checked: boolean;
  hasError: boolean;
};

type PropsT = {
  id: string;
  action: 'post' | 'unpost' | 'none';
  // onSave: (loads: unknown[]) => void;
  onCloseModal: (id: string) => void;
  prepopulatedComment: boolean;
  refreshGrid?: () => void;
  onUpdatePostings: (postings: ILoadBoardViewModel[]) => void;
};

export const PostUnPostLoadModal = (props: PropsT) => {
  const [loads, setLoads] = createSignal<RichLoadT[]>([]);
  const [errors, setErrors] = createSignal<ErrorT[]>([]);
  const [selectAll, setSelectAll] = createSignal(false);
  const [failedPostingIds, setFailedPostingIds] = createSignal<number[]>([]);
  const [count, setCount] = createSignal(0);
  const [loading, setIsLoading] = createSignal<boolean>(false);
  const form = createForm({
    initialValues: {
      loads: loads(),
    },
    onSubmit: async () => {
      // This setTimeout is used because, if it's not there, it breaks onSubmit. It does not seem
      // like an issue on our end, but it needs to be here. It sets the signal to true correctly,
      // but it still breaks the flow with the following error:
      // Uncaught TypeError: EventTarget.addEventListener: Argument 2 is not an object.
      setTimeout(() => {
        setIsLoading(true);
      }, 1);

      const toUpdate = form.data().loads.filter((l) => l.checked);
      let resp: PostMultipleResponseT | UnPostMultipleResponseT | undefined =
        undefined;

      if (props.action === 'post') {
        const postPayload = toUpdate.map((l) => {
          return {
            LoadId: l.loadId,
            Comment: l.comment,
          };
        });
        resp = await postMultiple(postPayload);

        if (resp.success) {
          handleToast(
            ToastType.Success,
            `${postPayload.length} loads posted successfully`,
          );
          props.refreshGrid?.();
          props.onCloseModal(props.id);
        }
      } else if (props.action === 'unpost') {
        const unPostPayload = toUpdate.map((l) => {
          return l.loadId;
        });
        resp = await unPostMultiple(unPostPayload);

        if (resp.success) {
          handleToast(
            ToastType.Success,
            `${unPostPayload.length} loads unposted successfully`,
          );
          props.refreshGrid?.();
          props.onCloseModal(props.id);
        }
      }
      if (
        resp !== undefined &&
        (resp.partialSuccess || !resp.success || (resp.errors ?? []).length > 0)
      ) {
        const successfulIds: { [key: string]: number } = {};
        if (resp.partialSuccess) {
          resp.postings.forEach((posting) => {
            if (!(resp.failedPostingIds as number[]).includes(posting.loadId)) {
              successfulIds[posting.loadNumber] = 1;
            }
          });
          const loadboardItems = cloneDeep(loadBoardStore.items);
          loadboardItems.forEach((l) => {
            if (successfulIds[l.loadNumber]) {
              l.postingId = successfulIds[l.loadNumber];
            }
          });
          props.onUpdatePostings(loadboardItems);
        }

        setFailedPostingIds(resp.failedPostingIds);

        if (resp.errors !== null) {
          setErrors(
            resp.errors.map((e) => {
              return {
                body: e.message,
              };
            }),
          );
        } else {
          handleToast(ToastType.Error, `Unable to ${props.action} loads`);
        }
      }

      setIsLoading(false);
    },
  });

  const resetForm = () => {
    form.reset();
    setCount(0);

    const { loads } = loadBoardStore.filteredItems.reduce(
      (acc, l) => {
        if (acc.count >= 25) {
          return acc;
        }

        const pd = l.pickDate;
        const date = Date.UTC(
          pd.getFullYear(),
          pd.getMonth(),
          pd.getDate(),
          0,
          0,
        );
        const nowDate = new Date().getUTCDate();

        if (
          (props.action === 'post' &&
            typeof l.postingId !== 'number' &&
            date > nowDate) ||
          (props.action === 'unpost' && typeof l.postingId === 'number')
        ) {
          const expanded = {
            ...l,
            comment: props.prepopulatedComment ? `${l.orderId}` : '',
            checked: false,
            hasError: false,
          };
          acc.loads.push(expanded);

          form.addField('loads', expanded, acc.count);
          acc.count++;
        }

        return acc;
      },
      { loads: [], count: 0 } as {
        loads: RichLoadT[];
        count: number;
      },
    );

    setFailedPostingIds([]);
    setErrors([]);
    setLoads(loads);
  };

  createEffect(() => {
    if (props.action === 'none') {
      return;
    }

    resetForm();

    if (props.action === 'unpost') {
      setSelectAll(true);
    } else {
      setSelectAll(false);
    }

    loads().forEach((_, i) => {
      form.setData(`loads.${i}`, (load: RichLoadT) => {
        load.checked = props.action === 'unpost';
        load.comment = props.prepopulatedComment ? `${load.orderId}` : '';
        return load;
      });
    });
  });

  const onSelectAll = (checked: boolean) => {
    setSelectAll(checked);
    const loadsData = loads();
    const updatedLoads = loadsData.reduce((acc, load) => {
      load.checked = checked;
      return [...acc, load];
    }, [] as RichLoadT[]);
    form.setData('loads', updatedLoads);
    setCount(() => {
      if (
        (props.action === 'post' && checked) ||
        (props.action === 'unpost' && !checked)
      ) {
        return loadsData.length;
      }
      return 0;
    });
  };

  return (
    <form ref={form.form}>
      <BasicModal
        title={`${props.action === 'post' ? 'Post' : 'Unpost'} Load(s)`}
        id={props.id}
        onSubmit={() => {
          if (!loading()) {
            form.handleSubmit();
          }
        }}
        onClose={() => {
          props.onCloseModal(props.id);
          form.resetField('loads');
          loads().forEach((l, i) => {
            form.setData(`loads.${i}`, l);
          });
          setLoads([]);
          setCount(0);
          setFailedPostingIds([]);
          setErrors([]);
          setSelectAll(false);
        }}
        width="100%"
        onSubmitLoading={loading()}
        onSubmitText={props.action.toUpperCase()}
        footerChild={
          <Box class="absolute" left="1rem">
            {props.action === 'post' ? 'Selected' : 'Unselected'} Loads:{' '}
            {Math.abs(count())}
          </Box>
        }
        preventCloseOnSubmit
        fullHeight
        showClose
      >
        <Show when={errors().length > 0}>
          <ErrorsTable
            errors={errors()}
            message={
              <span>
                Please see the below rows that failed to post, highlighted in
                red. The following loads were effected:
              </span>
            }
            overflowHeight="250px"
            showWarningIcon
            collapsible
            fullWidth
          />
        </Show>
        <Box class="flex mb-3">
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <CheckboxInput
                      inputProps={{ 'aria-label': 'Select All' }}
                      onChange={onSelectAll}
                      checked={selectAll()}
                      label=""
                    />
                  </TableCell>
                  <TableCell>Load Number</TableCell>
                  <TableCell>Customer</TableCell>
                  <TableCell>Equipment</TableCell>
                  <TableCell>Origin</TableCell>
                  <TableCell>Pickup</TableCell>
                  <TableCell>Destination</TableCell>
                  <TableCell>Dropoff</TableCell>
                  {props.action === 'post' && !props.prepopulatedComment ? (
                    <TableCell>Posting Comments</TableCell>
                  ) : undefined}
                </TableRow>
              </TableHead>
              <TableBody>
                {loads().map((l, i) => {
                  const hasError =
                    failedPostingIds().find((id) => id === l.loadId) !==
                    undefined;

                  return (
                    <TableRow
                      sx={{
                        '&:last-child td, &:last-child th': {
                          border: 0,
                        },
                        backgroundColor: hasError ? '#d32f2f14' : undefined,
                      }}
                    >
                      <TableCell>
                        <CheckboxInput
                          label=""
                          inputProps={{
                            'aria-label': `Checkbox for load ${l.loadNumber}`,
                          }}
                          name={`loads.${i}.checked`}
                          checked={form.data().loads[i].checked}
                          onChange={(checked) => {
                            form.setData(`loads.${i}`, (load: RichLoadT) => {
                              // eslint-disable-next-line no-console
                              console.log('load', load);
                              load.checked = checked;
                              return load;
                            });
                            setCount((prev) => {
                              if (checked) {
                                return prev + 1;
                              }

                              return prev - 1;
                            });
                          }}
                        />
                      </TableCell>
                      <TableCell>{l.loadNumber}</TableCell>
                      <TableCell>{l.customerName}</TableCell>
                      <TableCell>{l.equipment}</TableCell>
                      <TableCell>{l.origin}</TableCell>
                      <TableCell>
                        {DateTime.fromISO(l.pickDate.toISOString()).toFormat(
                          'MM/dd/yyyy',
                        )}
                      </TableCell>
                      <TableCell>{l.destination}</TableCell>
                      <TableCell>
                        {DateTime.fromISO(l.dropDate.toISOString()).toFormat(
                          'MM/dd/yyyy',
                        )}
                      </TableCell>
                      <Show
                        when={
                          props.action === 'post' && !props.prepopulatedComment
                        }
                      >
                        <TableCell>
                          <TextInput
                            label="Comment"
                            sxProps={{
                              input: {
                                width: '150px',
                                backgroundColor: '#fff',
                              },
                            }}
                            name={`loads.${i}.comment`}
                            onChange={(comment) => {
                              form.setData(`loads.${i}`, (load: RichLoadT) => {
                                load.comment = comment as string;
                                return load;
                              });
                            }}
                          />
                        </TableCell>
                      </Show>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </BasicModal>
    </form>
  );
};
