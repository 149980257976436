import {
  createSignal,
  createEffect,
  onMount,
  onCleanup,
  JSX,
  mergeProps,
} from 'solid-js';
import TrimbleMaps from '@trimblemaps/trimblemaps-js';
import { Box } from '@suid/material';
import { SxProps } from '@suid/system';
import { DateTime } from 'luxon';

import MapConfig from './mapConfig';

export interface Props {
  center?: [number, number];
  markerPositions?: Coordinate[];
  zoom?: number;
  height?: number | string;
  width?: number | string;
  style?: string;
  showCompass?: boolean;
  showZoom?: boolean;
  visualizePitch?: boolean;
  showScaleControl?: boolean;
  showFullscreenControl?: boolean;
  showGeolocateControl?: boolean;
  sx?: SxProps;
  coordinates: Coordinate[];
}

export interface Coordinate {
  lng: number;
  lat: number;
  city: string;
  state: string;
  locationTime: string;
}

const Map: (props: Props) => JSX.Element = (props) => {
  const mergedProps: Props = mergeProps(
    {
      center: [0, 0] as [number, number],
      zoom: 0,
      style: 'transportation',
      markerPositions: [],
      showCompass: true,
      showZoom: true,
      visualizePitch: true,
      showScaleControl: true,
      showFullscreenControl: true,
      showGeolocateControl: true,
    },
    props,
  );

  const [mapContainer, setMapContainer] = createSignal<HTMLDivElement | null>(
    null,
  );
  const [map, setMap] = createSignal<TrimbleMaps.Map | null>(null);
  const [mapRendered, setMapRendered] = createSignal<boolean>(false);
  let lastRoute: TrimbleMaps.Route | null = null;

  onMount(() => {
    TrimbleMaps.APIKey = MapConfig.PCMiler.PCMilerAPIKey;

    setMap(
      new TrimbleMaps.Map({
        container: mapContainer()!,
        style: mergedProps.style as
          | 'default'
          | 'transportation'
          | 'satellite'
          | 'terrain'
          | 'basic'
          | 'basic_dark'
          | 'datalight'
          | 'datadark'
          | 'transportation_dark'
          | 'light_accessible'
          | 'dark_accessible'
          | undefined,
        center: mergedProps.center,
        zoom: mergedProps.zoom,
      }),
    );

    map()!.on('load', () => {
      if (
        mergedProps.markerPositions &&
        mergedProps.markerPositions.length > 0
      ) {
        mergedProps.markerPositions.forEach((position) => {
          const popUp = new TrimbleMaps.Popup()
            .setLngLat(position)
            .setHTML(
              `${DateTime.fromISO(position.locationTime).toFormat(
                'MMMM dd, yyyy HH:mm ZZZZ',
              )} <br/> ${position.city},${position.state}`,
            )
            .addTo(map()!);

          new TrimbleMaps.Marker()
            .setLngLat(position)
            .setPopup(popUp)
            .addTo(map()!);
        });
      }
      if (
        mergedProps.showCompass === true ||
        mergedProps.showZoom === true ||
        mergedProps.visualizePitch === true
      ) {
        const navigationControl = new TrimbleMaps.NavigationControl({
          showCompass: true,
          showZoom: true,
          visualizePitch: true,
        });
        map()!.addControl(navigationControl, 'top-right');
      }

      if (mergedProps.showScaleControl === true) {
        map()!.addControl(new TrimbleMaps.ScaleControl({ unit: 'imperial' }));
      }

      if (mergedProps.showFullscreenControl === true) {
        map()!.addControl(new TrimbleMaps.FullscreenControl());
      }

      if (mergedProps.showGeolocateControl === true) {
        map()!.addControl(new TrimbleMaps.GeolocateControl());
      }

      setMapRendered(true);
    });
  });

  createEffect(() => {
    lastRoute?.remove();
    map()?.resize();
    if (
      Boolean(mergedProps.coordinates) &&
      mergedProps.coordinates[0].lat !== 0 &&
      mapRendered()
    ) {
      const myRoute = new TrimbleMaps.Route({
        stops: mergedProps.coordinates,
      });
      lastRoute = myRoute;
      myRoute.addTo(map()!);
    }
  });

  onCleanup(() => {
    map()?.remove();
  });

  return (
    <Box
      ref={setMapContainer}
      class="w-1/2 h-80vh border border-gray-300 overflow-hidden rounded-lg cursor-pointer"
      sx={{
        height: mergedProps.height,
        width: mergedProps.width,
        ...mergedProps.sx,
      }}
    >
      <link
        href="https://maps-sdk.trimblemaps.com/v3/trimblemaps-3.7.0.css"
        rel="stylesheet"
      ></link>
    </Box>
  );
};

export default Map;
