import { v3Client, v3ODataClient } from '@api/apiClient';
import { get } from 'lodash';

import {
  AddressBook,
  AddressBookModel,
  AddressBookResponse,
  NewAddressResponse,
} from './types';

export async function fetchAddressBookData(customerId: string) {
  try {
    const response: AddressBookResponse = await v3ODataClient.get(
      `CustomerAddresses?%24format=json&%24top=500&%24orderby=Name&%24filter=CustomerId+eq+${customerId}&%24count=true`,
    );
    if ('value' in response) {
      return get(response, 'value');
    }
    return [];
  } catch (error: unknown) {
    throw new Error(`Failed to fetch pricing Data: ${error}`);
  }
}

export async function postAddress(payloads: AddressBook) {
  try {
    const response: NewAddressResponse = await v3Client.post(
      'CustomerAddressBook',
      payloads,
    );
    return response;
  } catch (error: unknown) {
    throw new Error(`Failed to add address: ${error}`);
  }
}

export async function editAddress(payload: AddressBook) {
  try {
    const response: NewAddressResponse = await v3Client.put(
      `CustomerAddressBook/${payload.id}`,
      payload,
    );
    return response;
  } catch (error: unknown) {
    throw new Error(`Failed to add address: ${error}`);
  }
}

export async function deleteAddressBook(addressId: string) {
  try {
    const res: { success: boolean } = await v3Client.delete(
      `CustomerAddressBook/${addressId}`,
    );
    return res.success;
  } catch (error: unknown) {
    throw new Error(`Failed to delete address: ${error}`);
  }
}
export async function fetchAddressBookById(addressId: string) {
  try {
    const res: AddressBookModel = await v3Client.get(
      `CustomerAddressBook/${addressId}`,
    );
    return res;
  } catch (error: unknown) {
    throw new Error(`Failed to delete address: ${error}`);
  }
}
