import { BasicModal } from '@components';
import {
  SavedSearchT,
  deleteSavedSearch,
  loadBoardStore,
} from '@store/loadboard';
import { deleteFilter } from '@store/loadboard/services';
import DeleteIcon from '@suid/icons-material/Delete';
import {
  Box,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@suid/material';
import { Component, For } from 'solid-js';

type PropsT = {
  id: string;
  onCloseModal: (label: string) => void;
};

export const ManageModal: Component<PropsT> = (props: PropsT) => {
  const onDeleteFilter = async (search: SavedSearchT) => {
    const success = await deleteFilter(search);

    if (success) {
      deleteSavedSearch(search);
    }
  };

  return (
    <BasicModal
      title="My Saved Templates"
      id={props.id}
      onClose={() => props.onCloseModal(props.id)}
      width="600px"
      footer={false}
      showClose={true}
    >
      <Box
        sx={{
          height: '300px',
          overflowY: 'auto',
        }}
        class="flex mb-3"
      >
        <TableContainer component={Paper}>
          <Table>
            <TableBody>
              <For each={loadBoardStore.filters}>
                {(search) => {
                  return (
                    <TableRow>
                      <TableCell>{search.name}</TableCell>
                      <TableCell sx={{ width: '60px' }}>
                        <IconButton onClick={() => onDeleteFilter(search)}>
                          <DeleteIcon fontSize="small" />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                }}
              </For>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </BasicModal>
  );
};
