import { apiClient, falveyClient, v3Client } from '@api/apiClient';
import { ToastType } from '@components';
import {
  FalveyQuotesPayload,
  LTLStoreQuoteResponse,
  QuoteSpecifics,
} from '@store/ltl/types';
import { Accessorial, LoadEditViewModel } from '@store/orders/v3Types';
import { handleToast } from '@utils/utils';

import {
  GetCalculationsFromLoadItemsApiResponse,
  GetQuotesApiResponse,
  LTLRateQuoteRequest,
  SavedQuoteInfo,
  TotalLinearFeet,
} from './v3types';

export async function getSavedQuotes() {
  const response = await v3Client.get('/ltl/savedquotes');
  const values = response as unknown as { data: SavedQuoteInfo[] };
  return values.data;
}

export async function getLTLAccessorials() {
  const response = await apiClient.get('/order/getAccessorial');
  return response.value as unknown as Accessorial[];
}

export async function getQuotes(request: LTLRateQuoteRequest) {
  try {
    const response: GetQuotesApiResponse = await v3Client.post(
      '/ltl/getPricing',
      request,
    );
    if (Boolean(response.success)) return response.data;
    handleToast(ToastType.Error, response.message.join('\n'));
    return false;
  } catch (error: unknown) {
    if (error instanceof Error) {
      handleToast(ToastType.Error, 'Failed to get Quote');
    }
    return false;
  }
}

export async function saveTempQuote(request: QuoteSpecifics) {
  try {
    const response = await apiClient.post<LTLStoreQuoteResponse>(
      '/ltl/StoreQuote',
      request,
    );
    if ('value' in response) {
      return response.value;
    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      handleToast(ToastType.Error, 'Failed to Save Temp Quote');
    }
  }
}

export async function getTempQuote(request: string) {
  try {
    const response = await apiClient.get<QuoteSpecifics>(
      `/ltl/getQuote/${request}`,
    );
    if ('value' in response) {
      return response.value;
    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      handleToast(ToastType.Error, 'Failed to Load Quote Information');
      throw new Error(`Failed to Load Quote Information: ${error.message}`);
    }
  }
}

export async function getDefaultAddress(customerId: number) {
  try {
    const response = await v3Client.get(
      `/customerAddressBook/getDefaultAddress?customerId=${customerId}`,
    );
    return response;
  } catch (error: unknown) {
    if (error instanceof Error) {
      handleToast(ToastType.Error, 'Failed to get default address');
    }
    throw new Error(`Failed to get default address: ${error}`);
  }
}

export async function GetCalculationsFromLoadItems(request: TotalLinearFeet) {
  try {
    const response: GetCalculationsFromLoadItemsApiResponse =
      await v3Client.post('/LTL/GetCalculationsFromLoadItems', request);
    if (Boolean(response.success)) return response.data;
    throw new Error('Failed to calculate total linear feet');
  } catch (error: unknown) {
    if (error instanceof Error) {
    }
    throw new Error(`Failed to calculate total linear feet: ${error}`);
  }
}

export async function markSaveQuoteRemoved(id: string) {
  try {
    const response: {
      success: boolean;
    } = await v3Client.post(`/LTL/MarkQuoteRemoved/${id}`, { id });
    return response.success;
  } catch (error: unknown) {
    if (error instanceof Error) {
      handleToast(ToastType.Error, 'Failed to delete Quote');
    }
    throw new Error(`Failed to get Quote: ${error}`);
  }
}

export async function getFalveyExcludedtItems() {
  try {
    const response = await falveyClient.get('FalveyInsurance/getexcludeditems');
    return response;
  } catch (error: unknown) {
    if (error instanceof Error) {
      handleToast(ToastType.Error, 'Failed to get excluded items');
    }
    throw new Error(`Failed to get excluded items: ${error}`);
  }
}

export const getFalveyPricingQuoteData = async (
  payload: FalveyQuotesPayload,
  hideToastr?: boolean,
) => {
  try {
    const response = await falveyClient.post(
      'falveyInsurance/getinsurancepricingquote',
      payload,
    );
    Boolean(hideToastr) &&
      handleToast(ToastType.Success, 'Get Falvey pricing quotes successfully');
    if ('value' in response) {
      return response.value;
    }
  } catch (error: unknown) {
    handleToast(ToastType.Error, 'Failed to get Falvey pricing quotes');
    throw new Error(`'Failed to get Falvey pricing quotes:  ${error}`);
  }
};
export async function LtlShipmentDataUpdate(request: LoadEditViewModel) {
  try {
    const response: {
      success: boolean;
      message: string;
    } = await v3Client.put('/LTL/UpdateLtlShipmentData', request);
    return response.success;
  } catch (error: unknown) {
    if (error instanceof Error) {
    }
    throw new Error(`Failed to update LTL shipmentdata: ${error}`);
  }
}
