﻿import { TempQuoteModel } from '@store/ltl/types';
import {
  customerStore,
  ILoadEditViewModel,
  IOrderViewModel,
  LastUsedTopStopModel,
  LineItemViewModel,
  OperationTypeV4,
  OrderLineItem,
  PickUpDropOffItem,
} from '@store/orders';
import { BuildOrderFromQuote } from '@utils/ltl/LTLOrderBuilder';
import { printLog } from '@utils/utils';
import { DateTime } from 'luxon';

export const mergeOrderQuote = (
  quote: TempQuoteModel,
  existingOrder: IOrderViewModel,
): IOrderViewModel => {
  const newOrderFromQuote = BuildOrderFromQuote(quote);
  const mergeOrder = {
    id: existingOrder.id,
    customerId: customerStore.customer.id,
    operationType:
      existingOrder.id <= 0
        ? 'Insert'
        : existingOrder.customerId === customerStore.customer.id
          ? ('None' as OperationTypeV4)
          : ('Update' as OperationTypeV4),
    loads: mergeLoads(newOrderFromQuote.loads, existingOrder.loads),
    lineItems: mergeOrderLineItems(
      newOrderFromQuote.lineItems,
      existingOrder.lineItems,
    ),
    payments: existingOrder.payments,
    assignedId: existingOrder.assignedId,
    chargeCreditCardFee: existingOrder.chargeCreditCardFee,
    customerCredit: existingOrder.customerCredit,
    billingHold: existingOrder.billingHold,
    agingOut: existingOrder.agingOut,
    adjustments: existingOrder.adjustments,
    availableServices: existingOrder.availableServices,
    editable: existingOrder.editable,
    synched: existingOrder.synched,
    private: existingOrder.private,
    edi: existingOrder.edi,
    trackLoads: existingOrder.trackLoads,
    show214: existingOrder.show214,
    daysPastDue: existingOrder.daysPastDue,
    canVoidOrder: existingOrder.canVoidOrder,
    createdDate: existingOrder.createdDate,
    createdBy: existingOrder.createdBy,
    isDeleted: existingOrder.isDeleted,
    loadInformation: existingOrder.loadInformation,
    hideCustomerName: existingOrder.hideCustomerName,
  };
  return mergeOrder;
};

//handle order specific merging here
const mergeOrderLineItems = (
  quotedLineItems: OrderLineItem[],
  existingLineItems: OrderLineItem[],
) => {
  const originalLineItems = existingLineItems.map((x) => {
    return { ...x, operationType: 'Delete' } as OrderLineItem;
  });
  const newLines = [...quotedLineItems, ...originalLineItems];
  return newLines;
};

//handle load specific merging here
const mergeLoads = (
  quotedLoads: ILoadEditViewModel[],
  existingLoads: ILoadEditViewModel[],
) => {
  //Add logic here to associate each load with the one being quoted based on ID, will need to update LTLOrderBuilder to guard against
  // any potential issues with this
  const requotedLoad = existingLoads.find((x) => x.id == quotedLoads[0].id);
  if (requotedLoad) {
    const mergedLoad = mergeLoad(quotedLoads[0], requotedLoad);
    return [
      mergedLoad,
      ...existingLoads.filter((x) => x.id !== quotedLoads[0].id),
    ];
  }
  return existingLoads;
};

const mergeLoad = (
  quotedLoad: ILoadEditViewModel,
  existingLoad: ILoadEditViewModel,
) => {
  const newRequotedLoad = {
    ...existingLoad,
    items: getQuoteItems(existingLoad, quotedLoad),
    operationType: existingLoad.id <= 0 ? 'Insert' : 'Update',
    status: 'Assigned',
    ltlquoteId: quotedLoad.ltlQuote?.id ?? existingLoad.ltlquoteId,
    ltlQuote: {
      ...quotedLoad.ltlQuote,
      operationType: 'Insert',
      quoteItems: quotedLoad.ltlQuote?.ltlQuoteLoadItems,
    },
    quoteId: quotedLoad.quoteId,
    lineItems: mergeLineItems(quotedLoad.lineItems, existingLoad.lineItems),
    carrierId: quotedLoad.carrierId,
    stops: existingLoad.stops?.map((x) => {
      const times = getStopDates(quotedLoad.stops, x);

      return {
        ...x,
        driverInTime: times.driverInTime?.toString(), // for some reason, we cannot spread these in and we also have to do a toString for this to work
        driverOutTime: times.driverOutTime?.toString(), // for some reason, we cannot spread these in and we also have to do a toString for this to work
        stopDateTime: times.stopDateTime?.toString(), // for some reason, we cannot spread these in and we also have to do a toString for this to work
        operationType: x.id <= 0 ? 'Insert' : 'Update',
      };
    }),
  } as ILoadEditViewModel;
  return newRequotedLoad;
};

const getStopDates = (
  quotedStops: LastUsedTopStopModel[] | undefined,
  existingStop: LastUsedTopStopModel,
) => {
  if (quotedStops === undefined) {
    return {
      driverInTime: existingStop.driverInTime,
      driverOutTime: existingStop.driverOutTime,
      stopDateTime: existingStop.stopDateTime,
    };
  }

  const quotedStop = quotedStops.find((x) => x.pickUp === existingStop.pickUp);

  if (quotedStop === undefined) {
    return {
      driverInTime: existingStop.driverInTime,
      driverOutTime: existingStop.driverOutTime,
      stopDateTime: existingStop.stopDateTime,
    };
  }

  const zipChanged = quotedStop.zip !== existingStop.zip;

  if (zipChanged) {
    return {
      driverInTime: quotedStop.driverInTime,
      driverOutTime: quotedStop.driverOutTime,
      stopDateTime: quotedStop.stopDateTime,
    };
  }

  const existingDriverInTime =
    existingStop.driverInTime !== undefined &&
    existingStop.driverInTime !== null
      ? DateTime.fromISO(existingStop.driverInTime, { zone: 'utc' })
      : undefined;
  let quotedDriverInTime: DateTime | undefined = undefined;

  if (
    quotedStop.driverInTime !== undefined &&
    quotedStop.driverInTime !== null
  ) {
    if (existingDriverInTime !== undefined) {
      const q = DateTime.fromISO(quotedStop.driverInTime);
      quotedDriverInTime = q
        .toUTC()
        .set({
          hour: existingDriverInTime.hour,
          minute: existingDriverInTime.minute,
        })
        .toLocal()
        .set({
          day: q.day,
          month: q.month,
          year: q.year,
        });
    } else {
      quotedDriverInTime = DateTime.fromISO(quotedStop.driverInTime, {
        zone: 'utc',
      });
    }
  }

  const existingDriverOutTime =
    existingStop.driverOutTime !== undefined &&
    existingStop.driverOutTime !== null
      ? DateTime.fromISO(existingStop.driverOutTime, { zone: 'utc' })
      : undefined;

  let quotedDriverOutTime: DateTime | undefined = undefined;

  if (
    quotedStop.driverOutTime !== undefined &&
    quotedStop.driverOutTime !== null
  ) {
    if (existingDriverOutTime !== undefined) {
      const q = DateTime.fromISO(quotedStop.driverOutTime);
      quotedDriverOutTime = q
        .toUTC()
        .set({
          hour: existingDriverOutTime.hour,
          minute: existingDriverOutTime.minute,
        })
        .toLocal()
        .set({
          day: q.day,
          month: q.month,
          year: q.year,
        });
    } else {
      quotedDriverOutTime = DateTime.fromISO(quotedStop.driverOutTime, {
        zone: 'utc',
      });
    }
  }

  return {
    driverInTime: quotedDriverInTime,
    driverOutTime: quotedDriverOutTime,
    stopDateTime: quotedStop.stopDateTime,
  };
};

const getQuoteItems = (
  existingLoad: ILoadEditViewModel,
  quotedLoad: ILoadEditViewModel,
) => {
  printLog('existingLoad', existingLoad);
  printLog('quotedLoad', quotedLoad);
  const dropOffId = existingLoad.stops?.find((x) => !x.pickUp)?.id;
  const pickupId = existingLoad.stops?.find((x) => x.pickUp)?.id;
  if (Number(dropOffId) > 0 && Number(pickupId)) {
    const quotedItems = quotedLoad.items?.map((x, i) => {
      return {
        ...x,
        id: -(i + 1),
        item: x.type ?? x.item,
        type: x.type ?? x.item,
        dropOffId: dropOffId,
        pickUpId: pickupId,
        operationType: 'Insert',
      };
    }) as PickUpDropOffItem[];
    const existingItems = existingLoad.items?.map((x) => {
      return {
        ...x,
        operationType: 'Delete',
      };
    }) as PickUpDropOffItem[];
    printLog('existingItems', existingItems);
    return [...quotedItems, ...existingItems];
  }
  return quotedLoad.items;
};

const mergeLineItems = (
  quotedLineItems?: LineItemViewModel[],
  existingLineItems?: LineItemViewModel[],
) => {
  const originalLineItems = existingLineItems?.map((x) => {
    return { ...x, operationType: 'Delete' } as LineItemViewModel;
  });
  if (originalLineItems && quotedLineItems) {
    return [...quotedLineItems, ...originalLineItems];
  }
  if (!originalLineItems && quotedLineItems) {
    return quotedLineItems;
  }
  return existingLineItems;
};
