import { RowClassParams } from 'ag-grid-community';

const getFlagStyles = (
  flagType: string | undefined,
  rowBgColor: { background?: string },
) => {
  switch (flagType) {
    case 'Red Flag':
      rowBgColor = {
        background: `linear-gradient(to bottom, rgba(255, 88, 86,1), ${rowBgColor.background} 15%, ${rowBgColor.background} 85%, rgba(255, 88, 86,1) 100%)`,
      };
      break;

    case 'Blue Flag':
      rowBgColor = {
        background: `linear-gradient(to bottom, rgba(41,137,216,1), ${rowBgColor.background} 15%, ${rowBgColor.background} 85%, rgba(41,137,216,1) 100%)`,
      };
      break;

    case 'Green Flag':
      rowBgColor = {
        background: `linear-gradient(to bottom, rgba(6, 138, 6, 1), ${rowBgColor.background} 15%, ${rowBgColor.background} 85%, rgba(6, 138, 6, 1) 100%)`,
      };
      break;

    case 'Yellow Flag':
      rowBgColor = {
        background: `linear-gradient(to bottom, rgb(218, 255, 16), ${rowBgColor.background} 15%, ${rowBgColor.background} 85%, rgb(218, 255, 16) 100%)`,
      };
      break;

    case 'Orange Flag':
      rowBgColor = {
        background: `linear-gradient(to bottom, rgb(255, 178, 56), ${rowBgColor.background} 15%, ${rowBgColor.background} 85%, rgb(255, 178, 56) 100%)`,
      };
      break;
  }
  return rowBgColor;
};

//This method is utilized in the loadboard grid to set the background color of the row based on the status of the load,
//if it needs to be refactored that is ok, but make sure it still works on the loadboard or create a new one.
export const getRowStyle = (params: RowClassParams) => {
  let rowBgColor: { background?: string } = {};

  const status =
    (params.data as { status: string } | undefined)?.status ??
    (params.data as { loadStatus: string } | undefined)?.loadStatus ??
    '';

  switch (status) {
    case 'Assigned':
      rowBgColor = { background: '#FFFFC1' };
      break;

    case 'Unassigned':
      rowBgColor = { background: '#FFC1C1' };
      break;

    case 'Dispatched':
      rowBgColor = { background: '#AAD9ED' };
      break;

    case 'Loaded':
      rowBgColor = { background: '#9ACC9A' };
      break;

    case 'At Shipper':
      rowBgColor = { background: '#AEA3FF' };
      break;

    case 'At Receiver':
      rowBgColor = { background: '#FFFFFF' };
      break;

    case 'Out On Delivery':
      rowBgColor = { background: '#F7BF51' };
      break;

    case 'In Transit':
      rowBgColor = { background: '#67E667' };
      break;

    case 'On Hold':
      rowBgColor = { background: '#F97C6B' };
      break;

    case 'Tendered':
      rowBgColor = { background: '#c1fcff' };
      break;

    case 'Empty':
      rowBgColor = { background: '#FFF' };
      break;

    case 'Can Get':
      rowBgColor = { background: '#E4E6EF' };
      break;
  }
  const flagBgColor = getFlagStyles(
    (params.data as { flagType: string } | undefined)?.flagType,
    rowBgColor,
  );
  rowBgColor = {
    ...rowBgColor,
    ...flagBgColor,
  };
  return rowBgColor;
};
