import { CardPlaceholder, Typography } from '@components';
import { getFalveyExcludedtItems } from '@store/ltl/services';
import { Box, Grid } from '@suid/material';
import { get, isEmpty } from 'lodash';
import { createSignal, onMount, Show } from 'solid-js';

export const ExclusionList = () => {
  const [excludedItems, setExcludedItems] = createSignal<string[]>([]);
  const [loading, setLoading] = createSignal<boolean>(false);

  onMount(async () => {
    setLoading(true);
    const items = await getFalveyExcludedtItems();
    setLoading(false);
    if ('value' in items && !isEmpty(items.value)) {
      const excludedData = get(items, 'value') as Array<{
        id: string;
        description: string;
      }>;
      setExcludedItems(
        excludedData.map(
          (item: { id: string; description: string }) => item.description,
        ),
      );
    }
  });

  return (
    <Box class="p-4 !w-[1000px]">
      <Show when={!loading()} fallback={<CardPlaceholder />}>
        <Grid container gap={2}>
          <Grid item sm={3}>
            <Typography
              variant="h6"
              class="!text-xl !font-medium !text-[#123B50] !leading-8"
            >
              Falvey Exclusion List
            </Typography>
          </Grid>
          <Grid item sm={8.5}>
            <Typography
              variant="h6"
              class="!text-sm font-medium text-[#000000] opacity-60"
            >
              Please note that the following items are documents that represent
              a cash value: Account, Bill, Deed, Document, Evidence of Debt,
              Manuscript, Note, Securities and Other Negotiable Papers
            </Typography>
          </Grid>
        </Grid>
        <Box class="mt-2">
          <Grid container spacing={2} class="!p-0 !m-0">
            {excludedItems().map((item) => (
              <Grid item xs={3} class="!pt-1 !pl-2">
                <div class="flex break-word text-sm font-normal before:content-['•'] before:mr-2 leading-[1.2]">
                  {item.charAt(0).toUpperCase() + item.slice(1)}
                </div>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Show>
    </Box>
  );
};
