import { v3Client, v3FileUploadClient } from '@api/apiClient';
import { CommonFileUploadFiles } from '@components/FileUpload/CommonFileUpload';
import { handleToast } from '@utils/utils';
import { ToastType } from '@components';

import {
  Group,
  OfficeDetailType,
  RecruitersListType,
  SuccessType,
  UserCreateType,
} from './types';

export const fetchOfficeDetailApi = async (id: string) => {
  try {
    const response: unknown = await v3Client.get(`/office/${id}`);
    return response as OfficeDetailType;
  } catch (error) {
    throw new Error('Failed to fetch office detail');
  }
};

export const fetchRecruitersListApi = async () => {
  try {
    const response: unknown = await v3Client.get('/office/recruiters');
    return response as RecruitersListType[];
  } catch (error) {
    throw new Error('Failed to fetch recruiters list');
  }
};

export const fetchDocumentApi = async (id: string) => {
  try {
    const response: unknown = await v3Client.get(`/Document/Office/${id}`);
    return response as CommonFileUploadFiles[];
  } catch (error) {
    throw new Error('Failed to fetch document list');
  }
};

export const uploadOfficeDetailDocument = async (
  officeId: number,
  file: File,
) => {
  try {
    const formData = new FormData();
    formData.append(`${file.name}`, file);
    const res = await v3FileUploadClient.post(
      `/Document/Office/${officeId}`,
      formData,
    );
    return res.data as unknown as {
      url: string;
    };
  } catch (error: unknown) {
    throw new Error('Failed to upload office document');
  }
};

export const deleteOfficeDetailsDocument = async (payload: FormData) => {
  try {
    const urlEncodedData = new URLSearchParams();
    for (const pair of payload.entries()) {
      urlEncodedData.append(pair[0], String(pair[1]));
    }
    await v3FileUploadClient.post('/Document/DeleteFile', urlEncodedData, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });
  } catch (error: unknown) {
    throw new Error('Failed to delete office document');
  }
};

export const deleteOfficeDetailsGroupApi = async (id: number) => {
  try {
    await v3Client.delete(`/Group/${id}`);
  } catch (error) {
    throw new Error('Deleting office Group failed');
  }
};

export const addEditGroupApi = async (payload: Group) => {
  try {
    if (Boolean(payload.id)) {
      return await v3Client.put(`/Group/${payload.id}`, payload);
    }
    return await v3Client.post('/Group', payload);
  } catch (error) {
    throw new Error('Adding new Group failed');
  }
};

export const createUserApi = async (data: UserCreateType) => {
  try {
    const response: unknown = await v3Client.post('/User', data);
    return response as SuccessType;
  } catch (error: unknown) {
    throw new Error('Failed to create user');
  }
};

export const removeRoleApi = async (id: number, role: string) => {
  try {
    const response: unknown = await v3Client.get(
      `/User/RemoveRole/${id}/${role}?id=${id}&role=${role}`,
    );
    return response as SuccessType;
  } catch (error) {
    throw new Error('Failed to remove role from user');
  }
};

export const addRoleApi = async (id: number, role: string) => {
  try {
    const response: unknown = await v3Client.get(
      `/User/AddRole/${id}/${role}?id=${id}&role=${role}`,
    );
    return response as SuccessType;
  } catch (error) {
    throw new Error('Failed to add role to user');
  }
};

export const activeUserApi = async (id: number, isActive: boolean) => {
  try {
    const response: unknown = await v3Client.get(
      `/User/Active/${id}/${isActive}?id=${id}&active=${isActive}`,
    );
    return response as { id: number; active: boolean };
  } catch (error) {
    throw new Error('Failed to update user status');
  }
};

export const saveOfficeDetailApi = async (
  payload: OfficeDetailType,
  create = false,
) => {
  try {
    let data;
    if (create) {
      data = await v3Client.post('/Office', payload);
    } else {
      data = await v3Client.put(`/Office/${payload.id}`, payload);
    }
    return data as unknown as OfficeDetailType;
  } catch (error) {
    handleToast(ToastType.Error, 'Failed to update the office detail api');
  }
};
