import { Notification } from '@components';
import { fetchAllNeedPaperwork } from '@store/Global/service';
import { NeedPaperworkTypes } from '@store/Global/types';
import { createSignal, onMount } from 'solid-js';
import { Box } from '@suid/material';
import AgGridSolid, { AgGridSolidRef } from 'ag-grid-solid';
import logo from '@assets/logo.svg';
import { ColDef, CellClickedEvent } from 'ag-grid-community';
import { dateFormatter } from '@store/loadboard';
import { userHasRole } from '@utils/utils';
import { userRoles } from '@constants/common';
import { useNavigate } from '@solidjs/router';

const imageTag = `<img src=${logo} alt="" />`;

const cellStyle = {
  padding: '10px',
  'word-break': 'break-word',
  'white-space': 'normal',
  'line-height': '1.6 ',
};

const ColumnDefs: ColDef[] = [
  {
    field: 'orderId',
    headerName: 'Order #',
    cellStyle: { ...cellStyle, cursor: 'pointer' },
    filter: null,
    minWidth: 120,
    colId: 'orderId',
  },
  {
    field: 'requestedBy',
    headerName: 'RequestedBy',
    cellStyle: cellStyle,
    filter: null,
    minWidth: 120,
  },
  {
    field: 'agent',
    headerName: 'Agent',
    cellStyle: cellStyle,
    filter: null,
    sortable: false,
    minWidth: 120,
  },

  {
    field: 'flagDate',
    headerName: 'Date',
    cellStyle: cellStyle,
    valueFormatter: dateFormatter,
    filter: null,
    minWidth: 120,
  },
  {
    field: 'customer',
    headerName: 'Customer',
    cellStyle: cellStyle,
    filter: null,
    minWidth: 120,
    hide: userHasRole(userRoles.CAPACITY),
  },
  {
    field: 'carrier',
    headerName: 'Carrier',
    cellStyle: cellStyle,
    filter: null,
    minWidth: 120,
  },
];

const NeedPaperwork = () => {
  const navigate = useNavigate();
  let gridRef: AgGridSolidRef | undefined;
  const [needPaperwork, setNeedPaperwork] = createSignal<NeedPaperworkTypes[]>(
    [],
  );

  onMount(async () => {
    gridRef?.api.showLoadingOverlay();
    const response = await fetchAllNeedPaperwork();
    if (response) setNeedPaperwork(response);
    gridRef?.api.hideOverlay();
  });

  return (
    <Box class="w-[800px] max-w-full h-[500px] overflow-auto">
      <Notification
        type="info"
        text={
          'These Orders/Loads Need Paperwork. Please do what you can to obtain them so we can finish processing them.'
        }
        sxProps="bg-[#FFFFFF]"
      />

      <Box class="ag-theme-alpine !flex-1 !h-[400px] mt-4">
        <AgGridSolid
          ref={gridRef}
          columnDefs={ColumnDefs}
          rowData={needPaperwork()}
          gridOptions={{
            defaultColDef: {
              flex: 1,
              filter: true,
            },
            rowHeight: 50,
          }}
          overlayLoadingTemplate={imageTag}
          overlayNoRowsTemplate={imageTag}
          suppressPaginationPanel={false}
          pagination={true}
          paginationPageSize={50}
          onCellClicked={(e: CellClickedEvent) => {
            if (e.colDef.colId === 'orderId') {
              const orderId = (e.data as { orderId: number }).orderId;
              if (orderId) {
                navigate(`/order/Details/${orderId}`, { replace: true });
              }
            }
          }}
        />
      </Box>
    </Box>
  );
};

export default NeedPaperwork;
