import logo from '@assets/atg-loading-new-logo-23.gif';
import { Grid as MuiGrid, Stack } from '@suid/material';
import AgGridSolid, { AgGridSolidRef } from 'ag-grid-solid';
import { GridOptions } from 'ag-grid-community';
import '@components/Grid/AgGridStyles.css';
import {
  editHistoryStore,
  fetchEditHistory,
  setEditHistoryStore,
} from '@store/editHistory/store';
import { editHistoryColumns } from '@store/editHistory';

export const EditHistoryGrid = (props: { orderId: string }) => {
  const imageTag = `<img src=${logo} alt="" />`;
  const gridOptions: GridOptions = {
    suppressScrollOnNewData: true,
    groupDefaultExpanded: 1,
    groupDisplayType: 'groupRows',
    defaultColDef: {
      flex: 1,
    },
    suppressDragLeaveHidesColumns: true,
    sideBar: {
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
        },
        {
          id: 'filters',
          labelDefault: 'Filters',
          labelKey: 'filters',
          iconKey: 'filter',
          toolPanel: 'agFiltersToolPanel',
        },
      ],
      defaultToolPanel: '',
    },
  };
  let gridRef: AgGridSolidRef | undefined;
  return (
    <Stack class="h-[100%]">
      <MuiGrid class="ag-theme-alpine !flex-1">
        <AgGridSolid
          ref={gridRef}
          class="no-cell-grid"
          columnDefs={editHistoryColumns}
          rowData={editHistoryStore.items}
          gridOptions={gridOptions}
          rowGroupPanelShow={'always'}
          overlayLoadingTemplate={imageTag}
          onGridReady={async () => {
            gridRef?.api.showLoadingOverlay();
            await fetchEditHistory(props.orderId);
            setEditHistoryStore('isLoading', false);
            gridRef?.api.hideOverlay();
          }}
        />
      </MuiGrid>
    </Stack>
  );
};
