import { BasicModal, BasicTable, Map } from '@components';
import { Column } from '@components/BasicTable/BasicTable';
import { Coordinate } from '@components/Map/Map';
import { useParams } from '@solidjs/router';
import {
  ILatLon,
  IStops,
  fetchCoordinatesFromZip,
  fetchLoadTenderDetails,
  loadTenderStore,
  orderStore,
} from '@store/orders';
import ArrowCircleDown from '@suid/icons-material/ArrowCircleDown';
import ArrowCircleUpIcon from '@suid/icons-material/ArrowCircleUp';
import InsertInvitationIcon from '@suid/icons-material/InsertInvitation';
import { Box } from '@suid/material';
import { formatDate } from '@utils/dateFormat';
import { EDI_LOAD_TENDER_ID } from '@views/order/constants';
import { createEffect, createSignal, onMount } from 'solid-js';

import { IZip } from '../../carrier';
import classes from './classes';

export const EDILoadTender = () => {
  const params = useParams();
  const [coordinates, setCoordinates] = createSignal<Coordinate[]>([]);

  onMount(async () => {
    if (Boolean(orderStore.order.loadTenderId)) {
      await fetchLoadTenderDetails(params.id);
    }
  });

  const args = {
    id: EDI_LOAD_TENDER_ID,
    maxWidth: '1605px',
    width: '90vw',
    height: '740px',
    title: 'Load Tender',
    modalStyles: {
      backgroundColor: 'white',
      overflow: 'hidden',
    },
    footer: false,
    footerContainerClass: 'hidden',
  };

  const columns: Column<IStops>[] = [
    {
      name: 'Date',
      key: 'Date',
      minWidth: 176,
      maxWidth: 176,
      renderCell: (rowData) => (
        <div class={classes.dateContainer}>
          {rowData.dropOff && <ArrowCircleUpIcon sx={{ color: '#016FA1' }} />}
          {rowData.pickUp && <ArrowCircleDown sx={{ color: '#016FA1' }} />}
          <span>
            {formatDate({
              date: rowData.requestedDate
                ? new Date(rowData.requestedDate)
                : new Date(),
              formatString: 'MMM dd yyyy',
            })}
          </span>
          <InsertInvitationIcon sx={{ color: '#016FA1' }} />
        </div>
      ),
    },
    {
      name: 'Time',
      key: 'Time',
      minWidth: 118,
      maxWidth: 118,
      renderCell: (rowData) => (
        <div class={classes.timeContainer}>
          <span>{rowData.requestedTime} </span>
        </div>
      ),
    },
    {
      name: 'Location',
      key: 'Location',
      renderCell: (rowData) => (
        <div class={classes.locationContainer}>
          <div class={classes.marginBottom24}>
            <div>{rowData.address1} </div> <div> {rowData.address2}</div>{' '}
            <div>
              {rowData.city}, {rowData.state}
            </div>{' '}
            <div>
              {rowData.country} {rowData.zip}
            </div>{' '}
          </div>
          <div>
            <div>{rowData.contact.name}</div>{' '}
            <div>{rowData.contact.phone1}</div>
          </div>
        </div>
      ),
    },
    {
      name: 'Weight',
      key: 'Weight',
      renderCell: (rowData) => (
        <div class={classes.timeContainer}>
          <span>{rowData.weight} </span>
        </div>
      ),
    },
    {
      name: 'Items',
      key: 'Items',
      renderCell: (rowData) => (
        <div class={classes.itemsContainer}>
          <div>{rowData.items}</div>
        </div>
      ),
    },
    {
      name: 'Description',
      key: 'Description',
      renderCell: (rowData) => (
        <div class={classes.descriptionContainer}>{rowData.note}</div>
      ),
    },
  ];

  createEffect(async () => {
    const stops = loadTenderStore.loadTender?.stops;
    if (stops) {
      const zipArray: IZip[] = [];
      const latlngArray: Coordinate[] = [];
      stops.forEach((stop) => {
        zipArray.push({ Address: { Zip: stop.zip } });
      });
      const locationArray = await fetchCoordinatesFromZip(zipArray);
      locationArray.forEach((location: ILatLon) => {
        latlngArray.push({
          lat: Number(location.Coords.Lat),
          lng: Number(location.Coords.Lon),
        });
      });
      setCoordinates(latlngArray);
    }
  });

  return (
    <BasicModal showClose={true} {...args} class="!border-1">
      <div>
        <Box class={classes.flexContainer}>
          <div class={classes.leftContainer}>
            <div class={classes.headingContainer}>
              {loadTenderStore.loadTender?.customer} ( Shipment Id:{' '}
              {loadTenderStore.loadTender?.shipmentId})
            </div>
            <div class={classes.payContainer}>
              Will Pay: ${loadTenderStore.loadTender?.rate}
            </div>
            <div class={classes.equipmentContainer}>
              <span class={classes.boldFont}>Equipment</span>:{' '}
              {loadTenderStore.loadTender?.equipment}
            </div>
            <div class={classes.transportationMethod}>
              <span class={classes.boldFont}>
                Transportation Method:{' '}
                {loadTenderStore.loadTender?.transportationMethod}
              </span>
            </div>
            <div class={classes.tableContainer}>
              <BasicTable<IStops>
                columns={columns}
                rows={loadTenderStore.loadTender?.stops}
              />
            </div>
          </div>
          <div class={classes.rightContainer}>
            <Map
              height={550}
              width={'100%'}
              style={'basic'}
              coordinates={coordinates()}
              sx={{ borderRadius: '5px', boxShadow: '0px 2px 5px gray' }}
            />
          </div>
        </Box>
      </div>
    </BasicModal>
  );
};
