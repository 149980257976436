import { apiClient } from '@api/apiClient';
import { handleToast } from '@utils/utils';
import { ToastType } from '@components';
import { V4ApiResponse } from '@store/customers/customerDetails';

import {
  CarrierDetails,
  CarrierDocument,
  CarrierFuelSurchargeDetails,
  FactoringCompany,
  Comment,
  CarrierLoadHistory,
  CarrierDetailsAdjustment,
  CarrieDetailsClaimHistory,
  CarrierAdjustmentAccounts,
} from './types';
import { setCarrierDetailsStore } from './store';

export const fetchCarrierDetails = async (carrierId: string) => {
  try {
    const response: V4ApiResponse<CarrierDetails> = await apiClient.get(
      `/carrier/carrierdetails/${carrierId}`,
    );
    if (response.isSuccess) {
      return response.value;
    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      handleToast(
        ToastType.Error,
        `Fetching carrier details failed: ${error.message}`,
      );
    }
  }
};

export const fetchCarrierAdjustments = async (carrierId: string) => {
  try {
    const response: V4ApiResponse<CarrierDetailsAdjustment[]> =
      await apiClient.get(`/Carrier/Adjustments/${carrierId}`, {
        params: { carrierId },
      });
    return response;
  } catch (error: unknown) {
    if (error instanceof Error) {
      handleToast(
        ToastType.Error,
        `Fetching carrier adjustments failed: ${error.message}`,
      );
    }
  }
};

export const fetchCarrierAdjustmentAccounts = async () => {
  try {
    const response: V4ApiResponse<CarrierAdjustmentAccounts[]> =
      await apiClient.get('/carrier/adjustmentaccounts');
    if (response.isSuccess) {
      return response.value;
    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      handleToast(
        ToastType.Error,
        `Fetching carrier adjustments accounts failed: ${error.message}`,
      );
    }
  }
};
export const fetchCarrierDocuments = async (carrierId: string) => {
  try {
    const response: V4ApiResponse<CarrierDocument[]> = await apiClient.get(
      `/Carrier/getcarrierdocument/${carrierId}`,
    );
    return response.value;
  } catch (error: unknown) {
    if (error instanceof Error) {
      handleToast(
        ToastType.Error,
        `Fetching carrier documents failed: ${error.message}`,
      );
    }
  }
};

export const fetchCarrierFuelSurcharge = async (carrierId: string) => {
  try {
    const resp: V4ApiResponse<CarrierFuelSurchargeDetails> =
      await apiClient.get(
        `/FuelSurcharge/getfuelsurcharge?ownerId=${carrierId}&ownerType=${1}&includeDeleted=${false}`,
      );
    if (resp.isSuccess) {
      return resp.value;
    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      handleToast(
        ToastType.Error,
        `Fetching carrier fuel surcharge failed: ${error.message}`,
      );
    }
  }
};

export const addCarrierToPlaylistFavorite = async (payload: {
  selected: boolean;
  carrierId: number;
}) => {
  try {
    const resp: V4ApiResponse<number> = await apiClient.put(
      '/Playlist/Favorite',
      payload,
    );
    return resp;
  } catch (error: unknown) {
    if (error instanceof Error) {
      handleToast(
        ToastType.Error,
        `Adding carrier playlist favorite failed: ${error.message}`,
      );
    }
  }
};

export const fetchCarrierFactoringCompany = async (carrierId: number) => {
  try {
    const response: V4ApiResponse<FactoringCompany> = await apiClient.get(
      `/FactoringCompany/getfactoringdata/${carrierId}`,
    );
    return response.value;
  } catch (error: unknown) {
    if (error instanceof Error) {
      handleToast(
        ToastType.Error,
        `Fetching carrier factoring company failed: ${error.message}`,
      );
    }
  }
};

export const postCarrierComment = async (
  payload: Record<string, string | number | null>,
) => {
  try {
    const res: V4ApiResponse<Comment> = await apiClient.post(
      '/Carrier/addcarriercomment',
      payload,
    );
    return res.value;
  } catch (error: unknown) {
    if (error instanceof Error) {
      handleToast(
        ToastType.Error,
        `Adding carrier comments failed: ${error.message}`,
      );
    }
  }
};

export async function deleteCarrierComment(commentId: number) {
  try {
    const res: V4ApiResponse<null> = await apiClient.delete(
      `/Carrier/deletecomment/${commentId}`,
    );
    return res.isSuccess;
  } catch (error: unknown) {
    if (error instanceof Error) {
      handleToast(
        ToastType.Error,
        `Deleting carrier comments failed: ${error.message}`,
      );
    }
  }
}

export const fetchCarrierLoadHistory = async (carrierId: string) => {
  try {
    const response: V4ApiResponse<CarrierLoadHistory[]> = await apiClient.get(
      `Carrier/LoadHistory/${carrierId}?activeOnly=false`,
    );
    return response.value as unknown as CarrierLoadHistory[];
  } catch (error: unknown) {
    if (error instanceof Error) {
      handleToast(
        ToastType.Error,
        `Fetching carrier load history failed: ${error.message}`,
      );
    }
  }
};

export const saveNewCarrierAdjustments = async (
  carrierId: number,
  payload: Record<string, unknown>[],
) => {
  try {
    const res: V4ApiResponse<null> = await apiClient.post(
      '/Carrier/Adjustment',
      {
        id: carrierId,
        adjustments: payload,
      },
    );
    return res.isSuccess;
  } catch (error: unknown) {
    if (error instanceof Error) {
      handleToast(
        ToastType.Error,
        `Saving new carrier adjustments failed: ${error.message}`,
      );
    }
  }
};

export const fetchPastCarrierAdjustments = async (carrierId: number) => {
  try {
    const response: V4ApiResponse<CarrierDetailsAdjustment[]> =
      await apiClient.get(
        `/Carrier/AdjustmentHistory/${carrierId}?toCurrency=NaN`,
      );
    if (response.isSuccess) {
      return response.value;
    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      handleToast(
        ToastType.Error,
        `Fetching past carrier adjustments failed: ${error.message}`,
      );
    }
  }
};

export const updateCarrierComment = async (
  payload: Record<string, string | number | null>,
) => {
  try {
    const res: { isSuccess: boolean } = await apiClient.put(
      '/Carrier/updatecomment',
      payload,
    );
    return res.isSuccess;
  } catch (error: unknown) {
    if (error instanceof Error) {
      handleToast(
        ToastType.Error,
        `Updating carrier comments failed: ${error.message}`,
      );
    }
  }
};

export const updateCarrierDetailsRatings = async (
  payload: Record<string, string | number | null>,
) => {
  try {
    const res: V4ApiResponse<null> = await apiClient.put(
      '/Carrier/updaterating',
      payload,
    );
    return res.isSuccess;
  } catch (error: unknown) {
    if (error instanceof Error) {
      handleToast(
        ToastType.Error,
        `Updating carrier ratings failed: ${error.message}`,
      );
    }
  }
};

export const sendCarrierTransfloEmails = async (payload: {
  carrierId: number;
  contactIds: number[];
}) => {
  try {
    const resp: V4ApiResponse<null> = await apiClient.post(
      '/carrier/enabletransflocarrier',
      payload,
    );
    if (resp.isSuccess) {
      handleToast(
        ToastType.Success,
        'Sent Transflo email registration to selected contacts',
      );
    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      handleToast(
        ToastType.Error,
        `Sending carrier transflo emails failed: ${error.message}`,
      );
    }
  }
};

export async function updateCarrierDetails(payload: CarrierDetails) {
  try {
    const res: V4ApiResponse<CarrierDetails> = await apiClient.put(
      '/Carrier',
      payload,
    );
    if (Boolean(res.isSuccess)) {
      handleToast(ToastType.Success, 'Carrier details saved successfully');
      setCarrierDetailsStore('carrierDetails', res.value);
      return res.isSuccess as boolean;
    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      handleToast(
        ToastType.Error,
        `Updating carrier details failed: ${error.message}`,
      );
    }
  }
}

export const uploadCarrierDetailsDocument = async (
  carrierId: number,
  file: File,
) => {
  try {
    const formData = new FormData();
    formData.append('Files', file);
    formData.append('DocumentEntityType', '3');
    formData.append('EntityId', carrierId.toString());
    const res: V4ApiResponse<{
      results: { documentId: number; name: string; url: string }[];
    }> = await apiClient.post('/carrier/uploadfile', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return res.value;
  } catch (error: unknown) {
    if (error instanceof Error) {
      handleToast(
        ToastType.Error,
        `Uploading carrier document failed: ${error.message}`,
      );
    }
  }
};

export async function deleteCarrierDetailsDocument(payload: {
  id: number;
  file: string;
  type: string;
}) {
  try {
    await apiClient.delete('/carrier/deletefile', { data: payload });
  } catch (error: unknown) {
    if (error instanceof Error) {
      handleToast(
        ToastType.Error,
        `Deleting carrier document failed: ${error.message}`,
      );
    }
  }
}

export const fetchCarrierDetailsClaimHistory = async (carrierId: string) => {
  try {
    const response: V4ApiResponse<CarrieDetailsClaimHistory[]> =
      await apiClient.get(`carrier/claimhistory/${carrierId}`);
    if (response.isSuccess) {
      return response.value;
    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      handleToast(
        ToastType.Error,
        `Fetching carrier claim history failed: ${error.message}`,
      );
    }
  }
};

export const deleteCarrier = async (carrierId: number) => {
  try {
    const res: V4ApiResponse<boolean> = await apiClient.delete(
      `/Carrier/${carrierId}`,
    );
    return res.isSuccess;
  } catch (error: unknown) {
    if (error instanceof Error) {
      handleToast(ToastType.Error, `Deleting carrier failed: ${error.message}`);
      throw new Error(error.message);
    }
  }
};

export const blacklistCarrier = async (params: {
  id: number;
  comment: string;
}) => {
  try {
    const res: V4ApiResponse<null> = await apiClient.post(
      `/Carrier/addtoblacklist?id=${params.id}&comment=${params.comment}`,
    );
    return res.isSuccess;
  } catch (error: unknown) {
    if (error instanceof Error) {
      handleToast(
        ToastType.Error,
        `Blacklisting carrier failed: ${error.message}`,
      );
    }
  }
};

export const unblacklistCarrier = async (carrierId: number) => {
  try {
    const res: V4ApiResponse<null> = await apiClient.put(
      `/Carrier/removefromblacklist?id=${carrierId}`,
    );
    return res.isSuccess;
  } catch (error: unknown) {
    if (error instanceof Error) {
      handleToast(
        ToastType.Error,
        `Unblacklisting carrier failed: ${error.message}`,
      );
    }
  }
};

export async function saveCarrierDetails(payload: CarrierDetails) {
  try {
    const res: V4ApiResponse<CarrierDetails> = await apiClient.post(
      '/Carrier',
      payload,
    );
    handleToast(ToastType.Success, 'Carrier details saved successfully');
    return res.value.id;
  } catch (error: unknown) {
    if (error instanceof Error) {
      handleToast(
        ToastType.Error,
        `Saving carrier details failed: ${error.message}`,
      );
    }
  }
}
