import { BasicModal, Button, Row as BasicRowType } from '@components';
import {
  AddressBookModel,
  getAddressBookById,
  setAddressBookStore,
} from '@store/customers/customerDetails/addressBookTab';
import { Box, Grid, Stack } from '@suid/material';
import { atgLogo } from '@views/ltl';
import { ColDef, CellClickedEvent, GridReadyEvent } from 'ag-grid-community';
import AgGridSolid, { AgGridSolidRef } from 'ag-grid-solid';
import { closeModal, openModal } from '@store/modals';
import {
  columnsToolPanel,
  filtersToolPanel,
} from '@components/Grid/components/constants';
import { LTLCatalogs } from '@store/orders';

import { addressBookGridStyle as classes } from './classes';
import AddOrEditAddressBook from './AddOrEditAddressBook';

export type AddressBookGridProps = {
  addressBookColumns: ColDef[];
  items: AddressBookModel[];
  onGridReady?: (event: GridReadyEvent) => void;
  onRowClick?: (rowData: BasicRowType) => void;
  customerId?: number;
};

const AddressBookGrid = (props: AddressBookGridProps) => {
  let gridRef: AgGridSolidRef;

  const onGridReady = async (event: GridReadyEvent) => {
    if (props.onGridReady) {
      gridRef!.api.showLoadingOverlay();
      await props.onGridReady(event);
    }
  };

  const addModalId = 'addOrEditAddressBook';

  const handleClose = () => {
    closeModal(addModalId);
    setAddressBookStore('selectedAddress', null);
    setAddressBookStore('addOrEditErrorMessage', '');
  };
  return (
    <Stack class={classes.stackStyle}>
      <Box class={classes.addBtnBox}>
        <Button
          label={<div class={classes.addBtn}>Add Address</div>}
          variant="contained"
          size="small"
          onClick={() => {
            setAddressBookStore('selectedAddress', null);
            openModal(addModalId),
              setAddressBookStore('addOrEditErrorMessage', '');
          }}
        />
      </Box>
      <Grid class={classes.gridStyle}>
        <AgGridSolid
          ref={gridRef!}
          columnDefs={props.addressBookColumns}
          rowData={props.items}
          gridOptions={{
            defaultColDef: { flex: 1, filter: true, floatingFilter: true },
            onGridReady: onGridReady,
            rowHeight: 50,
            sideBar: {
              toolPanels: [columnsToolPanel, filtersToolPanel],
              defaultToolPanel: '',
            },
          }}
          onCellClicked={async (e: CellClickedEvent) => {
            if (props.onRowClick) return;
            if (e.colDef.field === 'delete') return;
            const selectedId = (e.data as AddressBookModel).id;
            if (selectedId != null) {
              await getAddressBookById(selectedId);
              openModal(addModalId);
            }
          }}
          onRowClicked={(params: { data: LTLCatalogs }) => {
            props.onRowClick && props.onRowClick(params.data);
          }}
          loadingOverlayComponent={atgLogo}
          suppressPaginationPanel={false}
          pagination={true}
          paginationPageSize={50}
          overlayLoadingTemplate={atgLogo}
          noRowsOverlayComponent={atgLogo}
        />
      </Grid>
      <BasicModal
        id={addModalId}
        title="Add/Edit Address"
        width="800px"
        footer={false}
        onClose={handleClose}
      >
        <AddOrEditAddressBook
          modalId={addModalId}
          handleClose={handleClose}
          customerId={props.customerId}
        />
      </BasicModal>
    </Stack>
  );
};

export default AddressBookGrid;
