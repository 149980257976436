import { BasicModal, Row as BasicRowType, Button } from '@components';
import {
  columnsToolPanel,
  filtersToolPanel,
} from '@components/Grid/components/constants';
import { closeModal, openModal } from '@store/modals';
import {
  LTLCatalogs,
  ODataApiQueryParams,
  fetchProductCatalog,
} from '@store/orders';
import { Box, Grid } from '@suid/material';
import {
  ColDef,
  GridReadyEvent,
  IServerSideDatasource,
} from 'ag-grid-community';
import AgGridSolid, { AgGridSolidRef } from 'ag-grid-solid';
import { DateTime } from 'luxon';
import { Show, createEffect, createSignal } from 'solid-js';

import {
  AddEditProductDetails,
  ImportCatalogItem,
  ProductCatalogActionsButton,
} from './components';

interface LTLProductCatalogPopupProps {
  customerId: number;
  goBack?: () => void;
  onRowClick?: (rowData: BasicRowType) => void;
  goBackButtonText?: string;
  fromCustomerDetails?: boolean;
}

const agGridCellStyle = {
  padding: '10px',
  'word-break': 'break-word',
  'white-space': 'normal',
  'line-height': '1.6 ',
  'align-items': 'center !important',
};

const LTLProductCatalog = (props: LTLProductCatalogPopupProps) => {
  const [gridRef, setGridRef] = createSignal<AgGridSolidRef | null>(null);
  const [selectedRow, setSelectedRow] = createSignal<LTLCatalogs | null>(null);
  const [triggerAddUpdateFn, setTriggerAddUpdateFn] = createSignal<
    'add' | 'update' | 'delete' | 'none'
  >('none');
  const [edit, setEdit] = createSignal(false);

  const filterParams = {
    filterOptions: [
      'equals',
      'notEqual',
      'contains',
      'startsWith',
      'endsWith',
      'notContains',
      'blank',
      'notBlank',
    ],
    buttons: ['apply', 'reset'],
    suppressAndOrCondition: true,
  };

  const columnDefs: ColDef[] = [
    {
      headerName: 'Name',
      field: 'name',
      minWidth: 220,
      cellStyle: agGridCellStyle,
      autoHeight: true,
      filter: 'agTextColumnFilter',
      filterParams,
    },
    {
      headerName: 'Description',
      field: 'description',
      minWidth: 220,
      cellStyle: agGridCellStyle,
      autoHeight: true,
      filter: 'agTextColumnFilter',
      filterParams,
    },
    {
      headerName: 'Class',
      field: 'class',
      cellStyle: agGridCellStyle,
      autoHeight: true,
      filter: 'agTextColumnFilter',
      filterParams,
      minWidth: 90,
    },
    {
      headerName: 'NMFC',
      field: 'nMFC',
      cellStyle: agGridCellStyle,
      autoHeight: true,
      filter: 'agTextColumnFilter',
      filterParams,
      minWidth: 100,
    },
    {
      headerName: 'Note',
      field: 'note',
      cellStyle: agGridCellStyle,
      autoHeight: true,
      sortable: false,
      filter: 'agTextColumnFilter',
      filterParams,
      minWidth: 100,
    },
    {
      headerName: 'Dimensions',
      field: 'dimensions',
      cellStyle: agGridCellStyle,
      autoHeight: true,
      filter: false,
      sortable: false,
      valueFormatter: (params) => {
        const data = params.data as LTLCatalogs;
        return `${data.lengthInches || ''}" x ${data.widthInches || ''}" x ${
          data.heightInches || ''
        }"`;
      },
      minWidth: 120,
    },
    {
      headerName: 'Hazmat',
      field: 'hazmat',
      valueFormatter: (params: { value: boolean }) => {
        return params.value ? 'true' : 'false';
      },
      cellStyle: agGridCellStyle,
      autoHeight: true,
      filter: 'agSetColumnFilter',
      filterParams: {
        ...filterParams,
        values: ['true', 'false'],
      },
      minWidth: 70,
    },
    {
      headerName: 'Created',
      field: 'createdDate',
      cellStyle: agGridCellStyle,
      autoHeight: true,
      filter: 'agDateColumnFilter',
      filterParams: {
        suppressAndOrCondition: true,
        buttons: ['apply', 'reset'],
      },
      valueFormatter: (params) => {
        const date = DateTime.fromISO(params.value as string);
        if (date.isValid) {
          return `${date.setZone('local').toFormat('MM/dd/yyyy')}`;
        }
        return '';
      },
      minWidth: 100,
    },
    {
      headerName: 'Unit Type',
      field: 'unitType',
      cellStyle: agGridCellStyle,
      autoHeight: true,
      filter: 'agTextColumnFilter',
      filterParams,
      minWidth: 100,
    },
  ];

  const onGridReady = (params: GridReadyEvent) => {
    setGridRef(params);
    const dataSource = {
      getRows: async (params: {
        api: {
          paginationGetCurrentPage: () => number;
        };
        request: {
          filterModel: {
            [key: string]: {
              filter?: string;
              dateFrom?: string | null;
              dateTo?: null;
              filterType: 'date' | 'text' | 'set';
              type: string;
              values: string[];
            };
          };
          sortModel: {
            colId: string;
            sort: string;
          }[];
        };
        success: (data: { rowData: LTLCatalogs[]; rowCount: number }) => void;
        fail: () => void;
      }) => {
        let filter = `CustomerId eq ${props.customerId}`;
        let orderby = 'Name';

        if (params.request.sortModel.length > 0) {
          const sortModel = params.request.sortModel[0];
          orderby = `${
            sortModel.colId.charAt(0).toUpperCase() + sortModel.colId.slice(1)
          } ${sortModel.sort}`;
        }

        if (Object.keys(params.request.filterModel).length > 0) {
          const filterString: string[] = [];

          const handleBooleanFilter = (
            keyFilter: string,
            filterValue: {
              filter?: string;
              dateFrom?: string | null;
              dateTo?: string | null;
              filterType?: 'set' | 'text' | 'date';
              type?: string;
              values: string[];
            },
          ) => {
            return filterValue.values[0] === 'true'
              ? `${keyFilter} eq true`
              : `${keyFilter} eq false`;
          };

          const formatDate = (date: string | null) => {
            return date!
              ? DateTime.fromFormat(date, 'yyyy-MM-dd HH:mm:ss', {
                  zone: 'utc',
                })
                  .setZone('local')
                  .toISO()
              : '';
          };

          const handleDateFilter = (
            keyFilter: string,
            filterValue: {
              dateFrom?: string | null;
              dateTo?: string | null;
              filterType: 'text' | 'set' | 'date';
              type: string;
            },
          ) => {
            const dateFrom = formatDate(filterValue.dateFrom!);
            const dateTo = formatDate(filterValue.dateTo!);

            switch (filterValue.type) {
              case 'inRange':
                return `${keyFilter} ge ${dateFrom} and ${keyFilter} le ${dateTo}`;
              case 'equals':
                return `${keyFilter} eq ${dateFrom}`;
              case 'notEqual':
                return `${keyFilter} ne ${dateFrom}`;
              case 'lessThan':
                return `${keyFilter} lt ${dateFrom}`;
              case 'greaterThan':
                return `${keyFilter} gt ${dateFrom}`;
              case 'blank':
                return `${keyFilter} eq null`;
              case 'notBlank':
                return `${keyFilter} ne null`;
              default:
                return '';
            }
          };

          Object.keys(params.request.filterModel).forEach((key: string) => {
            const filterValue = params.request.filterModel[key];
            const keyFilter = key.charAt(0).toUpperCase() + key.slice(1);

            if (filterValue.filterType === 'date') {
              const dateFilter = handleDateFilter(keyFilter, filterValue);
              if (dateFilter) filterString.push(dateFilter);
            } else if (filterValue.filterType === 'set') {
              let setFilter = '';
              switch (keyFilter) {
                case 'Hazmat':
                  setFilter = handleBooleanFilter(keyFilter, filterValue);
                  break;
                default:
                  setFilter = filterValue.values
                    .map((val: string) => `${keyFilter} eq '${val}'`)
                    .join(' or ');
                  break;
              }

              if (setFilter) filterString.push(setFilter);
            } else {
              const textFilterMap: { [key: string]: string } = {
                notEqual: `${keyFilter} ne '${filterValue.filter}'`,
                equals: `${keyFilter} eq '${filterValue.filter}'`,
                notContains: `indexof(${keyFilter},'${filterValue.filter}') eq -1`,
                blank: `${keyFilter} eq ''`,
                notBlank: `${keyFilter} ne ''`,
              };

              const textFilter =
                textFilterMap[filterValue.type] ||
                `${filterValue.type.toLowerCase()}(${keyFilter},'${
                  filterValue.filter
                }')`;

              if (textFilter) filterString.push(textFilter);
            }
          });
          filter = `${filter} and ${filterString.join(' and ')}`;
        }
        const queryParams: ODataApiQueryParams = {
          $top: 100,
          $skip: params.api.paginationGetCurrentPage() * 100,
          $orderby: orderby,
          $format: 'json',
          $count: true,
        };

        if (filter) queryParams.$filter = filter;

        try {
          gridRef()?.api.showLoadingOverlay();
          const data = await fetchProductCatalog(queryParams);
          params.success({
            rowData: data.data,
            rowCount: data.count,
          });
        } catch (error) {
          params.fail();
        } finally {
          gridRef()?.api.hideOverlay();
        }
      },
    };
    params.api.setGridOption(
      'serverSideDatasource',
      dataSource as unknown as IServerSideDatasource,
    );
  };

  const onModalClose = () => {
    setEdit(false);
    setSelectedRow(null);
    closeModal('addEditProductDetails');
  };

  function updateRowAtIndex() {
    const rowNode = gridRef()?.api.getRowNode(selectedRow()?.id as string);
    if (rowNode) {
      rowNode.setData(selectedRow() as LTLCatalogs);
      gridRef()?.api.refreshCells({ rowNodes: [rowNode] });
    }
  }

  function addRow() {
    gridRef()?.api.applyServerSideTransaction({ add: [selectedRow()] });
  }

  function removeRow() {
    const rowNode = gridRef()?.api.getRowNode(selectedRow()?.id as string);
    if (rowNode) {
      gridRef()?.api.applyServerSideTransaction({ remove: [rowNode.data] });
    }
  }
  createEffect(() => {
    const triggerAction = triggerAddUpdateFn();
    if (triggerAction === 'update' && edit()) {
      updateRowAtIndex();
    } else if (triggerAction === 'add' && !edit()) {
      addRow();
    } else if (triggerAction === 'delete') {
      removeRow();
    }
    setTriggerAddUpdateFn('none');
  });

  return (
    <>
      <Show when={Boolean(props.fromCustomerDetails)}>
        <ProductCatalogActionsButton
          setEdit={setEdit}
          openModal={openModal}
          gridRef={gridRef}
        />
      </Show>
      <Grid class="ag-theme-alpine" height={550}>
        <AgGridSolid
          ref={gridRef}
          columnDefs={columnDefs}
          onRowClicked={(params: { data: LTLCatalogs }) => {
            //Access from Customer Details
            if (Boolean(props.fromCustomerDetails)) {
              setSelectedRow(params.data);
              setEdit(true);
              openModal('addEditProductDetails');
            } else {
              props.onRowClick && props.onRowClick(params.data);
            }
          }}
          gridOptions={{
            defaultColDef: {
              flex: 1,
            },
            getRowId: (params: { data: LTLCatalogs }) => params.data.id,
            rowModelType: 'serverSide',
            pagination: true,
            suppressPaginationPanel: false,
            paginationPageSize: 100,
            cacheBlockSize: 100,
            sideBar: {
              toolPanels: [columnsToolPanel, filtersToolPanel],
              defaultToolPanel: '',
            },
            paginationPageSizeSelector: false,
            onGridReady: onGridReady,
          }}
        />
      </Grid>
      {props.goBack && (
        <Box displayRaw="flex" justifyContent="end" alignItems="center" pt={4}>
          <Button
            size="medium"
            onClick={props.goBack}
            label={props.goBackButtonText}
          />
        </Box>
      )}
      <BasicModal
        id="addEditProductDetails"
        title="Product Catalog"
        width="800px"
        footer={false}
        onClose={onModalClose}
      >
        <AddEditProductDetails
          customerId={props.customerId}
          edit={edit}
          setEdit={setEdit}
          selectedRow={selectedRow}
          onModalClose={onModalClose}
          setSelectedRow={setSelectedRow}
          triggerAddUpdateFn={triggerAddUpdateFn}
          setTriggerAddUpdateFn={setTriggerAddUpdateFn}
        />
      </BasicModal>
      <BasicModal
        id="importProductCatalog"
        title="Catalog Item Import"
        width="600px"
        footer={false}
      >
        <ImportCatalogItem gridRef={gridRef} />
      </BasicModal>
    </>
  );
};

export default LTLProductCatalog;
