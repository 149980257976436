import logo from '@assets/logo.svg';
import {
  ILoadBoardViewModel,
  LoadBoardEventEnum,
  LoadBoardMetaDataT,
  LoadBoardWSMsgT,
  autoHeightColumns,
  calculateNewHeight,
  loadBoardStore,
} from '@store/loadboard';
import {
  currencyCellFormatter,
  underlinedCellRenderer,
} from '@store/loadboard/utils';
import { userStore } from '@store/user';
import { Grid as MuiGrid, Stack } from '@suid/material';
import { ConfigManager } from '@utils/ConfigManager';
import { printLog } from '@utils/utils';
import TransitWarning from '@views/loadboard/TransitWarning';
import {
  CellClickedEvent,
  ColDef,
  FilterModel,
  GridApi,
  GridOptions,
} from 'ag-grid-community';
import 'ag-grid-community/styles/ag-grid.css';
import { LicenseManager } from 'ag-grid-enterprise';
import AgGridSolid, { AgGridSolidRef } from 'ag-grid-solid';
import { memoize } from 'lodash';
import { Component, Show, createEffect, createSignal } from 'solid-js';

import { globalState } from '../../store';
import '../Grid/AgGridStyles.css';
import Filters from './components/Filters';
import Pagination from './components/Pagination';
import { columnsToolPanel, filtersToolPanel } from './components/constants';
import { getRowStyle } from './utils';

LicenseManager.setLicenseKey(ConfigManager.agGridLicensePicks);

export type GridProps = {
  gridReady: boolean;
  data: Record<string, unknown>[];
  oldPicks: ILoadBoardViewModel[];
  oldDrops: ILoadBoardViewModel[];
  loading: boolean;
  onUpdateItems: (newItem: string, col: string, loadId: number) => void;
  onUpdatePostings: (postings: ILoadBoardViewModel[]) => void;
  columnDefs: Array<ColDef>;
  columns?: string[];
  singlePage?: boolean;
  allowFiltering?: boolean;
  onRowClicked?: (e: CellClickedEvent, col?: string) => void;
  onRefreshGrid?: () => void;
  onGridReady?: (gridApi: GridApi) => void;
  extraOptions?: GridOptions;
  setCustomFilterCache: (filter: string) => void;
};
const imageTag = `<img src=${logo} alt="" />`;

export const Grid: Component<GridProps> = (props: GridProps) => {
  let gridRef: AgGridSolidRef | undefined;
  const [socketHandlerInitialized, setSocketHandlerInitialized] =
    createSignal(false);
  const [pagelimit, setPageLimit] = createSignal(10);
  const [pageNumber, setPageNumber] = createSignal(1);
  const [total, setTotal] = createSignal(0);
  const [showingCount, setShowingCount] = createSignal({
    start: 1,
    end: pagelimit(),
  });
  const [gridApi, setGridApi] = createSignal<GridApi>();
  const [filterModel, setFilterModel] = createSignal<FilterModel>({});

  const showLoading = () => {
    gridRef?.api.showLoadingOverlay();
  };

  const hideLoading = () => {
    gridRef?.api.hideOverlay();
  };

  createEffect(() => {
    if (loadBoardStore.overlay === true) {
      showLoading();
    }
    if (
      loadBoardStore.overlay === false &&
      (gridRef?.api as unknown) !== undefined
    ) {
      hideLoading();
      gridRef?.api.redrawRows();
    }
  });

  const columnDefs: Array<ColDef> = props.columnDefs;
  const shouldBeSinglePage = props.singlePage !== undefined && props.singlePage;
  const gridOptions: GridOptions = {
    defaultColDef: {
      editable: false,
      enableRowGroup: false,
      enablePivot: false,
      enableValue: true,
      sortable: true,
      resizable: true,
      filter: true,
      cellDataType: false,
    },
    components: {
      underlinedCellRenderer: memoize(underlinedCellRenderer),
    },
    rowBuffer: 30,
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-return
    getRowId: (params) => params.data.loadId,
    asyncTransactionWaitMillis: 4000,
    includeHiddenColumnsInQuickFilter: true,
    columnDefs: columnDefs,
    suppressPaginationPanel: !shouldBeSinglePage,
    pagination: !shouldBeSinglePage,
    paginationPageSize: shouldBeSinglePage ? undefined : 10,
    suppressScrollOnNewData: true,
    rowGroupPanelShow: 'never',
    quickFilterParser: (quickFilter) => quickFilter.split(','),
    autoGroupColumnDef: {
      minWidth: 200,
    },
    dataTypeDefinitions: {
      currency: {
        extendsDataType: 'number',
        baseDataType: 'number',
        // @ts-expect-error The type is fine
        valueFormatter: currencyCellFormatter,
        columnTypes: ['currencyType', 'numericColumn'],
      },
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    getRowHeight(params) {
      const newHeight = autoHeightColumns.reduce<number | undefined>(
        (newHeight, colId) => {
          const data = params.data as ILoadBoardViewModel | null;
          const column = params.api.getColumn(colId);

          if (column !== null) {
            const height = calculateNewHeight(
              data?.lastCheckCallComment,
              column.getActualWidth(),
            );

            if (
              newHeight === undefined ||
              (height !== undefined && height > newHeight)
            ) {
              return height;
            }

            return newHeight;
          }
        },
        undefined,
      );

      return newHeight;
    },
    suppressDragLeaveHidesColumns: true,
    sideBar: {
      toolPanels: [
        ...(props.allowFiltering !== undefined ? [filtersToolPanel] : []),
        columnsToolPanel,
      ],
    },
    onPaginationChanged: (event) => {
      setPageLimit(event.api.paginationGetPageSize());
      setPageNumber(event.api.paginationGetCurrentPage() + 1);
      setTotal(event.api.paginationGetTotalPages());

      const rowCount = event.api.getDisplayedRowCount();
      const lastGridIndex = rowCount - 1;
      const currentPage = pageNumber() - 1;
      const startPageIndex = currentPage * pagelimit();
      let endPageIndex = (currentPage + 1) * pagelimit() - 1;

      if (endPageIndex > lastGridIndex) {
        endPageIndex = lastGridIndex;
      }

      setShowingCount({
        start: startPageIndex + 1,
        end: endPageIndex + 1,
      });
    },
    onGridReady(event) {
      setGridApi(event.api);
      const toolPanelInstance = event.api.getToolPanelInstance('filters');

      if (toolPanelInstance !== undefined) {
        toolPanelInstance.expandFilters();
      }

      event.api.addEventListener('filterChanged', () => {
        setFilterModel(event.api.getFilterModel());
      });

      props.onGridReady?.(event.api);
    },
    onCellClicked: (event) => {
      props.onRowClicked?.(event, event.colDef.field);
    },
    ...props.extraOptions,
  };

  createEffect(() => {
    const socketv = globalState.socketv;
    if (socketv !== undefined) {
      if (socketHandlerInitialized() === false) {
        globalState.socketv!.on(
          'RecieveWebSocketMessage',
          (message: string) => {
            const msgObj = JSON.parse(message) as LoadBoardWSMsgT;
            const loadMetaModel = JSON.parse(
              msgObj.dataJson,
            ) as LoadBoardMetaDataT;
            const load = loadMetaModel.loadboardLine;

            if (msgObj.eventName === LoadBoardEventEnum.Deleted) {
              const loadId = loadMetaModel.loadId;
              gridApi()?.applyTransaction({ remove: [{ loadId }] });
              return;
            }

            const name = userStore.user.name.trim();
            const myOwnedOrder =
              load.assignedTo === name ||
              load.coveredBy === name ||
              load.owner === name;
            load.my = myOwnedOrder ? 'Yes' : 'No';
            const myCustomers =
              load.accountManager === name || load.salesManager === name;
            load.myCustomers = myCustomers ? 'Yes' : 'No';

            if (msgObj.eventName === LoadBoardEventEnum.Created) {
              gridApi()?.applyTransaction({ add: [load] });
            } else {
              gridApi()?.applyTransaction({ update: [load] });
            }
          },
        );
      }
      setSocketHandlerInitialized(true);
    }
  });

  createEffect(() => {
    printLog(filterModel());
  });

  return (
    <Stack height="100vh">
      <Show when={props.oldDrops.length > 0 || props.oldPicks.length > 0}>
        <TransitWarning
          oldPicks={props.oldPicks}
          oldDrops={props.oldDrops}
          getRowStyle={getRowStyle}
          onUpdateItems={props.onUpdateItems}
        />
      </Show>
      <Filters
        gridReady={props.gridReady}
        gridOptions={gridOptions}
        columnDefs={columnDefs}
        gridApi={gridApi()}
        onRefreshGrid={props.onRefreshGrid}
        loading={props.loading}
        setCustomFilterCache={props.setCustomFilterCache}
        onUpdatePostings={props.onUpdatePostings}
      />
      <MuiGrid class="ag-theme-alpine !flex-1">
        <AgGridSolid
          ref={gridRef}
          class="!h-[calc(100% - 48px)]"
          columnDefs={columnDefs}
          rowData={props.data}
          gridOptions={gridOptions}
          getRowStyle={getRowStyle}
          overlayLoadingTemplate={imageTag}
          noRowsOverlayComponent={imageTag}
        />
      </MuiGrid>
      <Show when={!shouldBeSinglePage}>
        <Pagination
          gridApi={gridApi()}
          gridOptions={gridOptions}
          pageLimit={pagelimit()}
          pageNumber={pageNumber()}
          total={total()}
          showingCount={showingCount()}
          length={props.data.length}
        />
      </Show>
    </Stack>
  );
};
