import { BasicModal, Menu } from '@components';
import { TextAreaField } from '@components/forms';
import { carrierUrls, equipmentSearchUrl } from '@constants/urls';
import { Link } from '@solidjs/router';
import { openModal } from '@store/modals';
import {
  FlagType,
  ILoadEditViewModel,
  availableServices,
  carrierStore,
  copyCarrier,
  copyCarrierDetails,
  fetchAvailableServices,
  fetchPostLoadByPostingId,
  orderStore,
  setAvailableSevices,
  updateLoadPropertyAtIndex,
} from '@store/orders';
import { userStore } from '@store/user';
import { Box } from '@suid/material';
import { getEligibleEntries, isAdmin, printLog } from '@utils/utils';
import { Show, createEffect, createSignal, onMount } from 'solid-js';
import { unwrap } from 'solid-js/store';

import { PostLoad } from '../../models';
import './LoadInformationActionButtonStyles.css';

type LoadInformationActionButtonsProps = {
  load: ILoadEditViewModel;
  tabIndex: number;
  onAddFlag: () => void;
  setFlagValue: (flagType: FlagType) => void;
  currentFlagIndex: () => number | null;
  setCurrentFlagIndex: (index: number | null) => void;
};

const LoadInformationActionButtons = (
  props: LoadInformationActionButtonsProps,
) => {
  const [services, setServices] = createSignal<availableServices[]>([]);
  const [postId, setPostId] = createSignal(props.load.postingId);

  const legalTextModalId = `legalTextModal-${props.tabIndex}`;
  const postModalId = `postLoadModal-${props.tabIndex}`;
  const editFlagModalId = `EditFlagID-${props.tabIndex}`;

  createEffect(() => {
    setPostId(orderStore.order.loads[props.tabIndex].postingId);
  });

  onMount(async () => {
    if (
      userStore.user.postingGroupId !== 0 &&
      userStore.user.postingGroupId !== null
    ) {
      const response = await fetchAvailableServices();
      if (postId() !== null) {
        await fetchPostLoadByPostingId(postId());
      }
      setAvailableSevices(response);
      setServices(response);
    }
  });

  const onAddFlagTap = () => {
    if (orderStore.isReadOnly) {
      return;
    }

    props.setFlagValue(FlagType.Red);
    openModal(editFlagModalId);
  };

  const getEligibleStops = () => {
    return getEligibleEntries(props.load.stops);
  };

  return (
    <>
      <Box class="flex items-center gap-3 text-sm">
        {props.load.id && (
          <span
            class={`actionButton ${
              orderStore.isReadOnly ? '!cursor-default opacity-75' : ''
            }`}
            onClick={onAddFlagTap}
          >
            ADD FLAG
          </span>
        )}
        <Link href={carrierUrls.claim(props.load.id)} target="_blank">
          CLAIM
        </Link>
        <Show when={isAdmin()}>
          <span>
            <Menu
              width={200}
              menuItems={[
                { label: 'AP Calling Queue', value: 'Get Paperwork' },
                { label: 'EFS Issued', value: 'EFS Issued' },
                { label: 'Refund Request', value: 'Refund Request' },
                { label: 'AR Calling Queue', value: 'Missing Paperwork' },
              ]}
              menuButtonLabel="QUEUES"
              onMenuItemClick={(item) => {
                props.setFlagValue(item as FlagType);
                openModal(editFlagModalId);
              }}
            />
          </span>
        </Show>
        <span class="actionButton" onClick={() => openModal(legalTextModalId)}>
          LEGAL TEXT
        </span>
        <Show
          when={
            props.load.status === 'Unassigned' &&
            userStore.user.postingGroupId !== null &&
            userStore.user.postingGroupId > 0 &&
            (props.load.carrierId === null ||
              props.load.carrierId === undefined ||
              props.load.carrierId === 0)
          }
        >
          <span
            class="actionButton"
            hidden={getEligibleStops().length < 2}
            onClick={async () => {
              if (postId() !== null) {
                await fetchPostLoadByPostingId(postId());
              }
              openModal(postModalId);
            }}
          >
            {postId() !== null ? 'MANAGE POSTING' : 'POST LOAD'}
          </span>
        </Show>
        <Show
          when={
            props.load.status === 'Unassigned' &&
            userStore.user.postingGroupId !== null &&
            userStore.user.postingGroupId > 0
          }
        >
          <span
            class="actionButton"
            onclick={() => {
              const eligibleStops = getEligibleStops();
              const firstStop = eligibleStops.find((x) => x.pickUp);
              const secondStop = eligibleStops.find((x) => !x.pickUp);
              if (firstStop && secondStop) {
                printLog('firstStop', firstStop);
                printLog('secondStop', secondStop);
                const equipmentUrl = `${equipmentSearchUrl}?originCity=${firstStop.city}&originState=${firstStop.state}&originZip=${firstStop.zip}&originCountry=${firstStop.countryCode}&destinationCity=${secondStop.city}&destinationState=${secondStop.state}&destinationZip=${secondStop.zip}&destinationCountry=${secondStop.countryCode}&equipmentType=${props.load.equipment}`;
                window.open(equipmentUrl, '_blank', 'noopener noreferrer');
              }
            }}
          >
            TRUCK SEARCH
          </span>
        </Show>
        <Show when={true} fallback={<></>}>
          <span
            class="actionButton"
            onClick={() => {
              copyCarrier(unwrap(props.load));
              copyCarrierDetails(
                carrierStore.carrier[props.tabIndex],
                carrierStore.featureFlags[props.tabIndex],
              );
            }}
          >
            COPY CARRIER
          </span>
        </Show>
      </Box>
      <BasicModal
        id={legalTextModalId}
        title="Legal Text"
        showClose={false}
        width="50vw"
        height="50vh"
        children={
          <TextAreaField
            name="legalText"
            shrink={true}
            rows={10}
            value={props.load.legalText}
            onChange={(value) => {
              updateLoadPropertyAtIndex({
                legalText: value,
              });
            }}
            placeholder="This is an area for Legal Text. It will appear on the Carrier Rate Confirmation under Additional Info. There is no limit on the amount of text here."
          />
        }
      />
      <BasicModal
        title="Post Load"
        onSubmitText="Post Load"
        closeButtonVariant="text"
        id={postModalId}
        width={'1200px'}
        maxWidth={'90vw'}
        showClose={true}
        footer={false}
        header={true}
      >
        <PostLoad
          modalId={postModalId}
          tabIndex={props.tabIndex}
          services={services()}
          postingId={postId()}
          loadOrder={props.load}
        />
      </BasicModal>
    </>
  );
};

export default LoadInformationActionButtons;
