import { Button, DatePicker, ToastType } from '@components';
import AtgLoader from '@components/AtgLoader';
import { CheckboxInput, SelectField, TextAreaField } from '@components/forms';
import { customerUrl } from '@constants/urls';
import { Link, useParams } from '@solidjs/router';
import {
  applyCollectionActions,
  Buckets,
  collectionDetailsStore,
  CollectionsTier,
  fetchATGLookupValues,
  fetchCollectionsByCustomer,
  fetchCollectionsTier,
  getCollectionComments,
  Invoices,
  updateActionNotesState,
  updateCollectionDetailsState,
  updateCollectionsKeyReason,
  updateCollectionsRiskPercent,
  updateCollectionsTier,
  updateNextAction,
} from '@store/collectionsDashboard';
import { currencyFormatter } from '@store/loadboard/utils';
import {
  Box,
  Divider,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@suid/material';
import { handleToast, isAdmin } from '@utils/utils';
import { DateTime } from 'luxon';
import { createSignal, For, Index, onMount, Show } from 'solid-js';
import { SelectChangeEvent } from '@suid/material/Select';

import { CollectionsRiskPercentOptions } from '../constants';
import CustomerBasicDetails from './CustomerBasicDetails';
import HistoryInvoice from './HistoryInvoice';
import CollectionNotes from './Notes';
import CustomerCollectionComments from './CustomerCollectionComments';

export const actionBackgroundColor: { [key: string]: string } = {
  '0-14': 'bg-[#6b9332]',
  '15-29': 'bg-[#93a83a]',
  '30-44': 'bg-[#a8a23b]',
  '45-59': 'bg-[#a98c3a]',
  '60-74': 'bg-[#b67e41]',
  '75-89': 'bg-[#b76b3f]',
  '90-104': 'bg-[#d36147]',
  '105-119': 'bg-[#f51616]',
  '120+': 'bg-[#b72d0d]',
};

export const CollectionDetails = () => {
  const params = useParams();
  const [selectAll, setSelectAll] = createSignal<boolean>(false);
  const [selectedRows, setSelectedRows] = createSignal<number[]>([]);
  const [visibleHistoryRows, setVisibleHistoryRows] = createSignal<number[]>(
    [],
  );
  const [activeTiersStore, setActiveTiersStore] = createSignal<
    CollectionsTier[]
  >([]);

  onMount(async () => {
    await fetchCollectionsByCustomer(Number(params.id));
    await fetchCollectionsTier();
    await fetchATGLookupValues();
    await getCollectionComments(Number(params.id));
    isAdmin() && activeTiers();

    const invoiceIds = collectionDetailsStore.collectionDetails.invoices.map(
      (invoice) => invoice.id,
    );
    setSelectedRows(invoiceIds);
    setSelectAll(true);
    activeTiers();
  });

  const headers = [
    'Invoice#',
    'Invoice',
    'Invoiced Date',
    'Days Past Due',
    'Amount Due',
  ];

  const onSelectAll = (checked: boolean) => {
    setSelectAll(checked);
    setSelectedRows(
      checked
        ? collectionDetailsStore.collectionDetails.invoices.map(
            (invoice) => invoice.id,
          )
        : [],
    );
  };

  const onRowSelect = (id: number, checked: boolean) => {
    const updatedSelection = checked
      ? [...selectedRows(), id]
      : selectedRows().filter((rowId) => rowId !== id);

    setSelectedRows(updatedSelection);
    setSelectAll(
      updatedSelection.length ===
        collectionDetailsStore.collectionDetails.invoices.length,
    );
  };

  const attentionNeeded = (bucket: Buckets, invoice: Invoices) => {
    const inBucket =
      invoice.daysPastDue >= bucket.startDay &&
      invoice.daysPastDue <= bucket.endDay;

    const actionPerformed =
      bucket.actions.some((x) => x.orderId === invoice.id) || false;
    return !actionPerformed && inBucket;
  };

  const actionTaken = (bucket: Buckets, invoiceId: number) => {
    const actionPerformed =
      bucket.actions.some((x) => x.orderId === invoiceId) || false;
    return actionPerformed;
  };

  const toggleHistoryVisibility = (rowId: number) => {
    setVisibleHistoryRows((prev) => {
      if (prev.includes(rowId)) {
        return prev.filter((id) => id !== rowId);
      }
      return [...prev, rowId];
    });
  };

  const handleCollectionsBulkRiskChange = async (
    collectionsRiskPercent: number,
  ) => {
    const ids: number[] = [];
    for (const invoiceId of selectedRows()) {
      ids.push(invoiceId);
    }

    const res = await updateCollectionsRiskPercent({
      ids,
      collectionsRiskPercent,
    });
    if (res && res.success) {
      updateCollectionDetailsState({
        invoices: collectionDetailsStore.collectionDetails.invoices.map(
          (invoice) => ({
            ...invoice,
            collectionsRiskPercent: collectionsRiskPercent,
          }),
        ),
      });
    }
  };

  const handleCollectionsRiskChange = async (
    invoiceId: number,
    collectionsRiskPercent: string,
  ) => {
    const updatedInvoices =
      collectionDetailsStore.collectionDetails.invoices.map((invoice) => {
        if (invoice.id === invoiceId) {
          return {
            ...invoice,
            collectionsRiskPercent: Number(collectionsRiskPercent),
          };
        }
        return invoice;
      });

    const res = await updateCollectionsRiskPercent({
      ids: [invoiceId],
      collectionsRiskPercent,
    });
    if (res && res.success) {
      updateCollectionDetailsState({
        invoices: updatedInvoices,
      });
    }
  };

  const handleKeyPress = async (e: KeyboardEvent) => {
    const { comment, actions, orderId } = collectionDetailsStore.actionNotes;
    if (e.key === 'Enter') {
      e.preventDefault();
      if (actions.length === 0) {
        handleToast(
          ToastType.Error,
          'You have not selected any actions to perform',
        );
        return false;
      }
      await applyCollectionActions([{ actions, comment, orderId }]);
    }
  };

  const activeTiers = () => {
    const currentTime = new Date().getTime();
    const activeTiers = collectionDetailsStore.collectionTier.filter(
      (x) =>
        x.expirationDate !== null &&
        new Date(x.expirationDate).getTime() >= currentTime,
    );
    collectionDetailsStore.collectionTier
      .filter((x) => x.expirationDate == null)
      .forEach((tier) => activeTiers.push(tier));
    setActiveTiersStore(activeTiers);
  };

  const assignTier = async (e: SelectChangeEvent) => {
    const tier = e.target.value;
    const id = params.id;
    await updateCollectionsTier({ id, tierId: tier });
  };

  const assignKeyReason = async (e: SelectChangeEvent) => {
    const keyReason = e.target.value;
    await updateCollectionsKeyReason({
      id: Number(params.id),
      keyReasonId: Number(keyReason),
    });
  };

  const handleNextActionDateChange = async (date: string | null) => {
    const id = params.id;
    let stringDate = null;
    if (date !== null) {
      stringDate = DateTime.fromISO(date).toFormat('MM/dd/yyyy');
    }
    const res = await updateNextAction({ id, stringDate });
    if (res && res.success) {
      handleToast(ToastType.Success, 'Next Action successfully assigned!');
      updateCollectionDetailsState({
        customer: {
          ...collectionDetailsStore.collectionDetails.customer,
          nextActionDate: date,
        },
      });
    }
  };

  return (
    <Show
      when={!collectionDetailsStore.isCollectionLoading}
      fallback={<AtgLoader />}
    >
      <Show when={!collectionDetailsStore.isError}>
        <Box sx={{ p: 2 }}>
          <Show when={collectionDetailsStore.isLoading}>
            <AtgLoader />
          </Show>
          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
              <CustomerBasicDetails />
            </Grid>
            <Show when={isAdmin()}>
              <Grid item xs={12} md={6}>
                <Grid container direction="row" spacing={3} p={2}>
                  <Grid
                    item
                    xs={12}
                    md={6}
                    container
                    direction="column"
                    spacing={2}
                  >
                    <Grid item>
                      <SelectField
                        label="Assign Tier"
                        menuItems={activeTiersStore().map((tier) => ({
                          label: tier.tier,
                          value: tier.id,
                        }))}
                        placeholder="Select Assign Tier"
                        value={
                          collectionDetailsStore.collectionDetails.customer
                            .collectionTierId ?? ''
                        }
                        onChange={(e: SelectChangeEvent) => assignTier(e)}
                      />
                    </Grid>
                    <Grid item>
                      <SelectField
                        label="Assign Key Reason"
                        menuItems={collectionDetailsStore.aTGLookupValue.map(
                          (tier) => ({
                            label: tier.value,
                            value: tier.id,
                          }),
                        )}
                        placeholder="Select Assign Key Reason"
                        value={
                          collectionDetailsStore.collectionDetails.customer
                            .keyReasonId ?? ''
                        }
                        onChange={(e: SelectChangeEvent) => assignKeyReason(e)}
                      />
                    </Grid>
                    <Grid item>
                      <DatePicker
                        label="Next Action Date"
                        value={
                          collectionDetailsStore.collectionDetails.customer
                            .nextActionDate ?? ''
                        }
                        handleChange={(date) =>
                          handleNextActionDateChange(date)
                        }
                      />
                      <Button
                        label="Clear"
                        variant="contained"
                        size="small"
                        onClick={() => handleNextActionDateChange(null)}
                        class="!mt-[10px] !bg-[#d9534f]"
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={6}
                    container
                    direction="column"
                    spacing={2}
                  >
                    <CollectionNotes selectedRows={selectedRows} />
                  </Grid>
                </Grid>
              </Grid>
            </Show>
          </Grid>
        </Box>
        <Divider class="!m-[10px]" />
        <Show when={isAdmin()}>
          <Typography class="!font-medium !mx-[10px]">
            Customer Collection Notes
          </Typography>
        </Show>
        <Box
          class="m-[10px] border border-[#a5a5a5] rounded-md bg-[#f2f2f2]"
          sx={{
            flexGrow: 1,
          }}
        >
          <Show when={isAdmin()}>
            <CustomerCollectionComments />
          </Show>

          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    {' '}
                    <CheckboxInput
                      inputProps={{ 'aria-label': 'Select All' }}
                      onChange={onSelectAll}
                      checked={selectAll()}
                      label=""
                    />
                  </TableCell>
                  {headers.map((item) => (
                    <TableCell>{item}</TableCell>
                  ))}
                  <Show when={isAdmin()}>
                    <TableCell class="!text-center">
                      <Typography class="!font-medium !text-sm">
                        Risk
                      </Typography>
                      <input
                        type="range"
                        min="0"
                        max="100"
                        value="0"
                        step="25"
                        class="slider"
                        id="myRange"
                        style={{ width: '50px' }}
                        onChange={(e) =>
                          handleCollectionsBulkRiskChange(
                            Number(e.target.value),
                          )
                        }
                      />
                    </TableCell>
                  </Show>
                  <TableCell class="!text-center">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody class="bg-[#fff]">
                <Index each={collectionDetailsStore.collectionDetails.invoices}>
                  {(item) => (
                    <>
                      <TableRow
                        onClick={() => {
                          toggleHistoryVisibility(item().id);
                        }}
                        class="cursor-pointer"
                      >
                        <TableCell>
                          <Box onClick={(e) => e.stopImmediatePropagation()}>
                            <CheckboxInput
                              label=""
                              inputProps={{
                                'aria-label': 'Checkbox for selecting row',
                              }}
                              checked={selectedRows().includes(item().id)}
                              onChange={(checked) =>
                                onRowSelect(item().id, checked)
                              }
                            />
                          </Box>
                        </TableCell>
                        <TableCell>{item().id}</TableCell>
                        <TableCell class="!text-[#51607b]">
                          <Link
                            href={customerUrl.customerSystemDocuments(
                              item().invoiceUrl,
                            )}
                            target="_blank"
                          >
                            Invoice
                          </Link>
                        </TableCell>
                        <TableCell>
                          {DateTime.fromISO(item().invoicedDate).toFormat(
                            'MM/dd/yyyy',
                          )}
                        </TableCell>
                        <TableCell>{item().daysPastDue}</TableCell>
                        <TableCell>
                          {currencyFormatter(item().amountPaid)}
                        </TableCell>
                        <Show when={isAdmin()}>
                          <TableCell>
                            <SelectField
                              label=""
                              value={String(item().collectionsRiskPercent)}
                              menuItems={CollectionsRiskPercentOptions}
                              onChange={(e) =>
                                handleCollectionsRiskChange(
                                  item().id,
                                  e.target.value,
                                )
                              }
                            />
                          </TableCell>
                        </Show>
                        <TableCell>
                          <Box class="flex">
                            <Index
                              each={
                                collectionDetailsStore.collectionDetails.buckets
                              }
                            >
                              {(bucketItem) => (
                                <span
                                  class={`text-[#fff] px-[5px] w-[70px] border ${
                                    attentionNeeded(bucketItem(), item())
                                      ? 'bg-[#DBE2FF] !text-[#000]'
                                      : actionTaken(bucketItem(), item().id)
                                        ? `${
                                            actionBackgroundColor[
                                              bucketItem().bucket
                                            ]
                                          } text-[#fff]`
                                        : 'bg-[#7f7f7f]'
                                  }`}
                                >
                                  {bucketItem().bucket}
                                </span>
                              )}
                            </Index>
                          </Box>
                        </TableCell>
                      </TableRow>
                      <Show when={visibleHistoryRows().includes(item().id)}>
                        <TableRow>
                          <Show when={isAdmin()}>
                            <TableCell
                              colSpan={5}
                              sx={{
                                verticalAlign: 'baseline',
                              }}
                            >
                              <For
                                each={
                                  collectionDetailsStore.collectionDetails
                                    .actions
                                }
                              >
                                {(action) => (
                                  <Box class="bg-[#d9edf7] p-[5px] m-[10px] ml-[40px]">
                                    <CheckboxInput
                                      label={action}
                                      checked={collectionDetailsStore.actionNotes.actions.includes(
                                        action,
                                      )}
                                      onChange={() => {
                                        updateActionNotesState({
                                          actions:
                                            collectionDetailsStore.actionNotes.actions.includes(
                                              action,
                                            )
                                              ? collectionDetailsStore.actionNotes.actions.filter(
                                                  (x) => x !== action,
                                                )
                                              : [
                                                  ...collectionDetailsStore
                                                    .actionNotes.actions,
                                                  action,
                                                ],
                                          orderId: item().id,
                                        });
                                      }}
                                    />
                                  </Box>
                                )}
                              </For>
                            </TableCell>
                          </Show>

                          <TableCell
                            colSpan={5}
                            sx={{ verticalAlign: 'baseline' }}
                          >
                            <Show when={isAdmin()}>
                              <Box class="!m-[10px]">
                                <Typography class="!text-sm !font-medium">
                                  Notes:
                                </Typography>
                                <TextAreaField
                                  label=""
                                  rows={2}
                                  value={
                                    collectionDetailsStore.actionNotes.comment
                                  }
                                  maxLength={200}
                                  placeholder="Press Enter to Save"
                                  onChange={(value) => {
                                    updateActionNotesState({
                                      comment: value,
                                      orderId: item().id,
                                    });
                                  }}
                                  onKeyPress={handleKeyPress}
                                  class="!border-[#a5a5a5]"
                                />
                              </Box>
                            </Show>
                            <Typography class="!text-sm !font-medium underline !mx-[10px]">
                              History
                            </Typography>

                            <Index
                              each={
                                collectionDetailsStore.collectionDetails.buckets
                              }
                            >
                              {(bucketItem) => (
                                <HistoryInvoice
                                  bucket={bucketItem()}
                                  invoice={item()}
                                />
                              )}
                            </Index>
                          </TableCell>
                          <Show when={!isAdmin()}>
                            <TableCell colSpan={5}></TableCell>
                          </Show>
                        </TableRow>
                      </Show>
                    </>
                  )}
                </Index>
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Show>
    </Show>
  );
};
