import { Button, ToastType, Typography } from '@components';
import {
  officeDetailStore,
  setOfficeDetailStore,
} from '@store/office/officeDetails';
import { Box, Grid, Stack } from '@suid/material';
import { Save } from '@suid/icons-material';
import { ValidationError } from 'yup';
import { saveOfficeDetailApi } from '@store/office/officeDetails/services';
import { handleToast } from '@utils/utils';
import { formatDateOrNull } from '@utils/dateFormat';
import { createSignal } from 'solid-js';

import cls from './classes';
import { officeDetailSchema } from '../validation';

export const OfficeDetailHeader = (props: { id: string }) => {
  const [saveLoading, setSaveLoading] = createSignal<boolean>(false);

  const handleSaveOfficeDetail = async () => {
    setSaveLoading(true);
    try {
      officeDetailSchema.validateSync(officeDetailStore.officeDetail, {
        abortEarly: false,
      });
      setOfficeDetailStore('officeDetailErrors', null);
      if (officeDetailStore.officeDetail) {
        const updatedOfficeDetail = {
          ...officeDetailStore.officeDetail,
          startDate: formatDateOrNull(officeDetailStore.officeDetail.startDate),
          contractSignedDate: formatDateOrNull(
            officeDetailStore.officeDetail.contractSignedDate,
          ),
          terminationDate: formatDateOrNull(
            officeDetailStore.officeDetail.terminationDate,
          ),
        };
        await saveOfficeDetailApi(updatedOfficeDetail, !props.id);
        handleToast(ToastType.Success, 'Update successful');
      }
    } catch (validationError: unknown) {
      const errors: Record<string, string[]> = {};
      if (validationError instanceof ValidationError) {
        validationError.inner.forEach((error: ValidationError) => {
          if (error.path !== undefined) {
            errors[error.path] = error.errors;
          }
        });
      }
      setOfficeDetailStore('officeDetailErrors', errors);
    } finally {
      setSaveLoading(false);
    }
  };
  return (
    <Grid
      container
      alignItems="center"
      backgroundColor="white"
      class={cls.headerContainer}
      spacing={2}
    >
      <Grid item xs={3.5}>
        <Stack spacing={0.5}>
          <Box class={cls.customerNameText}>
            <Typography variant="h4" class="w-[300px] line-clamp-1">
              {officeDetailStore.officeDetail?.name}
            </Typography>
          </Box>
        </Stack>
      </Grid>
      <Grid item xs={8.5} displayRaw="flex" justifyContent="flex-end">
        <Box class={cls.customerActionBtns}>
          <Button
            startIcon={<Save />}
            variant="contained"
            label="SAVE"
            type="submit"
            onClick={handleSaveOfficeDetail}
            isLoading={saveLoading()}
          />
          <Button variant="contained" label="Posting Groups" type="submit" />
        </Box>
      </Grid>
    </Grid>
  );
};
