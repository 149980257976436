import { Typography } from '@components';
import { TextInput } from '@components/forms';
import { carrierUrls } from '@constants/urls';
import { Link } from '@solidjs/router';
import { playlistsStore } from '@store/carriers';
import { addCarrierToPlaylist } from '@store/orders';
import { People } from '@suid/icons-material';
import {
  Box,
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@suid/material';
import { Show, createSignal } from 'solid-js';

import { ILoadEditViewModel, Playlist } from '../../../../store/orders/types';
import cls from './classes';
import { handleCarrierChange } from '../carrier/loads/LoadInfo/CarrierAutocomplete';

export const SelectPlaylist = (props: {
  onClose: () => void;
  modalId: string;
  carrierId: number;
  tabIndex: number;
  load: ILoadEditViewModel;
}) => {
  const [playlists, setPlaylists] = createSignal<Playlist[]>(
    playlistsStore.playlists,
  );
  const [selectedPlaylist, setSelectedPlaylist] = createSignal<Playlist>();

  const addToPlaylist = async () => {
    void (await addCarrierToPlaylist({
      CarrierId: props.carrierId,
      PlaylistId: selectedPlaylist()!.id,
      Selected: true,
    }));
    props.onClose();
  };

  return (
    <Box>
      <Typography class={cls.playListModalTitle} variant="h6" component="h2">
        Select Playlist
      </Typography>
      <Grid
        container
        sx={{
          alignItems:
            selectedPlaylist() && playlists().length < 1
              ? 'center'
              : 'flex-start',
        }}
      >
        <Grid item xs={Boolean(props.carrierId) ? 12 : 6}>
          <Grid
            container
            spacing={2}
            class="flex flex-col gap-[10px] items-center px-4 pt-6 cursor-pointer"
          >
            {playlists().length === 0 && (
              <Grid container sx={{ paddingBottom: '10px' }}>
                <Grid
                  item
                  xs={10}
                  sx={{ paddingLeft: '20px', fontWeight: 'bold' }}
                >
                  {' '}
                  You don't seem to have any playlists created.
                </Grid>
                <Grid
                  item
                  xs={2}
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    paddingRight: '20px',
                  }}
                >
                  <Link href={carrierUrls.playLists()} target="_blank">
                    <Button class={cls.btn} variant="contained">
                      MAKE ONE HERE!
                    </Button>
                  </Link>
                </Grid>
              </Grid>
            )}
            <TextInput
              label="Filter Playlist"
              placeholder="Filter Playlists"
              variant="outlined"
              id="filter"
              size="medium"
              sxProps={{ width: '100%' }}
              onChange={(value) => {
                const filterValue = (value as string).toLocaleLowerCase();
                const filteredList = playlistsStore.playlists.filter(
                  (playlists) =>
                    playlists.name.toLowerCase().includes(filterValue) ||
                    playlists.user.toLowerCase().includes(filterValue),
                );
                setPlaylists(filteredList);
              }}
            />

            <Box sx={{ width: '100%' }}>
              {playlists().map((value) => {
                return (
                  <>
                    <Box
                      class={
                        selectedPlaylist()?.id === value.id
                          ? cls.playListsContainerActive
                          : cls.playListsContainer
                      }
                      onClick={() => {
                        setSelectedPlaylist(value);
                        if (Boolean(props.carrierId)) {
                          void addToPlaylist();
                        }
                      }}
                    >
                      <People class={cls.peopleIcon} />
                      <Box class={cls.playlistNameOwner}>
                        <Typography variant="h6" component="div">
                          {value.name}
                        </Typography>
                        <Typography
                          variant="body1"
                          component="div"
                          class="opacity-60"
                        >
                          {`Owner: ${value.user} `}
                        </Typography>
                      </Box>
                      <Box class={cls.playlistGroupCount}>
                        <Typography variant="body1">
                          {value.carriers.length}
                        </Typography>
                      </Box>
                    </Box>
                  </>
                );
              })}
            </Box>
          </Grid>
        </Grid>
        <Show when={!Boolean(props.carrierId)} fallback={<></>}>
          <Grid item xs={6} sx={{ marginBottom: '25px' }}>
            <TableContainer class={cls.carrierTable}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell />
                    <TableCell>MC #</TableCell>
                    <TableCell>DOT #</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Status</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {selectedPlaylist()?.carriers.map((option) => (
                    <TableRow
                      class={cls.tableRow}
                      onClick={async () => {
                        props.onClose();
                        await handleCarrierChange(
                          option.id,
                          option.name!,
                          props.tabIndex,
                          props.load,
                        );
                      }}
                    >
                      <TableCell></TableCell>
                      <TableCell>{option.mc}</TableCell>
                      <TableCell>{option.dot}</TableCell>
                      <TableCell>{option.name}</TableCell>
                      <TableCell>{option.status}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Show>
      </Grid>
      <Grid class="flex mt-2 justify-end gap-5">
        <Button class={cls.btn} onClick={() => props.onClose()} variant="text">
          {'Cancel'}
        </Button>
      </Grid>
    </Box>
  );
};
