import {
  addNewAddress,
  AddressBook,
  addressBookStore,
  editAddressBook,
  setAddressBookStore,
} from '@store/customers/customerDetails/addressBookTab';
import AddressAutoComplete from '@components/Autocomplete/AddressAutoComplete';
import { createSignal, onMount, Show } from 'solid-js';
import { createForm } from '@felte/solid';
import { validator } from '@felte/validator-yup';
import { closeModal } from '@store/modals';
import { get } from 'lodash';
import { handleToast, printLog } from '@utils/utils';
import { Box, Grid } from '@suid/material';
import {
  Button,
  Notification,
  TimePicker,
  ToastType,
  Typography,
} from '@components';
import {
  CheckboxInput,
  PhoneInput,
  SelectField,
  TextAreaField,
  TextInput,
} from '@components/forms';
import { DateTime } from 'luxon';
import { useParams } from '@solidjs/router';
import { SelectChangeEvent } from '@suid/material/Select';
import { AgGridSolidRef } from 'ag-grid-solid';

import { addOrEditAddressStyle as classes } from './classes';
import { addressBookSchema } from './validation';
import { countryList } from '../constants';

export type IntialAddressFormValues = {
  name: string;
  description: string | null;
  address1: string;
  addressLine2: string;
  city: string;
  state: string;
  zip: string;
  countryCode: string;
  contactName: string | null;
  contactPhone: string | null;
  openTimeFrom: string;
  openTimeTo: string;
  specialInstructions: string | null;
  drivingDirections: string | null;
  internalNote: string | null;
  isDefaultAddress: boolean;
  customerId: number;
};

export type AddressDetails = {
  address1?: string;
  city?: string;
  county?: string;
  state?: string;
  zipCode?: string;
};

type AddOrEditAddressBookProps = {
  modalId: string;
  handleClose: () => void;
  customerId?: number;
  gridRef?: AgGridSolidRef;
};

const AddOrEditAddressBook = (props: AddOrEditAddressBookProps) => {
  const params = useParams();
  const [isSaveAndNew, setIsSaveAndNew] = createSignal(false);
  const [checked, setChecked] = createSignal('');

  const {
    form,
    reset,
    errors,
    setIsDirty,
    isSubmitting,
    setIsSubmitting,
    setFields,
    setData,
    data,
  } = createForm<IntialAddressFormValues>({
    initialValues: {
      name: '',
      description: null,
      address1: '',
      addressLine2: '',
      city: '',
      state: '',
      zip: '',
      countryCode: '',
      contactName: null,
      contactPhone: null,
      openTimeFrom: '',
      openTimeTo: '',
      specialInstructions: null,
      drivingDirections: null,
      internalNote: null,
      isDefaultAddress: false,
      customerId: Boolean(props.customerId)
        ? Number(props.customerId)
        : Number(params.id),
    },
    extend: validator({ schema: addressBookSchema }),
    onSubmit: async (values) => {
      const cb = (message: string) => {
        handleToast(ToastType.Success, message);
        reset();
        setAddressBookStore('selectedAddress', null);
        setAddressBookStore('addOrEditErrorMessage', '');
        Boolean(isSaveAndNew()) ? setChecked('') : closeModal(props.modalId);
      };

      setIsSubmitting(true);
      try {
        const commonPayload = {
          ...values,
          addressLine1: values.address1,
        };

        if (addressBookStore.selectedAddress) {
          const editPayload = {
            ...commonPayload,
            id: addressBookStore.selectedAddress.id,
            createdOn: addressBookStore.selectedAddress.createdOn,
            openTimeFrom: formatTimeToPayload(commonPayload.openTimeFrom),
            openTimeTo: formatTimeToPayload(commonPayload.openTimeTo),
          };
          await editAddressBook({ ...editPayload, cb } as AddressBook);
        } else {
          const addPayload = {
            ...commonPayload,
            openTimeFrom: formatTimeToPayload(commonPayload.openTimeFrom),
            openTimeTo: formatTimeToPayload(commonPayload.openTimeTo),
          };
          await addNewAddress({ ...addPayload, cb } as AddressBook);
        }
      } catch (error) {
        const errorMessage =
          error instanceof Error ? error.message : String(error);
        throw new Error(`Failed to Add Address: ${errorMessage}`);
      } finally {
        setIsSubmitting(false);
      }
    },
    onError: (errors) => {
      printLog(errors);
    },
  });

  const formatTimeToPayload = (timeVal: string) => {
    return DateTime.fromISO(timeVal).toFormat('HH:mm:ss');
  };

  const formatTime = (timeVal: string) => {
    if (!timeVal) return '';
    const dateString = DateTime.now().toISODate();
    const res = DateTime.fromISO(`${dateString}T${timeVal}`).toISO();
    return res;
  };
  const getAddressBook = () => {
    const address = addressBookStore.selectedAddress;
    if (Boolean(address)) {
      setData('name', get(address, 'name') as string);
      setData('description', get(address, 'description') as string);
      setData('address1', get(address, 'addressLine1') as string);
      setData('addressLine2', get(address, 'addressLine2') as string);
      setData('city', get(address, 'city') as string);
      setData('state', get(address, 'state') as string);
      setData('zip', get(address, 'zip') as string);
      setData('countryCode', get(address, 'countryCode') as string);
      setData('contactName', get(address, 'contactName') as string);
      setData('contactPhone', get(address, 'contactPhone') as string);
      setData(
        'openTimeFrom',
        formatTime(get(address, 'openTimeFrom', '')) ?? '',
      );
      setData('openTimeTo', formatTime(get(address, 'openTimeTo', '')) ?? '');
      setData('internalNote', get(address, 'internalNote') as string);
      setData(
        'specialInstructions',
        get(address, 'specialInstructions') as string,
      );
      setData('drivingDirections', get(address, 'drivingDirections') as string);
      setData('isDefaultAddress', get(address, 'isDefaultAddress') as boolean);
    }
  };

  onMount(() => {
    getAddressBook();
  });

  const setAddressFields = (
    field: keyof IntialAddressFormValues,
    value: string | boolean,
  ) => {
    setIsDirty(true);
    setFields(field, value);
  };

  const handleGooglePlacesSelect = (place: AddressDetails) => {
    const { address1 = '', city = '', state = '', zipCode = '' } = place;
    setAddressFields('address1', address1);
    setAddressFields('city', city);
    setAddressFields('state', state.slice(0, 2));
    setAddressFields('zip', zipCode);
  };

  const setAddressDefaultText = (value: string) => {
    const defaultOriginItem = addressBookStore.items.find(
      (item) => item.isDefaultAddress === true,
    );

    const { selectedAddress } = addressBookStore;
    if (!selectedAddress) {
      if (value === 'Yes') {
        const message = (
          <Typography variant="body2" class="text-red-500">
            {defaultOriginItem
              ? `Clicking save will change the default origin from ${
                  defaultOriginItem.addressLine1
                } ${defaultOriginItem.city}, ${defaultOriginItem.state} ${
                  defaultOriginItem.zip
                } to ${data().address1} ${data().city}, ${data().state} ${
                  data().zip
                }`
              : `Clicking Save will default origin to ${data().address1} ${
                  data().city
                }, ${data().state} ${data().zip}`}
          </Typography>
        );
        return message;
      }
      return null;
    }

    if (value === 'No' && selectedAddress.isDefaultAddress) {
      return (
        <Typography variant="body2" class="text-red-500">
          Clicking save will remove {data().address1} {data().city},{' '}
          {data().state} {data().zip} as the default origin
        </Typography>
      );
    } else if (value === 'Yes' && !selectedAddress.isDefaultAddress) {
      return (
        <Typography variant="body2" class="text-red-500">
          {defaultOriginItem
            ? `Clicking save will change the default origin from ${
                defaultOriginItem.addressLine1
              } ${defaultOriginItem.city}, ${defaultOriginItem.state} ${
                defaultOriginItem.zip
              } to ${data().address1} ${data().city}, ${data().state} ${
                data().zip
              }`
            : `Clicking Save will default origin to ${data().address1} ${
                data().city
              }, ${data().state} ${data().zip}`}
        </Typography>
      );
    }

    return null;
  };

  return (
    <form ref={form} class={classes.rowFormGrid}>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container item xs={12} spacing={2} class={classes.errorBox}>
          <Show
            when={
              errors().name ||
              errors().address1 ||
              errors().city ||
              errors().state ||
              errors().zip ||
              errors().countryCode
            }
          >
            <Grid item xs={12}>
              <Notification
                type="error"
                text={
                  <>
                    <Typography variant="body1">
                      Please correct the following Problems:
                    </Typography>
                    <div>Highlighted Fields are required.</div>
                  </>
                }
              />
            </Grid>
          </Show>
          <Show when={addressBookStore.addOrEditErrorMessage}>
            <Grid item xs={12}>
              <Notification
                type="error"
                text={addressBookStore.addOrEditErrorMessage}
              />
            </Grid>
          </Show>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="body1">Location Details</Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Grid item container spacing={2}>
              <Grid item xs={12}>
                <TextInput
                  name="name"
                  label="Location Name"
                  error={errors().name}
                />
              </Grid>
              <Grid item xs={12}>
                <TextInput
                  name="description"
                  label="Location Description"
                  error={errors().description}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1">Address</Typography>
              </Grid>
              <Grid item xs={12}>
                <AddressAutoComplete
                  label="Address1"
                  placeholder="Enter Address"
                  value={data().address1}
                  onChange={(itm) => {
                    setAddressFields('address1', itm);
                  }}
                  onItemSelect={(item) => {
                    handleGooglePlacesSelect(item);
                  }}
                  zIndex="9999"
                  error={errors().address1}
                />
              </Grid>
              <Grid item xs={12}>
                <TextInput
                  label="Address2"
                  placeholder="Address line 2"
                  value={data().addressLine2}
                  onChange={(itm: string) =>
                    setAddressFields('addressLine2', itm)
                  }
                />
              </Grid>
              <Grid item container spacing={2}>
                <Grid item xs={4}>
                  <TextInput
                    label="City"
                    placeholder="City"
                    value={data().city}
                    onChange={(itm: string | number | Event | null) =>
                      setAddressFields('city', itm as string)
                    }
                    error={errors().city}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextInput
                    label="State"
                    placeholder="State"
                    value={data().state}
                    onChange={(itm: string) =>
                      setAddressFields('state', itm.slice(0, 2))
                    }
                    error={errors().state}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextInput
                    label="Zip"
                    placeholder="Pincode"
                    value={data().zip}
                    onChange={(value: string | number | Event | null) =>
                      setAddressFields('zip', value as string)
                    }
                    error={errors().zip}
                  />
                </Grid>
                <Grid item xs={12}>
                  <SelectField
                    label="USA/CAN/MEX"
                    menuItems={countryList}
                    value={data().countryCode}
                    onChange={(e: SelectChangeEvent) =>
                      setAddressFields('countryCode', e.target.value)
                    }
                    error={errors().countryCode}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1">Contact</Typography>
              </Grid>
              <Grid item xs={12}>
                <TextInput
                  label="Name"
                  placeholder="Name"
                  value={data().contactName as string}
                  onChange={(itm: string) =>
                    setAddressFields('contactName', itm)
                  }
                  error={errors().contactName}
                />
              </Grid>
              <Grid item xs={12}>
                <PhoneInput
                  label="Phone"
                  placeholder="Phone Number"
                  value={data().contactPhone as string}
                  onChange={(value) => setAddressFields('contactPhone', value)}
                  error={errors().contactPhone}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1">Hours</Typography>
              </Grid>
              <Grid item xs={6}>
                <TimePicker
                  label="From"
                  value={data().openTimeFrom}
                  onChange={(newTime) =>
                    setAddressFields('openTimeFrom', newTime)
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <TimePicker
                  label="To"
                  value={data().openTimeTo}
                  onChange={(newTime) =>
                    setAddressFields('openTimeTo', newTime)
                  }
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <Grid item container spacing={2}>
              <Grid item xs={12}>
                <TextAreaField
                  rows={5}
                  label="Location Instructions"
                  placeholder="Enter Location Instructions"
                  value={data().specialInstructions as string}
                  onChange={(itm: string) =>
                    setAddressFields('specialInstructions', itm)
                  }
                  error={errors().specialInstructions}
                />
              </Grid>
              <Grid item xs={12}>
                <TextAreaField
                  rows={5}
                  label="Driving Directions"
                  placeholder="Enter Driving Directions"
                  value={data().drivingDirections as string}
                  onChange={(itm: string) =>
                    setAddressFields('drivingDirections', itm)
                  }
                  error={errors().drivingDirections}
                />
              </Grid>
              <Grid item xs={12}>
                <TextAreaField
                  rows={5}
                  label="Internal Notes"
                  placeholder="Enter Internal Notes"
                  value={data().internalNote as string}
                  onChange={(itm: string) =>
                    setAddressFields('internalNote', itm)
                  }
                  error={errors().internalNote}
                />
              </Grid>
              <Grid item xs={12}>
                <CheckboxInput
                  label="Set as a Default Origin"
                  name="isDefaultAddress"
                  checked={data().isDefaultAddress}
                  onChange={(value) => {
                    setAddressFields('isDefaultAddress', value as boolean);
                    value ? setChecked('Yes') : setChecked('No');
                  }}
                />
                <Grid item xs={12}>
                  {setAddressDefaultText(checked())}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            gap={2}
            displayRaw="flex"
            alignItems="center"
            justifyContent="end"
          >
            <Button
              isLoading={isSaveAndNew() && isSubmitting()}
              label="Save And Add New"
              variant="contained"
              type="submit"
              onClick={() => {
                setIsSaveAndNew(true);
              }}
              sx={{ background: '#468DB5', color: '#FFF' }}
              disabled={isSubmitting()}
            />
            <Button
              isLoading={!isSaveAndNew() && isSubmitting()}
              label="Save"
              type="submit"
              variant="contained"
              onClick={() => {
                setIsSaveAndNew(false);
              }}
              disabled={isSubmitting()}
            />
          </Grid>
        </Grid>
      </Box>
    </form>
  );
};

export default AddOrEditAddressBook;
