import { ToastType } from '@components/Toast';
import { SelectField } from '@components/forms';
import { ILoadBoardViewModel, loadBoardStore } from '@store/loadboard';
import { changeLoadStatus } from '@store/orders';
import { SelectChangeEvent } from '@suid/material/Select';
import { handleToast } from '@utils/utils';
import { getLoadColor } from '@views/order/components/header/constants';
import { ICellRendererParams } from 'ag-grid-community';
import { Show } from 'solid-js';

const modeTypes = [
  { label: 'Assigned', value: 'Assigned' },
  { label: 'Can Get', value: 'Can Get' },
  { label: 'Unassigned', value: 'Unassigned' },
  { label: 'Dispatched', value: 'Dispatched' },
  { label: 'At Shipper', value: 'At Shipper' },
  { label: 'Loaded', value: 'Loaded' },
  { label: 'In Transit', value: 'In Transit' },
  { label: 'At Receiver', value: 'At Receiver' },
  { label: 'Out On Delivery', value: 'Out On Delivery' },
  { label: 'Empty', value: 'Empty' },
];

const LoadBoardStatusCell = (
  data: ICellRendererParams<ILoadBoardViewModel>,
) => {
  const onStatusChange = async (e: SelectChangeEvent) => {
    try {
      if (data.data !== undefined) {
        const loadId = data.data.loadId;

        await changeLoadStatus(loadId, e.target.value);
        const load = loadBoardStore.items.find((l) => l.loadId === loadId);

        if (load !== undefined) {
          data.api.applyTransaction({ update: [load] });
        }
      }
    } catch (error) {
      handleToast(ToastType.Error, 'Failed to update status');
    }
  };

  return (
    <Show when={data.data} fallback={<>{data.value}</>}>
      <SelectField
        label=""
        disableUnderline={true}
        menuItems={modeTypes}
        onChange={onStatusChange}
        value={data.data?.status}
        variant="standard"
        backgroundColor={getLoadColor(data.data?.status)}
        sxProps={{
          height: '35px',
          fontSize: 'small',
          borderRadius: '50px',
          border: 'none',
          textAlign: 'center',
          fontWeight: 'bold',
        }}
      />
    </Show>
  );
};

export default LoadBoardStatusCell;
