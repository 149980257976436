import {
  factoringCompanyDetailsStore,
  setFactoringCompanyDetailsStore,
} from '@store/factoringCompany/factoringCompanyDetails';
import { ValidationError } from 'yup';

import { factoringCompanyDetailsSchema } from './validation';

export const handleFactoringCompanyDetailsValidation = () => {
  try {
    setFactoringCompanyDetailsStore('factoringCompanyDetailsErrors', null);
    factoringCompanyDetailsSchema.validateSync(
      factoringCompanyDetailsStore.factoringCompanyDetails,
      {
        abortEarly: false,
      },
    );
    return true;
  } catch (validationError: unknown) {
    const errors: Record<string, string[]> = {};
    if (validationError instanceof ValidationError) {
      validationError.inner.forEach((error: ValidationError) => {
        if (error.path !== undefined) {
          errors[error.path] = error.errors;
        }
      });
      setFactoringCompanyDetailsStore('factoringCompanyDetailsErrors', errors);
      return false;
    }
  }
};
