import { IntialStopFormValues } from './types';

export const initialFormValues: IntialStopFormValues = {
  locationName: '',
  timeAppointment: '',
  dateAppointment: '',
  appointmentNum: '',
  needsAppointment: false,
  address1: '',
  address2: '',
  countryCode: 'USA',
  description: '',
  directions: '',
  instructions: '',
  city: '',
  state: '',
  zip: '',
  contact: '',
  phone: '',
  email: '',
  time: '',
  referenceNum: '',
  stopDateTime: '',
  driverDateIn: '',
  driverDateOut: '',
  driverTimeIn: '',
  driverTimeOut: '',
  loadItems: [],
  mode: '',
  pickUp: true,
  dropOff: false,
  stopCode: '',
  id: 0,
  operationType: 'Insert',
};

export const defaultLoadItems = {
  operationType: 'Insert',
  id: 0,
  loadId: 0,
  nmfc: undefined,
  class: null,
  item: 'Items',
  description: '',
  quantity: 1,
  charge: undefined,
  pickUpId: null,
  dropOffId: null,
  weight: 0,
  uom: 'lbs',
  volume: undefined,
  hazmat: false,
  heightInch: undefined,
  lengthInch: undefined,
  widthInch: undefined,
  height: undefined,
  length: undefined,
  width: undefined,
  pieces: 1,
  descriptionWithParanthesis: '',
  displayDimensions: '',
  loadItemHazmatDetail: undefined,
};
