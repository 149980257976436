import { deepConvertPascalToCamelCase } from '@utils/caseTransformers';
import { ConfigManager } from '@utils/ConfigManager';

import { UserViewModel } from './types';

export async function fetchUser(): Promise<UserViewModel> {
  try {
    const userData = await fetch(`${ConfigManager.yarpHost}/api/user`, {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    const user = await userData.json();
    return deepConvertPascalToCamelCase(user) as UserViewModel;
  } catch (error: unknown) {
    // eslint-disable-next-line no-console
    console.log(error, 'error');
    if (error instanceof Error) {
      throw new Error(`Failed to fetch offices: ${error.message}`);
    }
    throw new Error(`Failed to fetch offices: ${error}`);
  }
}
